.register-grid-title {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}

.register-tag {
  background: none !important;
  color: rgba(140, 86, 255, 1) !important;
}

.notetext {
  font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -.018em;
    margin: 0;
    color: #00fecf;
}

.walletdesc {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.whitedesc {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.separator {
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: rgba(255, 255, 255, 0.3);
}

.optionwrapper {
  border: 2px solid #6a608c;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
}

.optionwrapper:hover {
  border: 2px solid #7bd8b0;
}

.walletname {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #f7f7fc;
}

.option-wallet {
  width: 48px;
  height: 48px;
}

.purpledesc {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #FFFFFF;
}

.wallettext {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #d1d0d7;
}

.reqtitle {
  font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
}

.reqdesc {
  font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #FFFFFF;
}
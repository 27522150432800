@import '../../../responsive';
@import '../../../colors';



.cats-timeline {
    font-size: 14px;
    @include medium {
        font-size: 16px;
    }
    ul {
        list-style: none;
    }
    li {
        position: relative;
        padding-left: 40px;
        height: 80px;
        @include medium {
            padding-left: 50px;
        }
    }
    li a {
        color: gray;
    }
    li:before {
        content: "";
        content: "";
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background: url("https://cdn.worldofdypians.com/wod/white-paw.svg") no-repeat center;
        background-size: 24px;
        border: 2px solid #ffffff;
        position: absolute;
        left: -21px;
        top: 60%;
        transform: translateY(-28px);
        @include medium {
            width: 56px;
            height: 56px;
            border-radius: 50%;

            background-size: 30px;
            border: 2px solid #ffffff;
            position: absolute;
            left: -25px;
            top: 50%;
            transform: translateY(-28px);
        }
    }

    li:not(:last-child):after {
        content: "";
        width: 2px;
        height: 40px;
        background: #ffffff;
        position: absolute;
        left: -1px;
        top: calc(100% - 19px);
        @include medium {
            width: 2px;
            height: 24px;
            background: $white;
            position: absolute;
            left: 1px;
            top: calc(100% - 12px);
        }
    }
}

.roadmap-main-wrapper {
  // gap: 80px;
  max-width: 2400px;
  overflow-x: hidden;



  .roadmap-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 43px;
    color: #ffffff;
    text-align: center;
  }

  .roadmap-content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    width: 50%;
    @media screen and (max-width: 786px) {
      width: 100%;
    }
  }

  .roadmap-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;

    @media screen and (max-width: 786px) {
      grid-template-columns: auto;
    }
  }


  .roadmap-items {
    --mask: linear-gradient(-53.5deg, #0000 30.57px, #000 0);
    -webkit-mask: var(--mask);
    mask: var(--mask);
    width: 100%;
    background: linear-gradient(180deg, #5B08D0 44.48%, #B936F5 100%);;
    // bottom: 135px;
    // min-height: 230px;
    padding-top: 100px;

    .roadmap-items-wrapper {
      padding-bottom: 100px;
      @media screen and (max-width: 786px) {
        padding-bottom: 150px;
      }
    }
  }

  .quarter {
    width: 100%;
    position: relative;
    top: 80px;
    z-index: 2;

    @media screen and (max-width: 1620px) {
      bottom: 110px;
    }
    @media screen and (max-width: 1460px) {
      bottom: 105px;
    }
    @media screen and (max-width: 1350px) {
      bottom: 100px;
    }
    @media screen and (max-width: 786px) {
      bottom: 150px;
    }
  }
  .roadmap-image {
    width: 100%;
      
  }

  

  .step-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    transition: all 0.3s;
  }

  .step-title:hover {
    color: #7bd8b0;
  }

  .active-road-faq {
    border-top: 1px solid #965dea;
    border-bottom: 1px solid #965dea;
    background: #5207bc;
    padding: 10px 0px;
  }

  .step-content {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
  }

  .live-now-title {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    color: #7bd8b0;
    text-align: center;
    text-shadow: 0px 4px 10px rgba(123, 216, 176, 0.42);
  }
}

.roadmapbg {
  background-image: url('https://cdn.worldofdypians.com/wod/roadmapbg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.roadmap-slider-wrapper{
  .slick-slider{
    max-width: 350px !important;
  
    @media screen and (max-width: 1600px) {
    max-width: 320px !important;
    }
    @media screen and (max-width: 1500px) {
    max-width: 280px !important;
    }
  
    @media screen and (max-width: 1400px) {
    max-width: 250px !important;
    }
  
    @media screen and (max-width: 1300px) {
    max-width: 220px !important;
    }
    @media screen and (max-width: 1200px) {
    max-width: 190px !important;
    }
    @media screen and (max-width: 786px) {
    max-width: 350px !important;
    }
    @media screen and (max-width: 400px) {
    max-width: 300px !important;
    }
  }
}

.prev-arrow{
  transform: rotate(180deg);
  width: 35px;
  height: 35px;
  position: absolute;
  left: 0;
  bottom: 10%;
  cursor: pointer;
}

.next-arrow{
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0;
  bottom: 10%;
  cursor: pointer;
}
.roadmap-card{
  display: flex;
  flex-direction: column;
  height: fit-content;
 }
@media screen and (max-width: 786px) {
  .roadmap-card:nth-child(3){
   margin-top: -100px; 
  }
  .roadmap-card:nth-child(4){
   margin-top: -100px; 
  }
  .roadmap-card:nth-child(5){
   margin-top: -100px; 
  }

}
@import '../../../responsive';
@import '../../../colors';


.nft-caw-card {
  margin: 10px;
  width: 165px;
  cursor: pointer;
  @include medium {
    // margin: 17px;
  }
  .elevated-stake-container {
    background: white;
    // box-shadow: 12px 16px 44px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 10px;
    padding-bottom: 16px;
    @include medium {
      padding: 13px;
    }
    .nft-img {
      width: 100%;
      @include medium {
        width: 100%;
      }

      border-radius: 8px;
      object-fit: cover;
    }


.eth-rewards {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

    p {
      margin: 0;
      margin-top: 5px;
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $light-gray-99;
      @include medium {
        font-size: 16px;
        line-height: 20px;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nft-id {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: $black;
        margin: 0;
      }
      .img {
        cursor: pointer;
      }
    }
  }
  .earnwrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    margin-top: 10px;
    border-top: 1px solid #f5f5f5;

    p {
      margin: 0;
      font-weight: 400;
      font-size: 11px;
    }
  }
}

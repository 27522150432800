.govhero-wrapper {
  min-height: 100vh !important;
  background-image: url("https://cdn.worldofdypians.com/wod/govHeroBg.webp") !important;

  @media only screen and (max-width: 991px) {
    background-image: url("https://cdn.worldofdypians.com/wod/govHeroBgMobile.webp") !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}




.auto-button2 {
  width: fit-content;
}

.gov-desc {
  color: #eeedff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.gov-stat-item {
  border-radius: 20px;
  border: 1px solid #dcfb85;
  background: rgba(220, 251, 133, 0.04);
  backdrop-filter: blur(5.5px);
  width: 100%;
}

.gov-stat-title {
    color: #DCFB85;
    text-align: center;
    font-size: 42px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px; /* 57.143% */
  }
  
.gov-stat-desc {
  color: #eeedff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.gov-opacitywrapper {
    border-radius: 15px;
    padding: 0px; 
    z-index: 5;
    width: 100%;
  }
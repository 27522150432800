.tokenomics-chart {
  // width: 80%;
  @media screen and (max-width: 786px) {
    width: 100%;
  }
}

.allocation-wrapper {
  background: #fff;
  border: 4px solid rgba(85, 79, 216, 0.1);
  border-radius: 12px 0;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 16px;
}

.circulating-supply-title {
  color: #b8b8e0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.circulating-supply-amount {
  color: #554fd8;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.circulating-supply-wrapper {
  background: rgb(253 253 255 / 50%);
  border-radius: 12px 0;
  padding: 20px;
  width: 100%;
}

.tokenomics-wrapper-desc {
  color: #eeedff;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.new-tokenomics-bg {
  background-image: url('https://cdn.worldofdypians.com/wod/newTokenomicsBg.webp');
  background-size: 100%;
  background-repeat: no-repeat;
  border-bottom: 3px solid black;
}

.tokenomics-table-row {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 0px 4px 4px 0px rgba(20, 8, 54, 0.25);
  flex-wrap: nowrap;
}

.tokenomics-table-head {
  color: #eeedff;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  @media screen and (max-width: 786px) {
    // width: fit-content;
    font-size: 11px;
    font-weight: 500;
  }
}

.tokenomics-table-item {
  color: #a2adc8;
  text-align: center;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;

  font-weight: 700;
  line-height: 24px;
  @media screen and (max-width: 786px) {
    // width: fit-content;
    font-size: 14px;
    font-weight: 500;
  }
}

.tokenomics-dot {
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
}

.tokenomics-table-title {
  color: #dcfb85;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  white-space: nowrap;
  @media screen and (max-width: 500px) {
    font-size: 11px;
    line-height: normal;
  }
}

.tokenomicsTablewrapper {
  @media only screen and (max-width: 690px) {
    display: grid;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.wod-copy-span {
  color: #a2adc8;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.wod-address {
  color: #eeedff;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}

.wod-chart-wrapper {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 0px 4px 4px 0px rgba(20, 8, 54, 0.25);
  height: 392px;
  padding: 40px;
  @media screen and (max-width: 786px) {
    padding: 10px;
    height: fit-content;
  }
}

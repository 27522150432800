.security-wrapper {
  background: url('https://cdn.worldofdypians.com/wod/securityBg.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 500px;
  scroll-margin-top: 100px;


  .circlewrapper {
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 0px 5.485px 5.485px 0px rgba(255, 255, 255, 0.16) inset,
      0px 0px 43.884px 0px rgba(255, 255, 255, 0.08) inset;
    backdrop-filter: blur(18.513458251953125px);
    height: 100%;
    min-height: 482px;
    max-width: 482px;
    width: 100%;
  }

  
  .text-position {
    color: #DCFB85;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
  }
}

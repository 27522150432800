.popup-wrapper {
  position: fixed;
  width: fit-content;
  height: fit-content;
  top: -10000px;
  left: 0%;
  bottom: 0%;
  right: 0%;
  margin: auto;
  z-index: 1000;
  background: #1a1c39;
  border-radius: 16px;
  pointer-events: auto !important;
  transition: all 1s ease-in-out;
  width: 50%;

  @media screen and (max-width: 1455px) {
    width: 55%;
  }
  @media screen and (max-width: 1300px) {
    width: 70%;
  }
  @media screen and (max-width: 786px) {
    width: 90%;
  }
}

.domain-popup-wrapper {
  position: fixed;
  width: fit-content;
  height: fit-content;
  top: -10000px;
  left: 0%;
  bottom: 0%;
  right: 0%;
  margin: auto;
  z-index: 1000;
  background: #1a1c39;
  border-radius: 16px;
  pointer-events: auto !important;
  transition: all 1s ease-in-out;
  width: 50%;

  // @media screen and (max-width: 1455px) {
  //   width: 55%;
  // }
  @media screen and (max-width: 1300px) {
    width: 50% !important;
  }
  @media screen and (max-width: 991px) {
    width: 90% !important;
  }
}

.leaderboard-popup {
  background-image: url("https://cdn.worldofdypians.com/wod/newLeaderboardBackground.webp") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  @media screen and (max-width: 1525px) {
    width: 95% !important;
  }
  @media screen and (max-width: 786px) {
    width: 98% !important;
    background-size: cover;
  }
}

.table-outer-margin {
  // margin-top: -31px;
  height: 366px;
  overflow: auto;
  @media screen and (max-width: 786px) {
   height: 266px;
  }
}

.table-outer-margin::-webkit-scrollbar{
  border-radius: 5px !important;
  width: 15px !important;
}
.table-outer-margin::-webkit-scrollbar-thumb{
  border-radius: 5px !important;
}

.top100-text {
  color: #09F3D2;
  text-align: center; 
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.18px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.rank-popup {
  @media screen and (max-width: 1455px) {
    width: 95% !important;
  }
  @media screen and (max-width: 786px) {
    width: 98% !important;
  }
}

.popup-active {
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.5);
  top: 0% !important;
}

.land-nft-image {
  width: 450px;
}

.popup-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.018em;
  color: #f7f7fc;
  text-align: center;
}

.popup-title-2 {
  color: #f7f7fc;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.popup-paragraph {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-content {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  padding: 5px 30px;
  text-align: center;
  background: linear-gradient(
    269.91deg,
    rgba(86, 80, 216, 0) 0.07%,
    #5650d8 51.44%,
    rgba(86, 80, 216, 0) 100.72%
  );
}

.popup-span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.018em;
  width: 75%;
  text-align: center;
  color: #f7f7fc;
}

@media screen and (max-width: 786px) {
  .popup-span {
    width: 100%;
  }

  .land-nft-image {
    width: 75% !important;
  }
}

@keyframes premium {
  0% {
  }

  50% {
    filter: drop-shadow(0px 5px 10px rgba(0, 136, 135, 1));
  }

  100% {
  }
}
@keyframes golden {
  0% {
  }

  50% {
    filter: drop-shadow(0px 5px 10px rgba(88, 37, 112, 1));
  }

  100% {
  }
}


.activate-premium-btn {
  border-radius: 12px;
  border: 1px solid #f3bf09;
  background: radial-gradient(50% 50% at 50% 50%, #008887 0%, #006776 100%);
  color: #f3bf09;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  bottom: -10px;
}

.activate-golden-btn {
  border-radius: 12px;
  border: 1px solid #ffe8ff;
  background: radial-gradient(50% 50% at 50% 50%, #582570 0%, #582570 100%);
  color: #ffe8ff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  bottom: -10px;
}

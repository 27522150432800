.main-news-wrapper {
  background: rgba(24, 22, 54, 0.8);
  border: 2px solid rgba(24, 22, 54, 0.8);
  border-radius: 8px;
  padding: 1rem;
  height: 100%;
  @media screen and (max-width: 991px) {
    height: auto;
  }
  .main-news-inner {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  height: 100%;    

    .mainNews-title {
      font-size: 27px;
      cursor: pointer;
      z-index: 3;
      padding: 20px;
      font-weight: 600;
      position: absolute;
      @media screen and (max-width: 500px) {
        font-size: 14px;
        line-height: 25px !important;
        position: relative;
        padding: 0;
      }
    }
    .mainNews-title:hover {
      text-decoration: underline;
    }
  }
}

.mainNews-image {
  height: 500px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  object-fit: fill;
  transition: all 0.25s;
  aspect-ratio: 16/9;
  @media only screen and (max-width: 991px) {
    height: auto;
  }
}


.newsModal-wrapper {
  background: #181636;
  border: 2px solid #181636;
  border-radius: 8px;
  padding: 1rem;
}

.left-col-image {
  aspect-ratio: 16/8.5;
  object-fit: fill;

  @media only screen and (max-width: 991px) {
    height: auto;
  }

  @media screen and (max-width: 500px) {
    aspect-ratio: 16/12;
  }
}

.go-back-btn {
  background: #4ed5d2;
  border-radius: 8px;
  border: 2px solid #181636;
}
.go-back-btn:hover {
  border: 2px solid #4ed5d2;
}

.go-back-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.018em;
  color: #f7f7fc !important;
}

.left-col-title {
  font-size: 20px;

  @media only screen and (max-width: 575px) {
    font-size: 18px;
  }
}

.left-col-content iframe {
  width: 318px !important;
}

.left-col-content p {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: -0.018em !important;
  color: #f7f7fc !important;
}
.left-col-content a {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: -0.018em !important;
  color: #f7f7fc !important;
  background-color: transparent !important;
}

.left-col-content span {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: -0.018em !important;
  color: #f7f7fc !important;
}
.left-col-content strong {
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: -0.018em !important;
  color: #f7f7fc !important;
}
.left-col-content span {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: -0.018em !important;
  color: #f7f7fc !important;
}

.mainNews-date {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.018em;
  color: #dbd9ff !important;
  white-space: pre;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.featured-shadow {
  pointer-events: none;
  transition: all 0.25s;
  height: 101%;
  border-radius: 8px;
  z-index: 2;
  @media only screen and (max-width: 575px) {
    display: none;
  }
}

.mainNews-image-hover {
  // transition: all 0.25s;
  transform: scale(1.1);
}

.featured-shadow-hover {
  box-shadow: 100px 123px 200px 0px rgb(0 0 0 / 75%) inset;
  z-index: 2;
  position: relative;
}

.announcement-side-img {
  height: 200px;
  width: 100%;
  border-radius: 8px;
  --mask: linear-gradient(-135deg, #0000 20px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  cursor: pointer;
  object-fit: cover;
  transition: all 0.25s;
}

.announcement-min-img {
  height: 100px;
  width: 100px;
  border-radius: 8px;
  --mask: linear-gradient(-135deg, #0000 20px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  cursor: pointer;
  object-fit: cover;
  transition: all 0.25s;

  @media only screen and (max-width: 575px) {
    width: 100%;
    height: 160px;
  }
}

.top-rated-news {
  border-bottom: 2px solid #565891;
  padding-bottom: 8px;
  border-radius: 0px !important;
}

.top-rated-news:hover {
  .announcement-side-title {
    text-decoration: underline;
  }
}

.loadmore-btn {
  border-radius: 8px;
  background: #7e5ab870;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #fff;
  padding: 10px;
  outline: none !important;
  width: 117px;
  border: 1.5px solid #857dfa;
  margin-top: 20px;
}

.loadmore-btn:hover {
  background: linear-gradient(270deg, #7770e0, #3e3aa5, #554fd8, #7770e0);
  color: #fff;
}

.leftcol {
  border-right: 2px solid #565891;
  padding-right: 1rem;

  @media only screen and (max-width: 991px) {
    border-right: none;
    padding-right: 0;
    border-bottom: 2px solid #565891;
    padding-bottom: 1rem;
  }
}

.top-rated-news:last-child {
  border-bottom: none !important;
  padding-bottom: 0px !important;
}

.side-img-wrapper {
  --mask: linear-gradient(-135deg, #0000 20px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.announcement-side-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.018em;
  color: #f7f7fc;
}

.announcement-side-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.018em;
  color: #f7f7fc;
width: 75%;
  p {
    margin-bottom: 0 !important;
    span, strong {
      color: #dbd9ff !important;
    }
  }

  // p:empty {
  //   display: none;
  //   }

  span {
    color: #dbd9ff !important;
  }

  @media only screen and (max-width: 600px) {
   width: 100%;
  }
}

.ql-align-center {
  display: flex;
  justify-content: center;
}

.announcement-side-wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.topnews-wrapper {
  min-height: 630px;
}

.announcement-card {
  background: #181636;
  border: 2px solid #181636;
  border-radius: 8px;
  padding: 20px;
}

.sys-req {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #d9fa86;
  z-index: 5;
  cursor: pointer;
}

.sys-req:hover {
  text-decoration: underline;
  color: #d9fa86;
}

.announcement-side-wrapper :hover {
  .announcement-side-title {
    text-decoration: underline;
  }
}
.announcement-card:hover {
  border: 2px solid #20bbca !important;
}

.active-announcement {
  border: 2px solid #20bbca !important;
}

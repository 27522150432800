.navbar-wrapper {
  // <<<<<<< aldi/websiteUpdate
    position: fixed;
    margin: 0px auto;
    left: 16px;
    right: 16px;
    max-width: 2500px;
    top: 16px;
    min-height: 74px;
    // left: 50%;
    // transform: translate(-50%, 0);
    // right: 0;
    z-index: 8;
  // =======
    // position: sticky;
    // top: 0;
    // left: 0;
    // right: 0;
    // margin: auto;
  // >>>>>>> aldi/map
    border-bottom: 2px solid #525469;
    // max-width: 1500px;
    background: rgba(8, 11, 42, 0.8);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    // margin-right: 3px;
    .nav-anchor {
      font-weight: 600;
      height: 100%;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      text-decoration: none;
      transition: all 0.25s;
      font-family: "Poppins";

    }
  
    .nav-anchor:hover {
      color: #5690ff;
    }
  
    .activenavlink {
      color: #5690ff;
    }
  }
  
  .marketplace-navbar-wrapper {
    position: fixed;
    margin: 0px auto;
    left: 16px;
    right: 16px;
    max-width: 2500px;
    top: 84px;
    // left: 50%;
    // transform: translate(-50%, 0);
    // right: 0;
    z-index: 7;
    border-radius: 10px;
    border: 0.5px solid #525469;
    background: rgba(8, 11, 42, 0.9);
    backdrop-filter: blur(2px);
  
    .nav-anchor {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      height: 100%;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.25s;
    }
  
    .nav-anchor:hover {
      color: #5690ff;
    }
  
    .activenavlink {
      color: #5690ff;
    }
  }
  
  .linearborder2 {
    position: relative;
    padding: 1px;
    background: linear-gradient(89.7deg, #7bd8b0 0.23%, #dcfb85 99.72%);
    border-radius: 10px;
    height: fit-content;
  }
  
  .connectwallet {
    border-radius: 10px;
    background: #080b2a;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .menuwrapper {
    border-radius: 10px;
    border: 1px solid #b8b8e0;
    background: #080b2a;
    padding: 10px;
    position: absolute;
    top: 22px;
    right: 57px;
    width: 145px;
  }
  
  .menuwrapper2 {
    border-radius: 10px;
    border: 1px solid #b8b8e0;
    background: #080b2a;
    padding: 10px;
    position: absolute;
    top: 22px;
    right: 0px;
    width: 120px;
  }
  
  .logout-icon {
    transform: rotate(180deg);
  }
  
  .menuitem2 {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    padding: 5px;
    border-radius: 10px;
  }
  
  .menuitem2:hover {
    background: linear-gradient(
      268deg,
      rgba(255, 255, 255, 0.21) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    color: #11fed2;
  }
  
  .connected,
  .connected:hover {
    border-radius: 10px;
    border: 1px solid #b8b8e0;
    background: #080b2a;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.216px;
  }
  
  .account-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    // border: 2px solid #7371ff;
    // margin: auto;
    cursor: pointer;
  }
  
  .dropdown-toggle {
    background: transparent !important;
    border-radius: 10px;
    height: 32px;
    padding: 8px;
    height: 34px;
    // width: 150px;
    border: 1px solid #b8b8e0 !important;
    display: flex;
    align-items: center;
    @media screen and (max-width: 991px) {
      width: auto;
    }
  }
  
  .dropdown-toggle::after {
    display: none !important;
  }
  
  .dropdown-toggle2 {
    background: transparent !important;
    border-radius: 10px;
    height: 32px;
    padding: 8px;
    height: 34px;
    width: 134px;
    border: none !important;
    display: flex;
    align-items: center;
    @media screen and (max-width: 991px) {
      width: auto;
    }
  }
  
  .dropdown-toggle2::after {
    display: none !important;
  }
  
  .dropdown-menu.show {
    background: #26264f !important;
    box-shadow: 0px 12px 16px rgba(20, 20, 41, 0.4) !important;
    border-radius: 8px !important;
    padding: 0px;
    /* min-width: 100%; */
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #f7f7fc !important;
    padding: 10px 8px;
    border-bottom: 1px solid #3b3c68 !important;

  }
  
 
  .dropdown-item:hover {
    background: #3b3883;
  }
  
  .dropdown-title {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #8e97cd;
  }
  
  #dropdown-basic-button {
    width: fit-content;
    padding: 7px;
    height: -moz-fit-content;
    height: fit-content;
    border-right: 1px solid #51546f !important;
    border-radius: 7px 0 0 7px;
    background: rgba(81, 84, 111, 0.2) !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
  
  
  }
  
  .select-gray-txt {
    color: #8b8ca2;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 240% */

    // font-size: 18px;
    // color: #ffffff;
    text-decoration: none;
    // text-transform: uppercase;
    transition: all 0.25s;
    font-family: "Poppins";
  }
  
  #dropdown-basic-button2 {
    background: #2a2859 !important;
    border-radius: 8px;
    border-color: #57b6ab !important;
    width: fit-content;
  }
  
  #dropdown-basic-button4 {
    background: #2a2859 !important;
    border-radius: 50%;
    border: none !important;
    /* border-color: #57b6ab !important; */
    width: 56px !important;
    height: 56px !important;
  }
  
  .change-chain-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #f7f7fc !important;
  }
  
  .bell-amount {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #de5634;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -8px;
    right: -8px;
  
    span {
      color: #fff;
      text-align: center;
      font-size: 8px;
      font-style: normal;
      font-weight: 600;
      line-height: 1px;
    }
  }
  
  .notifications-wrapper {
    width: 430px;
    height: 360px;
    border-radius: 10px;
    border: 1px solid #b8b8e0;
    background: #080b2a;
    position: absolute;
    bottom: -360px;
    right: 0;
    visibility: hidden;
    opacity: 0;
    // overflow-y: scroll;
    transition: all 0.25s;
    @media screen and (max-width: 440px) {
      width: 90%;
    }
  }
  
  .inner-notifications {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .pending-notifications {
    border-radius: 10px;
    background: rgba(37, 39, 67, 0.45);
    box-shadow: 0px 4.300000190734863px 53px 0px rgba(0, 7, 72, 0.12);
    backdrop-filter: blur(13.380000114440918px);
    cursor: pointer;
  }
  
  .notifications-amount {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .pending-text {
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .open-notifications {
    visibility: visible;
    opacity: 1;
  }
  
  .inner-notifications::-webkit-scrollbar {
    display: none;
  }
  
  .green-dot {
    width: 11px;
    height: 6px;
    border-radius: 50%;
    background: #11fed2;
  }
  
  .notification-relative-time {
    color: #00fecf;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .header-notification-item {
    border-radius: 8px 8px 0 0;
    border-bottom: 2px solid #565891;
    transition: all 0.25s;
  }
  
  .header-notification-item:hover {
    background: rgba(54, 194, 207, 0.06);
  }
  
  .header-notification {
    cursor: pointer;
  }
  
  .header-notification:hover {
    border-radius: 6px;
    background: linear-gradient(
      268deg,
      rgba(255, 255, 255, 0.21) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    .notification-text {
      color: #11fed2 !important;
    }
  }
  
  .notification-text {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .popup-title {
    color: #f7f7fc;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .sign-out-btn {
    color: #ff8168;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background: transparent;
    border: none;
    text-transform: uppercase;
  }
  
  .domain-popup-desc {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .domain-popup-input {
    border-radius: 15px;
    border: 1px solid #8e97cd;
    background: rgba(23, 25, 50, 0.4);
    height: 45px;
    padding-left: 40px;
    color: #ffffff;
  }
  
  .domain-popup-input::placeholder {
    opacity: 0.7;
  }
  .domain-popup-input:focus-visible {
    border: 1px solid #8e97cd !important;
  }
  .domain-popup-input:focus {
    border: 1px solid #8e97cd !important;
  }
  .domain-search-icon {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  
  .domain-search-items {
    border-radius: 12px;
    background: #15172f;
    box-shadow: 0px 32px 64px 0px rgba(17, 17, 17, 0.12);
  }
  
  .domain-search-item {
    border-bottom: 2px solid #8e97cd;
    background: #15172f;
  }
  
  .domain-search-item:last-child {
    border-bottom: none !important;
  }
  
  .bnb-domain-icon-orange {
    width: 36px;
    height: 36px;
    background: linear-gradient(
      180deg,
      rgba(232, 128, 0, 0.25) -23.88%,
      rgba(232, 128, 0, 0) 99.99%
    );
    border-radius: 8px;
    border: 1px solid #e88000;
  
    span {
      color: #e88000;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .bnb-domain-icon-green {
    width: 36px;
    height: 36px;
    border-radius: 12px;
    border: 1px solid #3dbda7;
    background: linear-gradient(
      180deg,
      rgba(61, 189, 167, 0.25) -23.88%,
      rgba(61, 189, 167, 0) 99.99%
    );
  
    span {
      color: #3dbda7;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  
  .domain-name {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .name-service {
    color: #8e97cd;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .domain-available-tag {
    border-radius: 8px;
    background: linear-gradient(92deg, #3dbda7 -6.32%, #067d71 157.01%);
    padding-top: 4px;
    padding-bottom: 4px;
    span {
      color: #f7f7fc;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 10px;
    }
  }
  
  .domain-price {
    color: #f7f7fc;
    text-align: right;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 80% */
  }
  
  .domain-register {
    color: #7dd9af;
    text-align: right;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .header-dropdown {
    border-radius: 10px;
    border: 0.5px solid #525469;
    background: rgba(8, 11, 42, 1);
    width: 300px;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s;
  }

  .triangle {
    width: 0;
    height: 0;
    border-left: 22.5px solid transparent;
    border-right: 22.5px solid transparent;
    border-top: 22.5px solid rgba(8, 11, 42, 1); /* Color of the triangle */
    border-bottom: 2px solid transparent;
    transform: rotate(180deg);
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    margin: auto;
}

  // style={{ top: "150%", width: "300px", left: "-85px" }}
  
  // .dropdown-arrow {
  //   background: rgba(8, 11, 42, 1);
  //   border-color:rgba(8, 11, 42, 1);
  //   border-radius: 4px;
  //   border-style: solid;
  //   height: 16px;
  //   left: 50%;
  //   position: absolute;
  //   top: 0px;
  //   transform: rotate(45deg) translate(-50%);
  //   transform-origin: center center;
  //   transition: opacity 0.1s ease;
  //   width: 16px;
  //   z-index: 1;
  // }
  
  .header-dropdown-active {
    opacity: 1;
    visibility: visible;
  }

  .header-chain-grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
  }
  
  .chaindropdown {
    width: fit-content !important;
    .dropdown-menu.show {
      border-radius: 0px 0px 10px 10px !important;
      border: 0.5px solid #525469;
      background: rgba(8, 11, 42, 1) !important;
      backdrop-filter: blur(2px);
      width: 300px;
      right: -235px;
  
      // top: 17px !important; 
  
     .dropdown-item {
      border-bottom: none !important;
     }
    }
  }
  
  .dropdown-nav {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-decoration: none;
    border-radius: 10px;
    transition: all 0.25s;
  }
  
  .header-wallet-span {
    color: #8b8ca2;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    text-transform: none !important;
  }
  .header-wallet {
    color: #eeedff;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    text-transform: none !important;
  }
  
  .dropdown-nav:hover {
    background: rgba(86, 144, 255, 0.1);
    color: #5690ff !important;
  }
  .dropdown-nav-marketplace {
    color: #eeedff;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 10px;
    transition: all 0.25s;
  }
  
  .link-icon {
    position: absolute;
    right: 5%;
    bottom: -30%;
  }
  
  .account-not-linked {
    border: 0.2px solid #ff8168 !important;
    background: rgba(255, 129, 104, 0.1) !important;
  }
  .dropdown-nav-marketplace:hover {
    background: rgba(86, 144, 255, 0.1);
    color: #5690ff !important;
  }
  .nav-active {
    background: rgba(86, 144, 255, 0.1);
    color: #5690ff !important;
  }
  
  .new-connect-btn {
    border-radius: 8px;
  background: linear-gradient(270deg, #5690ff, #8c56ff, #8c56ff, #5690ff);
  background-size: 300%;
  background-position: 0 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: "Poppins";
    color: #ffffff !important;
    text-decoration: none;
    border: none;
    transition: all .25s;
    padding-left: 24px !important;
    padding-right: 24px !important;
    min-height: 48px;
  }
  .new-connect-btn:hover{
  background-position: 100% 0 !important;

  }
  
  .account-txt {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    font-family: "Montserrat";
    color: #ffffff;
    text-decoration: none;
  }
  
  .header-divider {
    height: 2px;
    background: #8e97cd;
    opacity: 0.5 !important;
  }
  
  .header-log-btn {
    width: 100%;
    border-radius: 7px;
  background: linear-gradient(270deg, #b936f5, #c64c85, #c64c85, #b936f5);
  background-size: 300%;
  background-position: 0 0;
    box-shadow: 0px 4px 4px 0px rgba(8, 11, 42, 0.25);
    border: none;
    color: #eeedff !important;
    text-align: center;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .25s;
    min-height: 48px;
  }
  .header-log-btn:hover {
    background-position: 100% 0 !important;
  }
  
  
  .account-btn-hover{
    border-radius: 12px;
    transition: all .25s;
  }
  
  .account-btn-hover:hover{
    background: rgba(255, 255, 255, 0.12);
  }
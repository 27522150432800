.wallet-balance {
  // background: #242240;
  border-radius: 8px;
  font-family: "Poppins", sans-serif !important;

  .multipe-wallet {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #ffffff;
  }

  // .bnb-wrapper {
  //   background: linear-gradient(90.83deg, #daaa0a 0.71%, #2e2853 65.22%);
  //   border-radius: 6px;
  // }

  // .avax-wrapper {
  //   background: linear-gradient(90.83deg, #df2d2e 0.71%, #2e2853 65.22%);
  //   border-radius: 6px;
  // }

  .chain-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #f7f7fc;
  }
}

.wallet-wrapper {
  background: rgba(8, 11, 42, 0.3);
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
}

.wallet-wrapper-active {
  background: rgba(27, 245, 255, 0.15);
  border: 2px solid #1bf5ff;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}

.wallet-wrapper-active2 {
  background: rgba(27, 245, 255, 0.15);
  border: 2px solid #1bf5ff;
  padding: 14px 10px;
  cursor: pointer;
  border-radius: 10px;
  height: 79px;
}

.wallet-wrapper-active-discount {
  background: linear-gradient(
    270deg,
    #7f2b8a 20.08%,
    #7e2b89 20.66%,
    #5c257b 37.71%,
    #412170 55.11%,
    #2e1e68 72.83%,
    #231c64 91.02%,
    #1f1b62 110.38%,
    #1f1b62 186.38%
  );
  border: 2px solid #7f2b8a;
  padding: 14px 10px;
  cursor: pointer;
  border-radius: 10px;
  height: 79px;
}
.rewardstable-wrapper2 {
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.dypiusprofile-banner {
  aspect-ratio: 1/0.4;
}

.wallet-wrapper-active-premium {
  background: rgba(255, 191, 0, 0.15);
  border: 2px solid #ffbf00;

  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}

// .become-premium-img {
//   scale: 1.6;
// }

.wallet-wrapper-alert {
  background: rgba(237, 130, 37, 0.15);
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
}

.wallet-span {
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.18px;
}

.wallet-address {
  color: rgba(247, 247, 252, 0.8);
  font-size: 12px;
  letter-spacing: -0.216px;
}
.walletinfo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #f7f7fc;
  opacity: 0.8;
}

.walletassoc-txt {
  color: rgba(255, 255, 255, 0.8);
  font-size: 10px;
}

.totalcollection {
  color: #11fed2;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.orangesection {
  background: linear-gradient(114.11deg, #faa525 3.05%, #c13509 97.98%);
  border-radius: 6px;
  padding: 10px;
  width: fit-content;
  height: 23px;
  display: flex;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    color: #ffffff;
  }
}

.explore-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 45px;
  color: #ffffff;
}

.bal-txt {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #b8b8e0;
}

.dyp-wrapper,
.idyp-wrapper {
  background: #252743;
  border-radius: 25px;
}

.wallet-amount {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.chain-icon {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 16px;
  height: 16px;
}

.balancewrapper3 {
  margin: 0 1rem;
}

.globaltext {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #b5ccd4;
}

.genesistext {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffb802;
}

.genesisimg {
  height: 70px;
  scale: 1.3;
}

.eth-chain-text {
  color: #5871d2;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.bnb-chain-text {
  color: #d9a908;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.blurrystate {
  filter: blur(6px);
}

.avax-chain-text {
  color: #df2c2d;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.account-nft-sort {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #e2e2e2;
  transition: all 0.25s;
  cursor: pointer;
}

.balanceseparator {
  height: 1px;
  background: #e2e2e2;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  border-radius: 500px;
}

.nft-sort-selected {
  color: #00fecf;
}

.account-nft-sort:hover {
  color: #00fecf;
}

.account-card-img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}


.account-card-img2 {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}

.nft-to-list-item-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #252743;
  padding: 5px;
  border-radius: 0 0 10px 10px;
}


.account-nft-card {
  background: #252743;
  // backdrop-filter: blur(26px);
  border-radius: 10px;
  cursor: pointer;
}

.row-nft-wrapper{
  overflow-x: auto;
  flex-wrap: nowrap;
grid-template-columns: repeat(6,1fr);
  display: grid;
}

.list-nft-card {
  border: 2px solid transparent;
}

.list-nft-card:hover {
  border: 2px solid #18ffff;
}


.account-nft-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #b8b8e0;
  margin-bottom: 0;
}

.account-nft-type {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #ffffff;
}

.account-view-all {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #7dd9af;
  margin-bottom: 0;
}

.rankings-outer-wrapper {
  height: auto;
  @media screen and (max-width: 786px) {
    height: fit-content;
    margin-bottom: 30px;
  }
}
.rankings-wrapper {
  height: 100%;
  @media screen and (max-width: 786px) {
    height: fit-content;
  }
}

.account-nft-sort-wrapper {
  width: 100%;

  @media screen and (max-width: 786px) {
    overflow-x: scroll;
  }
}

.MuiPaginationItem-root {
  color: white !important;
}

.profile-event-title {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profile-event-rewards {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.profile-time-number {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.profile-time-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}
.profile-time-number-2 {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 14px;
  color: #ffffff;
  @media screen and (max-width: 786px) {
    font-size: 20px;
  }
}

.profile-time-desc-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 12px;
  color: #ffffff;
  @media screen and (max-width: 786px) {
    font-size: 16px;
  }
}

.event-bottom-text {
  color: #18ffff;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.profile-events-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.profile-wrapper-title {
  color: #b8b8e0;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 15px;
}

.profile-event-item {
  border-radius: 8px;
  background: rgba(24, 255, 255, 0.1);
  // backdrop-filter: blur(10px);
  border-bottom: 1px solid #18ffff;
  cursor: pointer;
  height: fit-content;
}

.profile-event-top {
  border-radius: 8px;
  background: #252743;

  @media screen and (max-width: 360px) {
    .profilebannerimg {
      display: none;
    }
  }

  @media screen and (max-width: 330px) {
    flex-direction: column;
    align-items: start !important;
    gap: 10px;
  }
  // backdrop-filter: blur(10px);
}

.profile-event-bottom {
  border-radius: 0 0 8px 8px;

  @media screen and (max-width: 360px) {
    .eventusd {
      display: none !important;
    }
  }
}

// .profile-event-item:first-child {
//   transform: translateX(15px);
//   @media screen and (max-width: 786px) {
//     transform: translateX(0px);
//   }
// }

// .profile-event-item:nth-child(1) {
//   transform: translateX(20px);
//   @media screen and (max-width: 786px) {
//     transform: translateX(0px);
//   }
// }
// .profile-event-item:nth-child(2) {
//   transform: translateX(15px);
//   @media screen and (max-width: 786px) {
//     transform: translateX(0px);
//   }
// }
// .profile-event-item:nth-child(3) {
//   transform: translateX(-20px);
//   @media screen and (max-width: 786px) {
//     transform: translateX(0px);
//   }
// }
// .profile-event-item:nth-child(4) {
//   transform: translateX(-15px);
//   @media screen and (max-width: 786px) {
//     transform: translateX(0px);
//   }
// }

.profile-event-tag {
  border-radius: 3px;
  background: #18ffff;
  position: absolute;
  right: 0px;
  top: -12px;
  z-index: 2;
  span {
    color: #324b4b;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.profile-event-popup {
  max-height: 85%;
  overflow-y: auto;
  background: #1a1c39;
  border-radius: 10px;
  width: 50%;
  height: fit-content;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 9999;
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.5);
  pointer-events: auto;
  @media screen and (max-width: 1600px) {
    width: 75%;
  }
  @media screen and (max-width: 786px) {
    width: 95%;
  }
}

.event-popup-title {
  color: #f7f7fc;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 18px;
  }
}

.event-popup-status-live {
  border-radius: 3px;
  background: #18ffff;
  span {
    color: #324b4b;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.event-popup-status-upcoming {
  border-radius: 3px;
  background: #ec8123;
  span {
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.event-popup-status-expired {
  border-radius: 3px;
  background: #b8b8e0;
  span {
    color: #404040;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.profile-event-popup-wrapper {
  border-radius: 10px;
  background: #252743;
  height: 100%;
  // backdrop-filter: blur(26px);
}

.popup-second-title {
  color: #eeedff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 12px;
  }
}

.popup-rewards {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.event-popup-chain {
  color: #eeedff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-event-info {
  color: #eeedff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.popup-red-wrapper {
  border-radius: 10px;
  background: rgba(127, 20, 80, 0.2);
}

.event-my-earnings {
  color: #eeedff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.event-my-earnings3 {
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.event-earnings-coin {
  color: #eeedff;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.event-earnings-coin2 {
  color: #eeedff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.event-earnings-usd {
  color: #7dd9af;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ethpricerewards {
  color: #18ffff;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.get-beta-btn {
  border-radius: 20px;
  background: linear-gradient(90deg, #7bd8af 0%, #d9fa86 100%);
  color: #080b2a;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.specialstyle-wrapper {
  position: relative;
  left: 22px;
}

.specialstyle-wrapper-eth {
  position: relative;
  top: -10px;
  left: -19px;
}

.upcoming-tag {
  border-radius: 3px;
  background: #8c56ff;
  position: absolute;
  top: 0px;
  right: 0;
  .upcoming-text {
    color: #fff;
    text-align: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.live-tag {
  border-radius: 3px;
  background: #18ffff;
  position: absolute;
  top: 0px;
  right: 0;
  .live-text {
    color: #324b4b;
    text-align: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.expired-tag {
  border-radius: 3px;
  background: #b8b8e0;
  position: absolute;
  top: 0px;
  right: 0;
  .expired-text {
    color: #404040;
    text-align: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.event-slider-date {
  color: #b8b8e0;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.live-on {
  color: #7dd9af;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.live-on-date {
  color: #7dd9af;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  @media screen and (max-width: 786px) {
    font-size: 10px;
  }
}

.green-calendar {
  width: 24px;
  height: 24px;
  @media screen and (max-width: 786px) {
    width: 16px;
    height: 16px;
  }
}

.chest-event-title {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px; /* 108.333% */
  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
}

.specialCell {
  background: linear-gradient(
    270deg,
    rgba(255, 234, 206, 0.09) 0%,
    rgba(255, 234, 206, 0) 100%
  );
}

.bottomborder {
  border-radius: 0px 0px 8px 0;
}

.topborder {
  border-radius: 0px 8px 0 0;
}

.topbottom-border {
  border-radius: 0px 8px 8px 0;
}

.previousRewardsText {
  color: #ffa60c;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.all-past-total-earned {
  color: #ffa60c;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.all-past-total-earned-subtitle {
  color: #ffa60c;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.previousRewardsText {
  color: #ffa60c !important;
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.chest-event-desc {
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px; /* 108.333% */
  width: 70%;

  @media only screen and (max-width: 991px) {
    width: 100%;
  }
}


.chest-image {
  width: 70px;
  height: 70px;
  position: relative;
}

.chest-title {
  color: #fffef5;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  position: relative;
}

.chest-number {
  width: 24px;
  height: 24px;
  position: absolute;
  right: -5px;
  top: 5px;
}

 

.chest-number-text {
  color: #fff;
  text-align: center;
  text-shadow: 0px 1px 2px #003b43;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

@-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

.shake-bottom-animation {
  -webkit-animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    infinite both;
  animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
    both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-animation {
  -webkit-animation: fade-in 1.2s ease-in-out both;
  animation: fade-in 1.2s ease-in-out both;
}

.daily-rewards-popup {
  --mask: polygon(
    99.95% 7.803%,
    100% 7.712%,
    100% 7.613%,
    100% 1.023%,
    100% 0.532%,
    99.353% 0.532%,
    97.468% 0.532%,
    92.511% 0.532%,
    85.614% 0.532%,
    83.729% 0.532%,
    69.504% 0.532%,
    69.341% 0.532%,
    69.198% 0.591%,
    62.236% 3.438%,
    37.574% 3.438%,
    31.48% 0.604%,
    31.324% 0.532%,
    31.142% 0.532%,
    26.564% 0.532%,
    23.815% 0.532%,
    16.541% 0.532%,
    14.676% 0.532%,
    1.293% 0.532%,
    0.647% 0.532%,
    0.647% 1.023%,
    0.647% 14.38%,
    0.647% 14.482%,
    0.7% 14.576%,
    1.598% 16.145%,
    1.598% 33.394%,
    0.7% 34.962%,
    0.647% 35.056%,
    0.647% 35.158%,
    0.647% 46.793%,
    0.647% 46.895%,
    0.7% 46.989%,
    1.598% 48.558%,
    1.598% 88.661%,
    0.7% 90.23%,
    0.647% 90.324%,
    0.647% 90.426%,
    0.647% 99.509%,
    0.647% 100%,
    1.293% 100%,
    4.35% 100%,
    4.768% 100%,
    4.94% 99.71%,
    5.753% 98.338%,
    27.974% 98.338%,
    28.756% 99.704%,
    28.925% 100%,
    29.349% 100%,
    41.271% 100%,
    41.695% 100%,
    41.864% 99.704%,
    42.646% 98.338%,
    51.79% 98.338%,
    52.604% 99.71%,
    52.775% 100%,
    53.193% 100%,
    62.602% 100%,
    63.02% 100%,
    63.192% 99.71%,
    64.005% 98.338%,
    90.546% 98.338%,
    91.359% 99.71%,
    91.531% 100%,
    91.949% 100%,
    99.353% 100%,
    100% 100%,
    100% 99.509%,
    100% 91.257%,
    100% 91.155%,
    99.946% 91.061%,
    99.049% 89.492%,
    99.049% 79.902%,
    99.946% 78.333%,
    100% 78.24%,
    100% 78.138%,
    100% 55.401%,
    100% 55.299%,
    99.946% 55.205%,
    99.049% 53.637%,
    99.049% 9.433%,
    99.95% 7.803%
  );
  -webkit-mask: var(--mask);
  mask: var(--mask);
  background: #1a1c39;
}

.mask {
  // --mask: url("./assets/innerRewards.webp");
  // mask-repeat: no-repeat;
  // mask-size: cover;
  // -webkit-mask-image: url("./assets/innerRewards.webp");
  // mask-image: url("./assets/innerRewards.webp");
  // --webkit-mask-repeat: no-repeat;
  // -webkit-mask-size: cover;
  // -webkit-mask-position: center;
  // background: #1a1c39;
}

.overlay-container {
  position: fixed;
  top: 42px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.bonustitle {
  color: #fff;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
}

.outerbg {
  background: linear-gradient(to bottom, #00b9cd, #00d6be, #00b9cd);
}

.chestimg {
  width: 120px;
}

.rewardsgrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  // gap: 16px;
  place-items: center;
  // margin: auto;
  // height: 400px;
  // overflow-y: auto;
  // overflow-x: hidden;

  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.rewardinnerwrapper {
  overflow: auto;
}

.shadowwrapper {
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.5);
  z-index: 10;
  position: fixed;
  top: 0;
  left: 100%;
}

.closex {
  top: -45px;
  right: -25px;
  cursor: pointer;

  @media only screen and (max-width: 540px) {
    right: -5px;
    top: -70px;
  }
}

.dailyreward-separator {
  background: linear-gradient(
      270deg,
      rgba(255, 0, 0, 0) 81.29%,
      rgba(0, 0, 0, 0) 108.58%
    ),
    linear-gradient(
      270deg,
      rgba(118, 114, 114, 0) 2.92%,
      rgba(255, 255, 255, 0.26) 49.09%,
      rgba(255, 255, 255, 0) 98.18%
    );
  height: 7px;
  width: 100%;
}

.prizeswrapper {
  background: linear-gradient(
    270deg,
    rgba(90, 90, 90, 0) 38.72%,
    rgba(90, 90, 90, 0.4) 104.23%
  );
  // clip-path: polygon(100% 0%, 94.312% 100%, 0% 100%, 5.688% 0%, 100% 0%);
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #000;

  @media only screen and (max-width: 700px) {
    border: none;
  }
}

.paddingLeftCell {
  padding-left: 40px !important;
}

.specialRow {
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.myrewards-tbody {
  border-top: 1px solid #fff;
}

.chain-active-btn,
.chain-inactive-btn:hover {
  border-radius: 12px;
  border: 1px solid #ed8225;
  background: rgba(237, 130, 37, 0.7);
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px 10px;
}

.chain-inactive-btn {
  border-radius: 12px;
  border: 1px solid rgba(237, 130, 37, 0.4);
  background: rgba(237, 130, 37, 0.4);
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px 10px;
}

.prizeswrapper-premium {
  background: linear-gradient(
    270deg,
    rgba(219, 161, 34, 0) 38.72%,
    rgba(219, 161, 34, 0.4) 104.23%
  );
  // clip-path: polygon(100% 0%, 94.312% 100%, 0% 100%, 5.688% 0%, 100% 0%);
  padding: 10px;
  // background: #dba122;
  border-radius: 12px;
  border: 1px solid #dba122;
}

.reward-types {
  clip-path: polygon(100% 0%, 94.312% 100%, 0% 100%, 5.688% 0%, 100% 0%);
  padding: 10px 24px;
  cursor: pointer;
}

.reward-types-active {
  background: linear-gradient(
    270deg,
    rgba(0, 186, 204, 0.4) -59.13%,
    rgba(0, 186, 204, 0) 102.53%
  );
}

.reward-types-active-premium {
  background: linear-gradient(
    270deg,
    rgba(255, 191, 0, 0.9) -59.13%,
    rgba(255, 191, 0, 0) 102.53%
  );
}

.chestprizetitle {
  font-size: 17px;
}

 
 

.purple-container {
  border-radius: 10px;
  background: rgba(83, 84, 115, 0.15);
  border: 2px solid #080b2a;
  transition: all 0.25s ease;
  cursor: pointer;
}

.purple-container:hover {
  border: 2px solid #7dd9af;
  .profile-div-link {
    text-decoration: underline;
  }
}
.profile-div-title {
  color: #f7f7fc;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: "Poppins";
}

.become-premium-title {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}

.profile-rank {
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.05px;
  text-transform: uppercase;
}

.profile-div-link {
  color: #7dd9af;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile-div-chain {
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile-div-tokens {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile-div-usd {
  color: #7dd9af;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button__bar-2 {
  display: flex !important;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  // background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  width: 70px;
  text-align: center;
  margin-top: 20px;
  visibility: hidden;
}

.button__bar-2 li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
  visibility: hidden;
}

.button__bar-2 li button {
  border: none;
  background: #7dd9af66;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 12px;
  width: 10px;
  opacity: 0.3;
  border-radius: 7.5px;
  visibility: hidden;
}

.button__bar-2 li.slick-active button {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #7dd9af;
  box-shadow: 0px 4px 4px rgba(150, 100, 255, 0.24);
  visibility: hidden;
}

.red-container {
  border-radius: 10px;
  cursor: pointer;
  background: rgba(196, 44, 113, 0.15);
  border: 2px solid #080b2a;
  transition: all 0.25s ease;
}



.purplediv-stake {
  width: 4px;
  height: 40px;
  position: absolute;
  border-radius: 0 100px 100px 0;
  left: -1px;
  background: #00B7CC;
}

.free-plan-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #f7f7fc !important;
}

.subscribe-input-container {
  width: 20%;
  @media screen and (max-width: 786px) {
    width: 100%;
  }
}

.token-amount-placeholder {
  color: #d6d8e7;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 240% */
  letter-spacing: -0.18px;
}

.launchpad-dropdown {
  font-size: 12px;
  color: #b3b3b3 !important;
  background: #1a1c39 !important;
  border: 1px solid #8e97cd !important;
}

.dropdown-toggle {
  background: #3a377a;
  border-radius: 8px;
  height: 34px;
  padding: 8px;
  height: 34px;
  border: 1px solid #565891 !important;
}

.dropdown-toggle::after {
  display: none !important;
}


.dropdown-toggle-game {
  border-radius: 10px 0px 0px 10px;
border: 1px solid #8E97CD !important;
background: rgba(142, 151, 205, 0.20) !important; 
  height: 34px;
  padding: 8px; 
}

.dropdown-toggle-game::after {
  display: none !important;
}

.dropdown-menu.show {
  background: #26264f !important;
  box-shadow: 0px 12px 16px rgba(20, 20, 41, 0.4) !important;
  border-radius: 8px !important;
  padding: 0px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: #f7f7fc !important;
  padding: 10px 8px;
}

.dropdown-item:nth-child(1) {
  border-bottom: 1px solid #3b3c68 !important;
}

.dropdown-item:nth-child(2) {
  border-bottom: 1px solid #3b3c68 !important;
}

.dropdown-item:hover {
  background: #3b3883;
}

.launchpad-item {
  cursor: pointer;
  background: rgba(23, 25, 50, 0.4) !important;
  color: #8e97cd !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 18px !important;
}

.token-balance-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: -0.018em;
  color: #4cd0cd !important;
}

.account-token-amount {
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  text-align: right;
  letter-spacing: -0.018em;
  color: #4cd0cd !important;
}

.subscription-token-wrapper {
  background: #2b2a59;
  border-radius: 8px;
}

.usdt-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #f7f7fc !important;
}

.form-divider {
  background: #565891 !important;
  border-radius: 50px;
  height: 1px;
  width: 100% !important;
  opacity: 1 !important;
}

.success-btn {
  color: #ffffff !important;
  background: linear-gradient(90.74deg, #75cac2 0%, #57b6ab 100%);
  border: none !important;
  border-radius: 8px;
  opacity: 1 !important;
}

.launchpad-item:hover {
  background: #1e1c40 !important;
  color: #f7f7fc !important;
}

.premium-active-container {
  border-radius: 10px;
  background: rgba(246, 198, 0, 0.15);
  border: 2px solid #080b2a;
  transition: all 0.25s ease;
}

.red-container:hover {
  border: 2px solid #7dd9af;
  .profile-div-link {
    text-decoration: underline;
  }
}

 

.game-event-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.game-event-patchtitle {
  color: #e2e2e2;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.game-event-download {
  background: #fff;
  border-radius: 12px;
  padding: 3px 10px;
  color: #080b2a;
  font-size: 12pt;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: fit-content;
  display: flex;
  align-items: center;
  align-self: center;
  transition: all .25s;
}

.game-event-download:hover{
  background: #080b2a;
  color: #fff;
}


.game-img-second {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.requirements-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border: 0.2px solid #7dd9af;
  background: rgba(18, 48, 72, 0.8);
  cursor: pointer;
}

.sys-req-text {
  color: #7dd9af;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.news-game-wrapper {
  border-radius: 0px 0px 20px 20px;
  background: rgba(16, 24, 48, 0.9);
}

.news-game-wrapper:hover {
  .game-news-title {
    text-decoration: underline;
  }
}

.game-news-title {
  color: #fcfcf7;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.rewardstable-wrapper {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  @media only screen and (max-width: 991px) {
    height: auto;
  }
}

.rewardstable-wrapper2 {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  @media only screen and (max-width: 991px) {
    overflow-x: auto;
  }
}

.game-news-desc {
  p,
  span {
    color: #fcfcf7 !important;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    margin: 0;
  }
  color: #fcfcf7;
  font-size: 8px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
}

.game-news-date {
  color: #7dd9af;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1px;
  letter-spacing: -0.144px;
}

.table-separator {
  height: 1px;
  width: 100%;
  background: linear-gradient(
    90deg,
    #fff 45.42%,
    rgba(255, 255, 255, 0) 101.86%
  );
}

.myrewards-table {
  @media only screen and (max-width: 991px) {
    max-height: 300px;
    overflow-x: auto;
    white-space: nowrap;
    display: block;
  }
  .myrewards-th {
    color: #fff;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  tr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
 
  .myrewards-td-main {
    color: #fff;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .myrewards-td-second {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.leftbold-text {
  color: #fff;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.rightlight-text {
  color: #fff;
  font-family: "Poppins";
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.all-total-earned {
  color: #ffeace;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.all-total-earned-subtitle {
  color: #ffeace;
  text-align: center;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.total-earnings-purple-wrapper {
  border-radius: 10px;
  background: linear-gradient(180deg, #8262d0 0%, #482293 100%);
}

.total-rewards-amount {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.total-rewards-desc {
  color: #ffe4fc;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reward-category-text {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.216px;
}

.active-reward {
  border-radius: 4px;
  border: 0.5px solid #ff9100;
  background: rgba(255, 145, 0, 0.2);
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.216px;
  padding: 5px 11px;
}

.past-reward {
  color: #747687;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.216px;
  background: transparent;
  border-radius: 4px;
  padding: 5px 11px;
  border: 1px solid transparent;
}

.reward-category-item-active {
  border-radius: 12px;
  border: 1px solid #ff9100;
  background: rgba(255, 145, 0, 0.2);
  cursor: pointer;
}

.reward-category-item {
  border-radius: 12px;
  background: #232541;
  cursor: pointer;
  border: 1px solid transparent;
}

.reward-item-desc-active {
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
}

.reward-item-desc {
  color: #747687;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
}

.small-separator-active {
  border-radius: 500px;
  background: #ff9100;
  height: 1px;
  width: 100%;
}

.small-separator {
  border-radius: 500px;
  background: #747687;
  height: 1px;
  width: 100%;
}

.reward-category-amount-active {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.reward-category-amount {
  color: #747687;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.item-name-title {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.item-name-title-selected {
  color: #ff9100;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.item-name-wrapper {
  border-radius: 12px;
  background: #232541;
  border: 1px solid transparent;
}

.item-name-wrapper-selected {
  border: 1px solid #ff9100;
  border-radius: 12px;
  background: #232541;
}

.item-name-left {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.item-name-right {
  color: #ff9100;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.treasure-hunt-item-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media only screen and (max-width: 500px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

.treasure-hunt-item-wrapper-active {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media only screen and (max-width: 500px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

.reward-category-items-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  border-radius: 12px;
  height: 100%;
  background: #121235;

  @media only screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

.premium-img {
  position: absolute;
  right: 0px;
}

.daily-rewards-img {
  position: absolute;
  width: 70%;
  right: -20%;
  bottom: -7%;
  z-index: 1;
  pointer-events: none;
  // animation: pulsate 1.5s ease-out infinite;
  @media screen and (max-width: 991px) {
    width: auto;
    top: -43%;
    right: -20%;
  }
}
.daily-rewards-img-finished {
  position: absolute;
  width: 55%;
  right: 0%;
  top: -20%;
  // animation: pulsate 1.5s ease-out infinite;
  filter: drop-shadow(0px 0px 10px rgba(202, 64, 22, 0.6));

  @media screen and (max-width: 991px) {
    width: auto;
    top: -35%;
  }
}

.progress-bar-group {
  position: absolute;
  left: 5%;
  bottom: 10%;
}

.green-progress-outer {
  position: relative;
  width: 60px;
  height: 16px;
  border-radius: 12px;
  border: 1px solid rgba(0, 186, 204, 1);
}

.green-progress-inner {
  height: 100%;
  border-radius: 12px;
  background: rgba(0, 186, 204, 0.4);
}
.yellow-progress-outer {
  position: relative;
  width: 120px;
  height: 16px;
  border-radius: 12px;
  border: 1px solid #ff5ea0;
}

.yellow-progress-inner {
  height: 100%;
  border-radius: 12px;
  background: #ff5ea0;
}

.chest-progress {
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.daily-bonus-wrapper {
  position: relative;
  height: 145px;
  border-radius: 10px;
  background: radial-gradient(
    356.23% 141.42% at 100% 100%,
    #5b0025 30%,
    #730230 100%
  );
  // border: 2px solid #080b2a;
  transition: all 0.25s ease;
  cursor: pointer;
  @media screen and (max-width: 786px) {
    height: 170px;
  }
}

.daily-bonus-wrapper:hover {
  filter: drop-shadow(0px 0px 10px #c09645);
  .profile-div-link {
    text-decoration: underline;
  }
}

.daily-bonus-wrapper:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  // background-image: url("./newAssets/dailyChest2.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: right;
  pointer-events: none;
}
.game-events-wrapper {
  position: relative;
  height: 170px;
  border-radius: 10px;
  background: linear-gradient(to right, #252743 -13.33%, #124287 70.47%);
  // backdrop-filter: blur(26px);
  border: 2px solid #080b2a;
  transition: all 0.25s ease;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    margin-top: 40px;
  }
}

.game-events-wrapper:hover {
  border: 2px solid #7dd9af;
  .profile-div-link {
    text-decoration: underline;
  }
}

.game-events-wrapper:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; 
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: right;
  pointer-events: none;
}
.profile-staking-wrapper {
  position: relative;
  height: 170px;
  background: linear-gradient(
    to right,
    #252743 -11.81%,
    rgba(72, 33, 147, 0.8) 130.24%
  );
  border-radius: 10px;
  border: 2px solid #080b2a;
  transition: all 0.25s ease;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    margin-top: 40px;
  }
}

.profile-staking-wrapper:hover {
  border: 2px solid #7dd9af;
  .profile-div-link {
    text-decoration: underline;
  }
}
.profile-staking-wrapper:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  // background-image: url("./newAssets/stakingBg.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: right;
  pointer-events: none;
}
.profile-mint-wrapper {
  position: relative;
  height: 170px;
  background: linear-gradient(90deg, #181b38 0%, #11503d 168.67%);
  border-radius: 10px;
  border: 2px solid #080b2a;
  transition: all 0.25s ease;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    margin-top: 40px;
  }
}

.profile-mint-wrapper:hover {
  border: 2px solid #7dd9af;
  .profile-div-link {
    text-decoration: underline;
  }
}
.profile-mint-wrapper:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  // background-image: url("./newAssets/stakingBg.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: right;
  pointer-events: none;
}

 
.instakeWrapper3 {
  border-radius: 8px;
  background: linear-gradient(92deg, #2a6fc4 -6.32%, #951c6a 157.01%);
  width: fit-content;
  padding: 5px 10px;
  height: 16px;
}

.instaketxt2 {
  color: #f7f7fc;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 7px;
  display: flex;
}

.view-rewards-btn {
  border-radius: 8px;
  background: linear-gradient(92deg, #377fbb -6.32%, #137265 157.01%);
  width: fit-content;
  padding: 5px 10px;
  height: 16px;
}

 
 
.my-rewards-wrapper {
  position: relative;
  height: 170px;
  background: linear-gradient(to right, #252743, rgba(18, 51, 41, 0.8));
  border-radius: 10px;
  border: 2px solid #080b2a;
  transition: all 0.25s ease;
  cursor: pointer;
}

.my-rewards-wrapper:hover {
  border: 2px solid #7dd9af;
  .profile-div-link {
    text-decoration: underline;
  }
}
 

.my-total-rewards-wrapper {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  @media screen and (max-width: 1450px) {
    margin: 0px;
    top: 45px;
    left: 15px;
  }
  @media screen and (max-width: 991px) {
    margin: auto 15px;
  }
}

.my-total-rewards {
  color: #ffeace;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.my-total-earned {
  color: #ffeace;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.green-div {
  width: 3px;
  height: 25px;
  border-radius: 0px 100px 100px 0px;
  background: #7dd9af;
  position: absolute;
  left: 0;
  top: 12.5px;
}

.red-div {
  width: 3px;
  height: 25px;
  border-radius: 0px 100px 100px 0px;
  background: #b44169;
  position: absolute;
  left: 0;
  top: 12.5px;
}

.green-link {
  cursor: pointer;
}

.green-link:hover {
  .profile-div-link {
    text-decoration: underline;
  }
}

.linear-divider {
  background: linear-gradient(
    90deg,
    #fff 45.42%,
    rgba(255, 255, 255, 0) 101.86%
  );
  width: 100%;
  height: 3px;
}

.my-special-rewards {
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.my-special-rewards-value {
  color: #fff;
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rewards-success-title {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.chest-blur {
  filter: blur(5px);
}

.chest-lock {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.chest-prize-title {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.05px;
  text-transform: uppercase;
}

.reward-prize-title {
  color: #f7f7fc;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.random-reward {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

 

.reward-subtitle {
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: fit-content;
}

.ready-border {
  width: 96px;
  height: 40px;
  animation: pulsate 1.5s ease-out infinite;
}
.ready-border-2 {
  width: 96px;
  height: 40px;
  filter: drop-shadow(0px 0px 10px rgba(202, 64, 22, 0.6));
}

@keyframes pulsate {
  0% {
    filter: drop-shadow(0px 0px 0px rgba(202, 64, 22, 0.6));
  }
  50% {
    filter: drop-shadow(0px 0px 15px rgba(202, 64, 22, 0.6));
  }
  100% {
    filter: drop-shadow(0px 0px 0px rgba(202, 64, 22, 0.6));
  }
}
.ready-to-claim {
  color: #f7f7fc;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 32%;
  right: 0;
  left: 0;
  bottom: 0;
  height: fit-content;
  z-index: 2;
}

.timer-text {
  color: #f7f7fc;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 8px;
  position: relative;
  // left: 20px;
}

.timer-text2 {
  color: #f7f7fc;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 8px;
  position: relative;
  // left: 20px;
}
.headericon {
  position: absolute;
  right: 0px;
  bottom: 10px;
}

.game-events-img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 75%;
  @media screen and (max-width: 991px) {
    width: auto;
  }
}

.profile-div-desc {
  color: #f7f7fc;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 43%;
}

.profile-staking-img {
  position: absolute;
  bottom: 25%;
  right: -4%;
  width: 70%;
  @media screen and (max-width: 991px) {
    width: 50%;
    bottom: 10%;
  }
}

.rewards-div {
  border-radius: 10px;
  background: rgba(5, 3, 3, 0.3);
}

.event-popup-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-bottom: 30px;
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.event-popup-item {
  // width: 20% !important;
  border-radius: 10px;
  border: 2px solid #8e97cd;
  background: rgba(23, 25, 50, 0.4);
  box-shadow: 0px 0px 8px 0px rgba(142, 151, 205, 0.15);
  cursor: pointer;
  transition: all 0.25s;
  h6 {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media screen and (max-width: 500px) {
    h6 {
      font-size: 9px;
    }
  }
}

@media screen and (max-width: 500px) {
  .popup-events-container {
    flex-wrap: wrap;
  }
}

.event-popup-item-2 {
  min-height: 90px;
  border-radius: 10px;
  border: 2px solid #8e97cd;
  background: rgba(23, 25, 50, 0.4);
  box-shadow: 0px 0px 8px 0px rgba(142, 151, 205, 0.15);
  p {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.event-popup-item:hover {
  border: 2px solid #7dd9af;
}

.selected-popup-item {
  border: 2px solid #25e08c !important;
}

@media screen and (max-width: 786px) {
  .nft-wrapper-popup {
    height: 80%;
    overflow-y: hidden;
  }
}
@media screen and (max-width: 786px) {
  .nft-popup-container {
    max-height: 540px;
    overflow-y: scroll;
  }
}

.daily-error-text-wrapper {
  border-radius: 8px;
  background: rgba(237, 130, 37, 0.2);
  width: fit-content;
}
.progress-bar-title {
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.new-bundle-btn {
  border: 2px solid #41435a;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #e2e2e2;
}

.portfolio {
  height: 0px;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
}

.portfolio-open {
  height: fit-content;
}

.new-my-rewards-wrapper {
  border-radius: 12px;
  background: #18193c;
}

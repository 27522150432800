.user-cardBg {
  // background-color: transparent;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 14px;
  position: relative;
  display: table;
  font-family: "Poppins", sans-serif !important;
}

.user-cardImg {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(33, 36, 64, 0.15) 100%
  );
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  border: 1px solid #494b61;
}

.bordereddiv {
  padding: 10px 20px;
  border-radius: 10px;
  border-bottom: 1px solid #494b61;
}

.premiumtext-active {
  color: #ffbf00;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Poppins";
  width: fit-content;
}

.premiumtext {
  color: #1bf5ff;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Poppins";
  width: fit-content;
}

.premiumtext-alert {
  color: #ed8225;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: fit-content;
  font-family: "Poppins";
}

.logoutbtn {
  color: #ff6232;
  white-space: nowrap;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-cardImg-active {
  background: linear-gradient(
    180deg,
    rgba(27, 245, 255, 0.15) 0%,
    rgba(118, 15, 90, 0) 100%,
    rgba(33, 36, 64, 0) 100%
  );
  border-radius: 10px;
  border: 1px solid #494b61;
}

.user-cardImg-active-premium {
  background: linear-gradient(
    180deg,
    rgba(255, 191, 0, 0.3) 0%,
    rgba(33, 36, 64, 0.01) 100%
  );
  border-radius: 10px;
  border: 1px solid #494b61;
}

.hoverpremium:hover {
  filter: drop-shadow(0px 4px 25px #dfb625);
}
.hoveractive,
.hoverpremium {
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}
.hoveractive:hover {
  filter: drop-shadow(0px 0px 6px #00bacc);
}

.hoverdiscount:hover {
  filter: drop-shadow(0px 0px 6px #7f2b8a);
}

.user-cardImg-alert {
  background: linear-gradient(
    180deg,
    rgba(237, 130, 37, 0.15) 0%,
    rgba(33, 36, 64, 0) 100%
  );
  border-radius: 10px;
  border: 1px solid #494b61;
}

.sync-wrapper {
  border-radius: 10px;
  // background: rgba(237, 130, 37, 0.08);
}

.sync-txt {
  color: #ffffff;
  font-size: 12px;
}

.syncbtn {
  border-radius: 7px;
  background: linear-gradient(90deg, #ea6278 0%, #ff8168 166.8%);
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}
.syncbtn:hover {
  border-radius: 7px;
  background: linear-gradient(90deg, #ff8168 166.8%, #ea6278 0%);
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.syncicon {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.wallettitle {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
}

.walletAddr {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
}

.userAvatar-alert {
  width: 80px;
  height: 80px;
  filter: drop-shadow(0px 4px 16px rgba(237, 130, 37, 0.6));
}

.userAvatar {
  width: 80px;
  height: 80px;
  filter: drop-shadow(0px 4px 16px rgba(0, 254, 207, 0.3));
}

.userAvatarPremium {
  width: 80px;
  // height: 80px;
  filter: drop-shadow(0px 4px 16px rgba(253, 157, 8, 0.3));
}

.ranktitle {
  color: rgba(247, 247, 247, 0.8);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.018em;
}

.borderdiv {
  border-bottom: 1px solid #757086;
}

.userRank {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.usernametext {
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
}

.emailtext {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
}

.blancetext {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: -0.018em;
  color: #8c56ff;
}

.balancetext {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #ffffff;
}

.copybtn {
  background: linear-gradient(
      0deg,
      rgba(0, 254, 207, 0.3),
      rgba(0, 254, 207, 0.3)
    ),
    linear-gradient(
      112.83deg,
      rgba(255, 255, 255, 0.47) 0%,
      rgba(255, 255, 255, 0) 110.84%
    );
  box-shadow: 0px 4px 12px rgba(0, 254, 207, 0.24);
  backdrop-filter: blur(4px);
  border-radius: 6px;
  width: fit-content;
  color: #fff;
}

.copybtninner {
  background: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dypMedal {
  top: -30px;
  right: 0px;
  width: 72px;
  height: 68px;
}

 
.failbtn {
  border-radius: 20px;
  background: linear-gradient(90deg, #ffac45 0%, #ff6232 100%);
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}

.signintext {
  color: #82daac;
  font-size: 12px;
  font-weight: 600;
}

.signintext:hover,
.logouttext:hover {
  text-decoration: underline;
}

.logouttext {
  color: #ff6232;
  font-size: 12px;
}

.playerimg {
  width: 396px;
  height: 361px;
  bottom: 0;
  object-fit: contain;
  right: -80px;

  @media screen and (max-width: 991px) {
    display: none !important;
  }
}

 

.golden-pass-tag {
  position: absolute;
  right: 0;
  top: -20%;
  margin: auto;
}

.player-rank-wrapper {
  width: 250px;
  height: 79px;
  transition: all 0.25s ease-in-out;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.player-bust {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  z-index: 2;
  transition: all 0.25s ease-in-out;
  @media screen and (max-width: 786px) {
    width: 40%;
  }
}

.player-ranking-text {
  color: #fff;
  font-weight: 800;

  opacity: 0;

  transition: all 0.25s ease-in-out;
}

.ranking-text-wrapper {
  width: fit-content;
  height: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  margin: auto;
}

.player-rank-dropdown {
  width: 130%;
  border-radius: 10px;
  border: 2px solid #b8b8e0;
  background: #1b1c3a;
  position: absolute;
  top: 110%;
  right: 0%;
  z-index: 5;
  @media screen and (max-width: 786px) {
    width: 100%;
  }
}

.chain-score {
  color: rgb(243, 192, 9);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chain-score-span {
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.open-ranks-text {
  color: #ffc700;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rank-title {
  font-size: 16px;
  font-weight: 800;
}

.rank-active-div {
  padding: 5px 30px;
  border-radius: 12px;
  border: 1px solid #1bf5ff;
  background: rgba(27, 245, 255, 0.2);
  h6 {
    color: #f7f7ff;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Oxanium";
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 0;
  }
}
.rank-inactive-div {
  padding: 5px 30px;
  border-radius: 12px;
  border: 1px solid #747687;
  background: #1e2237;
  h6 {
    color: #747687;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Oxanium";
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 0px !important;
  }
}

.rank-rewards {
  background: rgba(151, 89, 192, 0.3);
  border-radius: 12px;
  width: 100%;
}

.rank-rewards-text {
  font-size: 18px;
  font-family: "Oxanium";
  color: #fff;
  font-weight: 700;
}
.rank-rewards-placeholder {
  font-size: 14px;
  font-family: "Montserrat";
  color: #fff;
  font-weight: normal;
}

.needed-points {
  color: #f4e27b;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.needed-points-span {
  color: #f7f7ff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rank-img-active {
  width: 140%;
}

.rank-img-inactive {
  position: relative;
  width: 140%;
  opacity: 0.8;
  filter: brightness(0.7);
}
.rank-title-inactive {
  opacity: 0.8;
  filter: brightness(0.7);
}

.my-rank-text {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.player-rank-text {
  color: #1bf5ff;
  font-size: 20px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.bnb-rank-wrapper {
  border-radius: 12px;
  background: #1e2237;
}
.skale-rank-wrapper {
  border-radius: 12px;
  background: #1e2237;
}

.new-rank-span {
  color: #f7f7ff;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bnb-rank-score {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.skale-rank-score {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.rank-logo-position {
  position: absolute;
  left: 0;
  right: 0;
  top: -15%;
  margin: auto;
}

.new-rank-divider {
  width: 100%;
  height: 2px;
  color: #b8b8e0;
  background: #b8b8e0;
}

.current-rank {
  color: #f7f7ff;
  font-family: "Oxanium";
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.rank-progress-bar {
  border-radius: 8px;
  background: #262640;
  height: 25px;
  width: 100%;
  box-shadow: 0px 0px 4px 0px rgba(27, 245, 255, 0.25);
  border-radius: 20px;
}
.rank-progress-bar-active {
  border-radius: 8px;
  background: #262640;
  height: 25px;
  width: 100%;
  box-shadow: 0px 0px 4px 0px rgba(255, 199, 0, 0.25);
  border-radius: 20px;
}

.rank-current-progress {
  height: 25px;
  border-radius: 20px;
  border: 0.5px solid #1bf5ff;
  background: linear-gradient(90deg, #116d77 34%, #00bcc6 100%);
  min-width: 35%;
  position: absolute;
  left: 0;
  z-index: 0;
}
.rank-current-progress-active {
  height: 25px;
  border-radius: 20px;
  border: 0.5px solid #ffc700;
  background: linear-gradient(90deg, #b26510 34%, #e9c333 100%);
  min-width: 35%;
  position: absolute;
  left: 0;
  z-index: 0;
}

.rank-current-score {
  color: #f7f7ff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  z-index: 1;
}

.rank-current-reward {
  color: #feb45d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rank-popup-btn {
  border-radius: 12px;
  border: 2px solid #ffc700;
  cursor: pointer;
  transition: all 0.25s;
}

.rank-popup-btn:hover {
  box-shadow: 0px 0px 8px 0px rgba(255, 199, 0, 1);
}

@media screen and (max-width: 991px) {
  .rank-outer-wrapper {
    width: 100%;
    margin-top: 40px;
  }
}

.rank-wrapper-inner {
  .player-rank-text {
    font-size: 15px;
  }
  filter: grayscale(0) !important;
  img {
    filter: grayscale(0) !important;
  }
  h6 {
    filter: grayscale(0) !important;
  }
}

.rank-pointer-arrow {
  width: 70px;
  height: auto;
}

.leaderboard-title-span {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.single-rank-wrapper {
  width: 15%;
  @media screen and (max-width: 786px) {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .rankingsPopupTitle {
    font-size: 12px !important;
  }
}

.rank-popup {
  background-size: 100% 100% !important;
}
.rank-popup::before {
  content: "";
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(26, 28, 57, 0.8);
}

@keyframes shine2 {
  0% {
    filter: drop-shadow(0px 0px 6px #ddb800);
  }
  50% {
    filter: drop-shadow(0px 0px 0px #ddb800);
  }
  100% {
    filter: drop-shadow(0px 0px 6px #ddb800);
  }
}

.premium-offer-tag {
  position: absolute;
  right: 15%;
  top: -5%;
  cursor: pointer;
  z-index: 6;
  transition: all 0.25s;
  animation: shine2 2s infinite;
}

.premium-offer-tag:hover {
  filter: drop-shadow(0px 0px 6px #ddb800);
  animation: none !important;
}

.premium-shadow-active {
  filter: drop-shadow(0px 0px 6px #ddb800);
  animation: none !important;
}

.premium-exclusive {
  position: absolute;
  right: 11%;
  top: 13%;
  cursor: pointer;
  width: 200px;
  z-index: 5;
  transition: all 2s ease-in-out;

  transition: all 0.5s ease-in-out;
  transform: translateY(-30px);
  opacity: 0;
  pointer-events: none;
}

.premium-exclusive-active {
  transform: translateY(0px);
  opacity: 1;
  filter: drop-shadow(0px 0px 6px #ddb800);
  pointer-events: auto;
}

@keyframes shine {
  0% {
    box-shadow: 0px 0px 8px 0px rgba(255, 199, 0, 1);
  }
  50% {
    box-shadow: 0px 0px 0px 0px rgba(255, 199, 0, 1);
  }
  100% {
    box-shadow: 0px 0px 8px 0px rgba(255, 199, 0, 1);
  }
}

.activate-bonus-btn {
  border-radius: 12px;
  background: linear-gradient(89deg, #b26510 3.75%, #e9c333 99.13%);
  color: #4b0900;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  outline: none;
  border: none;
  transition: all 0.25s;
  animation: shine 2s infinite;
}

.activate-bonus-btn:hover {
  box-shadow: 0px 0px 8px 0px rgba(255, 199, 0, 1);
}

.your-score-span {
  color: #f7f7fc;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.your-score-text {
  color: #ffc700;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.activated-user-score {
  border-radius: 20px;
  border: 0.5px solid #ffc700;
  background: rgba(255, 199, 0, 0.1);
  width: fit-content;
}

// .premium-offer-wrapper{
//   position: relative;
//   border-radius: 20px;
// border: 1px solid #8262D0;
// background: linear-gradient(180deg, #8262D0 0%, #482293 100%);
// h6{
//   color: #FFF;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: normal;
//   z-index: 1;
// }
// span{
//   color: #FFF;
// font-size: 12px;
// font-style: normal;
// font-weight: 400;
// line-height: normal;
// z-index: 1;
// }
// }

// .premium-offer-btn{
//   border-radius: 8px;
// background: linear-gradient(95deg, #D62267 0.51%, #EA1AA5 100%);
// color: #FFF;
// text-align: center;
// font-size: 14px;
// font-style: normal;
// font-weight: 600;
// line-height: normal;
// outline: none;
// border: none;
// z-index: 1;
// }

// .big-prize{
//   font-size: 36px !important;
//   font-weight: 800 !important;
//   transform: rotate(-8deg);
//   color: #F4E27B !important;
//   -webkit-text-stroke: 0.3px #F3C009;
//   @media screen and (max-width: 1100px) {
//     font-size: 22px !important;

//   }
// }

.bigTextYellow {
  color: #f3bf09;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
}

.smallTextYellow {
  color: #ffefca;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
}

.rank-premium-status-wrapper {
  border-radius: 6px;
  border: 1px solid #2c2d52;
}

.rank-premium-status-wrapper:hover {
  border: 1px solid #f3bf09;
}

.rank-upgrade-status {
  color: #f7f7ff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.216px;
}

.rank-upgrade-status-bottom {
  color: #f3bf09;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.144px;
}

.starscontentwrapper {
  border-radius: 2px;
  background: #2c2d52;
  height: fit-content;
  display: flex;
}

.chainstatustxt {
  color: #edeeff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 240% */
}

.startext {
  color: #ffd37e;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  // line-height: 16px; /* 160% */
}

.total-stars-wrapper {
  background-image: url("https://cdn.worldofdypians.com/wod/totalStarsBg.svg");
  border-radius: 12px;
  background-size: cover;
}

.total-stars-wrapper2 {
  background-image: url("https://cdn.worldofdypians.com/wod/totalStarsBg.svg");
  border-radius: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.golden-pass-wrapper2 {
  background-image: url("https://cdn.worldofdypians.com/wod/goldenPassbg.svg");
  border-radius: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all .25s;
  cursor: pointer;
}

.domain-name-wrapper2 {
  background: linear-gradient(to right, #0078a6, #49c278);
  border-radius: 12px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all .25s;
}

.domain-name-wrapper2:hover{
  border: 2px solid #2980e8;
}

.total-stars-premium-wrapper2 {
  background-image: url("https://cdn.worldofdypians.com/wod/totalStarsPremium.svg");
  border-radius: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all .25s;
}

.total-stars-premium-wrapper2:hover {
  filter: drop-shadow(0px 0px 10px #c09645);
}

.golden-pass-wrapper2:hover {
  filter: drop-shadow(0px 0px 10px #c09645);
}

.activate-btn {
  background: #536bbe;
  border-radius: 6px;
  color: #ebeeff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  transition: all 0.25s ease-in-out;
}

.activate-btn2 {
  background: #ac1186;
  border-radius: 6px;
  color: #ebeeff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  transition: all 0.25s ease-in-out;
}

.activate-btn:hover {
  background: #53a5be;
  border-radius: 6px;
  color: #ebeeff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}

.activate-btn2:hover {
  background: #9411ac;
  border-radius: 6px;
  color: #ebeeff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}

.total-stars-span {
  color: #ffefca;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.total-stars-amount {
  color: #f3bf09;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.profile-header-wrapper {
  flex-direction: row;
  align-items: center !important;
}

.buttons-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  @media screen and (max-width: 991px) {
    grid-template-columns: auto !important;
    width: 100%;
  }
}
.buttons-grid-premium {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  @media screen and (max-width: 991px) {
    grid-template-columns: auto !important;
    width: 100%;
  }
}

.rank-dropdown-span {
  color: #ffc700;
  text-align: center;
  font-family: "Oxanium";
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.rank-dropdown-item {
  border-radius: 10px;
  background: #2c2d52;
  position: relative;
}

.rank-dropdown-text {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 10px */
}

.rank-dropdown-text-yellow {
  color: #ffd37e;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 12px */
}

.rank-item-wrapper {
  border-radius: 6px;
  background: #35365e;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rankChain {
  position: absolute;
  left: -10px;
}

.rank-chain-status-grid{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 12px; 
  @media screen and (max-width: 786px) {
    grid-template-columns: repeat(1,1fr);
  }
}

.rank-chain-status-item{
  border-radius: 10px;
  background: #2c2d52;
}
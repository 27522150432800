.whitelist-mainhero-wrapper {
  min-height: 65vh !important;
  background-image: url("https://cdn.worldofdypians.com/wod/whitelistBg.webp") !important;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
border-bottom: none !important;
  @media only screen and (max-width: 991px) {
    min-height: auto !important;
  }

  // @media only screen and (max-width: 450px) {
  //   background-image: linear-gradient(
  //       180deg,
  //       #080b2a 0%,
  //       rgba(8, 11, 42, 0.6) 100%
  //     ),
  //     url("../../../../assets/bridgeAssets/whitelistBgMobile.webp") !important;
  //   background-position: center -150px;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  // }
}

.otc-wrapper {
  width: 25%;
    margin: auto;
    @media only screen and (max-width: 991px) {
      width: 100% !important;
    }
}

.whitelist-hero-wrapper{
  margin-top: 150px;
  margin-bottom: 50px;
  @media screen and (max-width: 786px) {
    margin-top: 40px;
  }
}


.seedClass {
  border-radius: 10px;
  border: 1px solid #b8b8e0;
  backdrop-filter: blur(12.5px);
  min-height: 160px;
  background: url('https://cdn.worldofdypians.com/wod/seedInactive.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.seedClassActive,.seedClass:hover {
  border-radius: 10px;
  backdrop-filter: blur(12.5px);
  min-height: 160px;
  background: url('https://cdn.worldofdypians.com/wod/seedActive.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #F3BF09;
}


.advisorsClass {
  border-radius: 10px;
  border: 1px solid #b8b8e0;
  backdrop-filter: blur(12.5px);
  min-height: 160px;
  background: url('https://cdn.worldofdypians.com/wod/advisorsInactive.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.advisorsClassActive,.advisorsClass:hover {
  border-radius: 10px;
  backdrop-filter: blur(12.5px);
  min-height: 160px;
  background: url('https://cdn.worldofdypians.com/wod/advisorsActive.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #F3BF09;
}

.privateClass {
  border-radius: 10px;
  border: 1px solid #b8b8e0;
  backdrop-filter: blur(12.5px);
  min-height: 160px;
  background: url('https://cdn.worldofdypians.com/wod/privateInactive.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.privateClassActive, .privateClass:hover {
  border-radius: 10px;
  backdrop-filter: blur(12.5px);
  min-height: 160px;
  background: url('https://cdn.worldofdypians.com/wod/privateActive.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #F3BF09;
}

.kolClass {
  border-radius: 10px;
  border: 1px solid #b8b8e0;
  backdrop-filter: blur(12.5px);
  min-height: 160px;
  background: url('https://cdn.worldofdypians.com/wod/kolInactive.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.kolClassActive, .kolClass:hover {
  border-radius: 10px;
  backdrop-filter: blur(12.5px);
  min-height: 160px;
  background: url('https://cdn.worldofdypians.com/wod/kolActive.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #F3BF09;
}
.leaflet-container {
  height: 100vh !important;
  // background: black !important;
  width: 100vw;
  background-image: url("https://cdn.worldofdypians.com/wod/stars.webp") !important;
  background-size: 100%;
}


.leaflet-popup-content-wrapper {
  background: linear-gradient(
    126.67deg,
    #181636 35.2%,
    #7133e5 191.04%
  ) !important;
}

/* The sidebar menu */
.sidebar {
  height: 87%; /* 100% Full-height */
  width: 400px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 500; /* Stay on top */
  top: 12%;
  left: 15px;
  border-radius: 10px;
  border: 0.5px solid #525469;
  background: rgba(8, 11, 42, 0.9);
  backdrop-filter: blur(2px);
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

.sidebar.closed {
  width: 0;
  opacity: 0;
  padding-left: 0;
  padding-right: 0;
  pointer-events: none;
}

@media screen and (max-width: 786px) {
  .sidebar {
    width: 100%;
    height: calc(-60px + 100%);
    left: 0;
  }
}

.map-sidebar-scroll {
  height: 78%;
  overflow-y: scroll;
}

.marker-details-2 {
  position: fixed;
  right: -450px;
  top: 12%;
  width: 450px;
  height: 87%;
  border-radius: 10px;
  border: 0.5px solid #525469;
  background: rgba(8, 11, 42, 0.9);
  z-index: 500;
  transition: all 0.25s;
}

/* The sidebar links */
.sidebar a {
  padding: 8px 0px 8px 8px;
  text-decoration: none;
  font-size: 25px;
  color: #fff;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  top: 0;
  font-size: 36px;
  cursor: pointer;
}

/* The button used to open the sidebar */
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #080b2a;
  color: white;
  padding: 10px 15px;
  border: none;
  position: absolute;
  width: 56px;
  height: 56px;
  top: 110px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-radius: 8px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s; /* If you want a transition effect */
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}

.MuiAccordionSummary-content > span {
  display: flex;
  align-items: center !important;
  color: white;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiPaper-root {
  background: none !important;
}

.chain-popup .leaflet-popup-content-wrapper {
  width: 100%;
}

.area-text-marker {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-align: center;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.city-text-marker {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-align: center;
  color: white;
  font-weight: 800;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  pointer-events: none;
}

.map-fallback {
  width: 100vw;
  height: 100vh;
  background: white;
}

.area-marker {
  pointer-events: none !important;
}
.sea-text-marker {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  text-align: center;
  color: #3d6b80 !important;
  font-weight: 600;
  text-transform: uppercase;
  /* -webkit-text-stroke-width: 1px; */
  /* -webkit-text-stroke-color: black; */
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.map-search {
  padding-left: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: rgba(73, 71, 115, 0.2);
  border: 1px solid #828fbb;
  width: 100%;
  border-radius: 10px;
}

.search-box {
  position: absolute;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  z-index: 6;
  background: #080b2a;
  border-radius: 12px;
  border: 2px solid #525469;
}

.search-item {
  color: #fff;
  cursor: pointer;
  transition: all 0.25s;
}

.search-item:hover {
  background: rgba(255, 255, 255, 0.3);
}

.closebtn-2 {
  padding: 8px 0px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #828fbb;
  display: block;
  transition: 0.3s;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.land-btn {
  align-items: center;
  background: linear-gradient(90deg, #7bd8af -10.16%, #d9fa86 129.3%);
  border-radius: 8px;
  color: #080b2a;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 600;
  line-height: 20px;
  padding: 10px 15px;
  border: none;
}

.land-title {
  color: #b8b8e0;
  font-size: 20px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 700;
  line-height: 29px;
}

.marker-details {
  position: fixed;
  right: 0;
  top: 90px;
  width: 350px;
  height: calc(-90px + 100vh);
  background: #080b2a;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000; /* Ensure it's on top of the map */
}

@media screen and (max-width: 768px) {
  .sidebar {
    top: 59px;
  }

  .openbtn {
    top: 74px;
  }

  .events-arrow {
    top: 74px !important;
  }

  .marker-details-2 {
    width: 100%;
    right: -100%;
    height: calc(-59px + 100%);
    top: 59px;
  }
  .leaflet-container {
    position: absolute !important;
    bottom: 0 !important;
    height: calc(-59px + 100%) !important;
  }
}

.marker-events-active {
  right: 15px !important;
}

.closebtn-3 {
  text-decoration: none;
  font-size: 25px;
  color: #fff;
  display: block;
  transition: 0.3s;
  cursor: pointer;
}

.custom-marker-content {
  color: #b8b8e0;
  font-size: 12px;
  font-style: normal;
  font-family: "Montserrat";
  font-weight: 500;
}

.events-arrow {
  background: #080b2a;
  border-radius: 8px;
  position: absolute;
  right: 15px;
  top: 110px;
  z-index: 1000;
  cursor: pointer;
  transition: all 0.25s;
  img {
    transform: rotate(180deg);
  }
}

.events-arrow-open {
  right: 465px;
  img {
    transform: rotate(0deg);
  }
}

.map-event-item {
  background: #080b2a;
  border-radius: 12px;
  cursor: pointer;
}

.map-event-title {
  font-size: 16px;
  font-family: "Montserrat" !important;
  font-weight: 700;
  color: #fff;
}

.map-event-type {
  border: 1px solid;
  border-color: green;
  border-radius: 4px;
  width: fit-content;
}

.map-event-type-span {
  font-size: 11px;
  line-height: 14px;
  font-family: "Montserrat" !important;
  color: green;
  font-weight: 500;
}

.map-event-duration {
  font-size: 16px;
  font-family: "Montserrat" !important;
  font-weight: 700;
  color: #fff;
}

.map-event-img {
  height: 100%;
  width: 150px;
  border-radius: 12px;
  object-fit: cover;
}

.arrow-pos-2 {
  position: relative !important;
  right: 15px !important;
  top: 15px !important;
}

#animals-header {
  padding: 0 !important;
}

.map-sidebar-btn {
  cursor: pointer;
  width: 100%;
  background: linear-gradient(269.74deg, #ffffff36 -254.09%, #fff0 99.84%);
  border-radius: 8px;
}

.switches-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.switch-btn {
  background: rgba(25, 28, 67, 0.2);
  border: 1px solid #262b4a;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.35s;
  span {
    color: #828fbb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 300% */
  }
}

.switch-btn:hover {
  background: rgba(246, 202, 74, 0.2);
  border: 1px solid #f6ca4a;
  span {
    color: #f1edff;
  }
}

.switch-btn-active {
  background: rgba(246, 202, 74, 0.2);
  border: 1px solid #f6ca4a;
  span {
    color: #f1edff;
  }
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

#panel1a-header {
  min-height: auto !important;
  padding: 10px;
  background: linear-gradient(269.74deg, #00ffff36 -254.09%, #00ffff00 99.84%);
  border-radius: 8px;
}
#panel2a-header {
  min-height: auto !important;
  padding: 10px;
  background: linear-gradient(269.74deg, #00a86b36 -254.09%, #00a86b00 99.84%);

  border-radius: 8px;
}

.genesis-focus-btn {
  background: linear-gradient(269.74deg, #d8b9ff36 -254.09%, #d8b9ff00 99.84%);
}

.leaflet-control-container {
  display: none !important;
}
.map-sidebar-title-wrapper {
  border-radius: 10px;
  border-bottom: 0.5px solid #525469;
  // background: rgba(8, 11, 42, 0.90);
  box-shadow: 0px 4px 4px 0px rgba(8, 11, 42, 0.25);
  backdrop-filter: blur(2px);
}

.map-sidebar-title {
  color: #eeedff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
}

.sidebar-section-title {
  color: #eeedff;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 200% */
}

.section-switch-btn {
  background: rgba(25, 28, 67, 0.2);
  border: 1px solid #262b4a;
  border-radius: 10px;
  transition: all 0.35s;
  cursor: pointer;
  span {
    color: #828fbb;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 240% */
  }
}

.inactive-switch {
  opacity: 0.5;
  pointer-events: none;
}

.section-switch-btn:hover {
  background: rgba(73, 71, 115, 0.7);
  border: 1px solid #828fbb;
  span {
    color: #f1edff;
  }
}

.section-switch-btn-active {
  background: rgba(73, 71, 115, 0.7);
  border: 1px solid #828fbb;
  span {
    color: #f1edff;
  }
}

.accordion-side-text {
  color: #f1edff;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 240% */
}

#areas-header {
  min-height: 42px !important;
}

.MuiPaper-elevation {
  margin-top: 0 !important;
}

.dypians-areas-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.areas-grid-item {
  background: rgba(25, 28, 67, 0.2);
  border: 1px solid #262b4a;
  border-radius: 10px;
  transition: all 0.35s;
  cursor: pointer;
  span {
    color: #828fbb;
    font-family: "Montserrat";
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 240% */
  }
}
.areas-grid-item:hover {
  background: rgba(73, 71, 115, 0.7);
  border: 1px solid #828fbb;
  span {
    color: #f1edff;
  }
}

.areas-grid-item-active {
  background: rgba(73, 71, 115, 0.7);
  border: 1px solid #828fbb;
  span {
    color: #f1edff;
  }
}

.chain-marker-info-wrapper {
  border-radius: 4px;
  background: #161b37;
  backdrop-filter: blur(2px);
}
.chain-marker-title {
  color: #edeeff;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 300% */
  text-transform: uppercase;
}

.chain-marker-info-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  height: 87px;
}

.chain-marker-info-grid-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.chain-marker-info-item {
  border-radius: 4px;
  background: #2d314f;
  h6 {
    color: #edeeff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  span {
    color: #828fbb;
    text-align: center;
    font-family: "Montserrat";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
  }
}

.chain-marker-benefits-title {
  color: #edeeff;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.green-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #00c6c3;
}

.marker-event-item {
  border-radius: 4px;
  border: 0.5px solid #2d314f;
  background: #161b37;
  transition: all 0.25s;
  span {
    color: rgba(130, 143, 187, 0.98);
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 150% */
  }
}

.marker-event-item:hover {
  border-radius: 4px;
  border: 0.5px solid #ffc808;
  background: rgba(255, 200, 8, 0.1);
  span {
    color: #ffc808;
  }
}

.marker-event-time {
  color: #eeedff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.144px;
  cursor: pointer;
}

.marker-event-time-active {
  border-radius: 4px;
  border: 0.5px solid #ffc808;
  background: rgba(255, 200, 8, 0.2);
  color: #ffc808;
}

.no-features-span {
  color: #828fbb;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 300% */
}

@media screen and (max-height: 900px) {
  .marker-details-inner-wrapper {
    overflow-y: scroll;
    height: 80%;
  }
}
@media screen and (max-width: 786px) {
  .marker-details-inner-wrapper {
    overflow-y: scroll;
    height: 100% !important;
  }
  .marker-events-active {
    right: 0 !important;
  }
}

.clear-all-span {
  color: #ffb45b;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 300% */
  cursor: pointer;
  position: absolute;
  bottom: 1%;
}

.animals-dropdown-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.custom-chain-icon {
  width: 36px !important;
  height: 44px !important;
  transition: all 0.35s;
}

.custom-chain-icon:hover {
  transform: scale(1.2);
}

.marker-pin {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -6px;
  top: -4px;
}

.ends-in-span {
  color: #828fbb;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 240% */
}

.treasure-hunt-marker-span {
  color: #fdf7ff;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
}

.treasure-hunt-marker-value {
  color: #2df5f2;
  text-align: right;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.marker-divider {
  height: 2px;
  width: 100%;
  background: rgba(130, 143, 187, 0.5);
}

.map-stars-wrapper {
  background-image: url("https://cdn.worldofdypians.com/wod/myStarsBg.png");
  background-size: 100% 100%;
  border-radius: 12px;
}

.collected-stars-span {
  color: #ffefca;
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.collected-stars-value {
  color: #f3bf09;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.marker-teleports-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.genesis-marker-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.caws-hero-title{
    font-style: normal;
font-weight: 800;
font-size: 32px;
line-height: 43px;
color: #FFFFFF;
}

.caws-hero-content{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
}

.caws-world-wrapper{
    background: rgba(255, 255, 255, 0.04);
backdrop-filter: blur(5.5px);
// transform: matrix(-1, 0, 0, 1, 0, 0);
}



@media screen and (max-width: 786px) {
    .caws-banner{
        width: 300px;
    }

    .caws-hero-title{
        font-size: 26px;
        width: 100% !important;
    }
}
.containergecko {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  justify-content: space-between;
}

.create_acc_bnb {
  color: #f1edff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.createplayertxt2 {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
}



.bottomGroup_graytxt {
  color: #c0cbf7;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.18px;
  margin: 0;
}

.bottomGroup_login {
  color: #09fbd3;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.18px;
  text-decoration-line: underline;
  cursor: pointer;
  margin: 0;
}

.container {
  padding: 50px 60px 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerbnb {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: space-between;
}

.labelBNB {
  color: #c0cbf7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.createplayertxt {
  color: #18ffff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
}

.errorText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.018em;
  color: #fe7a00;
}

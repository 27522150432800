.beta-main-wrapper {
  gap: 80px;
  max-width: 2400px;
  overflow-x: hidden;
  background-image: url('https://cdn.worldofdypians.com/wod/joinBetaBg.webp');
  background-size: 100% 125%;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
      
    }
}

.beta-tester-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}

.beta-tester-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.sub-info-item{
  font-weight: 400;
font-size: 12px;
line-height: 24px;
color: #FFFFFF
}

.join-beta-banner {
  background-image: url('https://cdn.worldofdypians.com/wod/betaBanner.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: 4px solid #6309A6;
  border-radius: 6px;
  padding: 50px 70px;
  @media screen and (max-width: 786px) {
  padding: 50px 10px;
  background-size: cover;
  background-position: bottom;
    
  }
}

.info-grid-item{
  width: 25%;
  @media screen and (max-width: 1500px) {
    width: 30%;
  }
  @media screen and (max-width: 786px) {
    width: 100%;
    margin-bottom: 40px;
  }
}

.become-tester-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  width: 75%;
}

.registered {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  @media screen and (max-width: 786px) {
    white-space: pre;
  }
}
.registered-amount {
    font-weight: 700;
font-size: 32px;
line-height: 40px;
color: #8C56FF;

}

.beta-info-wrapper{
    background-image: url('https://cdn.worldofdypians.com/wod/listBackground.png');
    background-size: 100% 100%;
    // width: 25%;
}

.beta-info-title{
    font-family: 'Organetto';
font-weight: 800;
font-size: 24px;
line-height: 32px;
color: #FFFFFF;

@media screen and (max-width: 786px) {
  font-size: 20px;
}

}

.beta-info-item{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

@media screen and (max-width: 786px) {
  .info-grid-item:last-child{
    margin-bottom: 0 !important;
  }


}
.placeholder-wrapper {
  display: flex;
  flex-wrap: wrap;

  .placeholder-content {
    height: fit-content;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background: #f5f5f5;
    opacity: 0.8;
    gap: 10px;
    .placeholder-content-img {
      width: 110px;
      border: 1px dashed;
      border-radius: 8px;
    }

    .placeholder-content-text {
      color: #7d7d7d;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }

    .placeholder-button {
      background: #ffffff;
      border: 1px solid #ff0000;
      box-sizing: border-box;
      border-radius: 6px;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      padding: 5px 18px;
      color: #ff0000;
    }
  }
}

.unstaketitle {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;

  color: #f7f7fc;
}

.unstakedesc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #c0cbf7;
}

.staking-pool-banner {
  background: url('https://cdn.worldofdypians.com/wod/wodStakingBg.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.staking-banner-title {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.staking-banner-desc {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

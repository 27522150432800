.system-requirements-modal {
  background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  border-radius: 8px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 40%;
  height: fit-content;
  z-index: 10;
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
  pointer-events: auto !important;

  @media screen and (max-width: 786px) {
    width: 95%;
    height: fit-content;
    overflow: hidden;
  }

  .overall-requirements {
    overflow-y: auto;
    height: 80%;
  }

  .sys-req-title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
  }

  .requirements-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .requirements-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  .requirements-divider {
    background: #ffffff;
    opacity: 0.3;
    height: 2px;
  }

  .close-modal {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #f7f7fc;
    cursor: pointer;
  }
}

.epicgame {
  // padding: 10px 11px 9px 11px;
  width: 38px;
  height: 42px;
}

.epicgame2 {
  width: 30px;
  height: 34px;
}

.bigtitle {
  color: #f7f7fc;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

.small-green-btn {
  border-radius: 10px;
  background: linear-gradient(90deg, #7bd8b0 0%, #dcfb85 98.44%);
  color: #080b2a;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  outline: none;
  width: 60px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-hero-title {
  color: #fff;
  text-align: start;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
  font-size: 50px !important;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 32px !important;
    text-align: center;
  }
}

.main-hero-subtitle {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.video-wrapper {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // min-height: 100vh;
  background-position: center;
  background-image: url(https://cdn.worldofdypians.com/wod/mainHero.webp);
  border-bottom: 3px solid black;
  @media only screen and (max-width: 991px) {
    min-height: auto;
    background-size: cover;
  }

  .video-main-title {
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 57px;
    text-align: center;
    text-transform: uppercase;
    width: 40%;
    color: #f7f7fc;

    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
  }

  .video-button-border {
    position: absolute;
  }

  .sys-req {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #d9fa86;
    z-index: 5;
    cursor: pointer;
  }

  .sys-req:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 786px) {
  .video-main-title {
    font-size: 24px !important;
    line-height: 32px !important;
    width: 100% !important;
  }
}

.video-diagonal {
  position: relative;
  --mask: linear-gradient(250deg, #0000 25%, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);

  @media screen and (max-width: 991px) {
    -webkit-mask: none;
    mask: none;
  }
}

.leaderboard-bg { 
  background-repeat: no-repeat;
  background-position: right;
  background-size: 60% 100%;

  @media screen and (max-width: 991px) {
    background-size: cover;
  }
}

.download-buttons-wrapper {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 35%;
  bottom: 0;
  margin: auto;
  z-index: 1;

  @media screen and (max-width: 1600px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  @media screen and (max-width: 991px) {
    position: relative;
    margin-top: 8rem;
    margin-bottom: 2rem;
  }
}

.token-launch-wrapper {
  background: linear-gradient(148deg, rgb(255 254 254 / 20%), rgb(237 222 222 / 20%));;
  border-radius: 12px;
}

.buy-wod-popup-title{
  color: #EEEDFF;
font-family: "Montserrat";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 171.429% */
}

.home-epic{
  cursor: pointer;
  transition: all .25s;
  z-index: 2;
}

.home-epic:hover{
  transform: scale(1.05);
  filter: drop-shadow(0px 4px 6px rgba(243, 191, 9, 0.45));
}

.home-line{
  position: absolute;
  top: 22%;  
    left: 50%; 

    transform: translate(-50%, -50%);
}
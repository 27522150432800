.our-team {
  // min-height: 700px;
  position: relative;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background: url("https://cdn.worldofdypians.com/wod/ourTeamBg.webp");

  .our-team-background {
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // right: 0;
    // left: 0;
    // height: 700px;
    // border-radius: 56px 0px;
    // z-index: -1;
  }

  .team-container {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    place-items: self-start center;
    @media screen and (max-width: 1650px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 500px) {
      place-items: self-start center;
    }

    .team-card {
      width: 315px;
      height: 420px;
      background: linear-gradient(
        131deg,
        rgba(170, 20, 240, 0.1) 8.94%,
        rgba(170, 20, 240, 0.05) 85.2%
      );
      box-shadow: 0px 32px 64px rgb(20 20 43 / 8%);
      position: relative;
      z-index: 1;
      --mask: linear-gradient(-135deg, #0000 30px, #000 0);
      -webkit-mask: var(--mask);
      mask: var(--mask);

      .text-position {
        color: #dcfb85;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .profile-pic {
        width: 270px;
        height: 280px;
        object-fit: cover;
        --mask: linear-gradient(-135deg, #0000 30px, #000 0);
        -webkit-mask: var(--mask);
        mask: var(--mask);
      }

      .title-container {
        .accordion-toggler {
          transition: all 0.25s;
          -webkit-transition: all 0.25s;
        }

        .rotate {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
    }

    .team-card:nth-child(3n-2) {
      margin-top: 70px;
    }

    .team-card:nth-child(3n-1) {
      margin-top: 35px;
    }

    .team-name {
      color: #eeedff;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px; /* 125% */
    }

    .team-info {
      width: 315px;
      border-radius: 0px 0px 10px 10px;
      border: 1px solid #3a2562;
      background: #250d50;
      box-shadow: 0px 32px 64px rgba(20, 20, 43, 0.08);
      // padding-top: 40px;

      p {
        font-size: 12px;
        color: #fff !important;
      }
    }
  }
}

.our-team::before {
  content: "";
  // background-image: url('../assets/teamBackground.svg');
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
}

@media screen and (max-width: 786px) {
  .our-team {
    .team-container {
      grid-template-columns: auto;

      .team-card {
        width: 350px;
      }
    }
  }
}

@media screen and (max-width: 1399px) {
  .our-team {
    .team-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width: 920px) {
  .our-team {
    .team-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 600px) {
  .profile-pic {
    width: 300px !important;
  }

  .title-container {
    width: 300px !important;
  }

  .our-team {
    .team-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

// @media screen and (max-width: 970px) {

//     .team-card{
//         width: 290px !important
//     }

// }

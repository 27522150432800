.stats-wrapper {
  background-image: url("https://cdn.worldofdypians.com/wod/statsBg.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-bottom: 3px solid black;
  @media screen and (max-width: 786px) {
  background-image: url("https://cdn.worldofdypians.com/wod/statsBgMobile.png");
    
  }
}

.explorer-grid-title {
  color: #fff;
  font-size: clamp(1rem, 2vw + 1vh, 3rem);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-shadow: 0 0 1px transparent, 0 1px 2px rgba(0, 0, 0, 0.8);
}
.explore-tag {
  background: none !important;
  color: rgba(140, 86, 255, 1) !important;
}
.explorer-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  .explorer-card-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 48px;
    color: #ffffff;
  }

  .explorer-card-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
}

@media screen and (max-width: 786px) {
  .explorer-grid {
    grid-template-columns: auto;
  }
  .explorer-grid-title {
    font-size: 26px !important;
  }
}

.new-stats-wrapper {
  border-radius: 20px;
border: 1px solid #DCFB85;
background: rgba(220, 251, 133, 0.04);
backdrop-filter: blur(5.5px);

  .new-stats-value {
    color: #dcfb85;
    text-align: center;
    font-family: "Montserrat";
    font-size: clamp(0.8rem, 1.9vw + 0.8vh, 2.7rem);
    font-style: normal;
    font-weight: 900;
    line-height: 42px;
    @media screen and (max-width: 500px) {
      font-size: 2rem;
    }
  }

  .new-stats-type {
    color: #eeedff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.dappRadar-full{
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30%;
}
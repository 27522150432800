.active-proposals-wrapper {
  background: #14153f;
  border-bottom: 3px solid black;
}

.active-proposals-inner-wrapper {
  border-radius: 10px;
  background: rgba(0, 183, 204, 0.2);
  border: 2px solid transparent;
}

.active-proposals-inner-wrapper:hover {
  border: 2px solid #7bd8b0;
}

.active-proposals-features {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.my-votes-amount {
  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.my-votes-desc {
  color: #c0c9ff;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gov-gray-text {
  color: #a5a5a5;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gov-white-text {
  color: #eeedff;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.poll-tag {
  border-radius: 12px;
  background: linear-gradient(90deg, #7b2eb0 0%, #2647c9 100%);
}

.poll-text {
  color: #eeedff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  // line-height: normal;
}

.past-proposals-wrapper {
  background: #0e0f35;
  border-bottom: 3px solid black;
}

.rejected-tag {
  border-radius: 12px;
  border: 1px solid #ff8168;
}

.rejected-text {
  color: #ff8168;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  // line-height: normal;
}

.aor-tag {
  border-radius: 12px;
  background: linear-gradient(90deg, #ff8168 0%, #ea6278 100%);
}

.aor-text {
  color: #eeedff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  // line-height: normal;
}

.past-proposals-item {
  border-radius: 10px;
  background: #121342;
  cursor: pointer;
  border: 1px solid transparent;
}

.past-proposals-item:hover {
  border: 1px solid #b8b8e0;
}

.option-tag {
  border-radius: 12px;
  border: 1px solid #7bd8b0;
}

.option-text {
  color: #7bd8b0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  // line-height: normal;
}

.gov-navlink {
  color: #eeedff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.proposal-top-wrapper {
  background: #0e0f35;
}

.single-proposal-top-title {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.single-proposal-description-green {
  color: #dcfb85;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.single-proposal-content-txt {
  color: #eeedff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.proposal-right-col {
  border-left: 1px solid rgba(255, 255, 255, 0.24);

  @media screen and (max-width: 786px) {
    border-left: none;
  }
}

.proposal-result-wrapper {
  border-radius: 20px;
  border: 1px solid #484865;
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(5.5px);
}

.single-proposal-option-txt {
  color: #eeedff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.percentage-text {
  color: #eeedff;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.prog1 {
  background: transparent;
  border-radius: 12px;
  border: 1px solid #7bd8b0;
  height: 8px;

  .progress-bar {
    border-radius: 12px;
    background: #7bd8b0;
  }
}

.prog2 {
  background: transparent;
  border-radius: 12px;
  border: 1px solid #00b7cc;
  height: 8px;

  .progress-bar {
    border-radius: 12px;
    background: #00b7cc;
  }
}

.orange-btn {
  border-radius: 10px;
  background: linear-gradient(90deg, #008ebf 0%, #00b7cc 100%);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border: none;
}

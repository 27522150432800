// .cats-and-watches-society-benefits{

// }
    .common-title-text{
        mark {
            color: #7770E0;
        }
        p {
            font-size: 16px;
        }
    }
    .graphics{
        max-height: 600px;
        object-fit: contain;
    }
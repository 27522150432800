.notification-bar {
  border-radius: 20px;
  background: rgba(37, 39, 67, 0.45);
  box-shadow: 0px 4.300000190734863px 53px 0px rgba(0, 7, 72, 0.12);
  // backdrop-filter: blur(13.380000114440918px);
}
.notification-bar-item {
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.25s;
}

.notification-item-text {
  color: #eeedff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media only screen and (max-width: 600px) {
    font-size: 13px;
  }
}

.notification-bar-item:hover {
  background: #252743;
}

.notification-bar-item-active {
  border-radius: 8px;
  background: #252743;
  .notification-item-text {
    color: #00fecf;
  }
}

.notifications-list {
  height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    height: auto;
  }
}

.outer-notification-list {
  border-radius: 40px;
  background: rgba(37, 39, 67, 0.15);
  box-shadow: 0px 4.300000190734863px 53px 0px rgba(0, 7, 72, 0.12);
  // backdrop-filter: blur(13.380000114440918px);
}

.list-notification {
  position: relative;
  border-bottom: 2px solid #565891;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  transition: all 0.25s;
}

.notification-delete{
  background: rgba(222, 86, 52, 0.06);
  position: absolute;
  transform: translateX(100%);
  height: 100%;
  transition: all .25s;
  top: 0;
  bottom: 0;
  right: 0;
}

.notification-date-wrapper{
  width: auto;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}


.view-more-notif{
  color: #00fecf;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
}

.view-more-notif:hover{
  text-decoration: underline;

}

.delete-notif{
  color: #DE5634;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.delete-notif:hover{
  text-decoration: underline;
}

.list-notification:hover {
  background: rgba(54, 194, 207, 0.06);

  // .notification-date-wrapper{
  //   display: none !important;
  // }

  .notification-delete{
    transform: translateX(0%);
  }
}

.header-notification:hover{
  .notification-delete{
    transform: translateX(0%);
  }
}

.notification-title {
  color: #eeedff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.notification-desc {
  color: #eeedff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.notifwrapper {
  width: 80%;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}
.notif-delete-text {
  color: #de5634;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.notification-hour {
  color: #eeedff;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notification-date {
  color: #00fecf;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

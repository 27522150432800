.mainhero-wrapper {
  min-height: 70vh !important;
  background-image: url("https://cdn.worldofdypians.com/wod/tokenomicsBg2.webp") !important;
  background-size: 100% 100%;

  @media only screen and (max-width: 991px) {
    min-height: auto !important;
  }
}

.buy-item-logo {
  width: 47px;
}

.main-hero-title {
  color: #fff;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.buy-item-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #ffffff;
}

.buy-items-all-wrapper {
  backdrop-filter: blur(12.5px);
  background: linear-gradient(148deg, #0000001a, #0000000d);
}

.buy-token-wrapper {
  border: 0.1rem solid rgb(149 143 135);
  min-height: 87px;
}

.buy-token-wrapper:hover {
  border: 0.1rem solid #fff;
  transition: all 0.25s ease;
  // filter: drop-shadow(0 0 6px #fff);
}

.stake-wod-btn {
  border-radius: 12px;
  border: 1px solid #f3bf09;
  color: #f3bf09 !important;
  font-family: Open Sans, Arial, Helvetica, sans-serif !important;
  text-align: center;
  font-size: 17.28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  transition: all 0.25s;
  padding: 12px 30px !important;
  width: fit-content;
  text-transform: uppercase;
  @media screen and (max-width: 786px) {
    font-size: 13px;
  }
}

.stake-wod-btn:hover {
  background: #f3bf09;
  color: #49291c !important;
}

.stake-wod-btn-inactive,
.stake-wod-btn-inactive:hover {
  border-radius: 10px;
  background: #cfc6dd;
  border: none;
  color: #464555;
  text-align: center;
  font-family: Open Sans, Arial, Helvetica, sans-serif !important;
  font-size: 17.28px;
  padding: 12px 30px !important;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  // line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 13px;
  }
}

.auto-button {
  @media screen and (max-width: 786px) {
  width: fit-content;
  }
}

.buy-wod-btn {
  border-radius: 10px;
  background: linear-gradient(270deg, #7b2eb0, #2647c9, #2647c9, #7b2eb0);
  background-size: 300%;
  background-position: 0 0;
  border: none;
  color: #eeedff !important;
  text-align: center;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  // line-height: normal;
transition: all .25s;

}

.buy-wod-btn:hover {
  background-position: 100% 0 !important;
}

.buy-wod-bg {
  background: linear-gradient(180deg, #140836 100.09%, #2f025b 181.99%);
  display: flex;
  justify-content: center;
}

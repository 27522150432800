.buy-wod-card{
    border-radius: 6px;
    background: rgba(113, 127, 255, 0.10);
    border: 1px solid transparent;
    transition: all .25s;
}

.buy-wod-card:hover{
    border: 1px solid #1E5ED7;
}

.buy-wod-title{
    color: #EEEDFF !important;
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
}
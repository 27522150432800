.caws-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 100px;
}

.marquee {
  gap: 45px;
}

// .slick-slide {
//   margin: 0 24px;
//   width: 90vw !important;
// }

// /* the parent */
// .slick-list {
//   margin: 0 -24px;
// }
.slick-slider {
  width: 100%;
}

// .slick-list {
//   margin: 0 -10px;
// }
// .slick-slide > div {
//   padding: 0 10px;
// }

.slick-list {
  margin: 0 -5px;
}
.slick-slide > div {
  padding: 0 5px;
}

.button__bar {
  display: flex !important;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  // background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  width: 70px;
  text-align: center;
  margin-top: 20px;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: #9664ff;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 12px;
  width: 10px;
  opacity: 0.3;
  border-radius: 7.5px;
}

.button__bar li.slick-active button {
  width: 16px;
  height: 16px;
  opacity: 1;
  background: #9664ff;
  box-shadow: 0px 4px 4px rgba(150, 100, 255, 0.24);
}

.marketplace-title {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 43px;
  text-align: center;
  width: 50%;
}

.marketplace-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  width: 50%;
}

@media screen and (max-width: 786px) {
  .caws-grid {
    grid-template-columns: auto;
    gap: 30px;
  }

  .slick-list {
    margin: 0px;
  }

  .marketplace-title {
    width: 100% !important;
    font-size: 24px;
  }

  .marketplace-desc {
    width: 100% !important;
  }
}

.video-gallery-grid{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 20px;
}

.video-gallery-item{
  transition: all .25s;
  border: 1px solid #b8b8e0;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 350px;
  padding-bottom: 5px;
}

.gallery-video-thumb{
  border-radius: 12px;
  width: 100%;
  transform: scale(1.05);
  transition: all .25s;

}

.gallery-play-icon-wrapper{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all .25s;

}


.video-gallery-item:hover{
  .gallery-video-thumb{
    transform: scale(1);

  }
  .gallery-play-icon-wrapper{
    background: rgba(0,0,0, 0.5);
  }
}

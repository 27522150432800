.land-hero-wrapper {
  background-image: url("https://cdn.worldofdypians.com/wod/landHeroBg.webp");
  background-repeat: no-repeat;
  background-size: 100% 130%;

  @media screen and (max-width: 786px) {
    background-size: cover;
  }
}

.land-hero-title {
  font-weight: 800;
  font-size: 32px;
  line-height: 43px;
  color: #ffffff;
}
.land-hero-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.benefits-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 2px solid #8c56ff;
  backdrop-filter: blur(5.5px);
  border-radius: 12px;
  gap: 40px;

  @media screen and (max-width: 786px) {
    grid-template-columns: auto;
  }

  .benefits-title {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
  }
  .benefits-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #c0cbf7;
  }
}

.staking-height {
  height: 480px;
  @media screen and (max-width: 991px) {
    height: auto;
    min-height: fit-content;
  }
  @media screen and (max-width: 786px) {
    height: auto;
    min-height: fit-content;
  }
}

.staking-height-2 {
  height: 480px;
  @media screen and (max-width: 786px) {
    height: auto !important;
  }
}

.totalcreated {
  background: #7bd8af;
  position: absolute;
  border-radius: 50%;
  padding: 4px 13px;
  top: -17px;
  left: 10px;
  z-index: 1;

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #080b2a;
  }
}
.connect-wallet-title {
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.mint-span-success {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #09fbd3;
  text-align: center;
}

.addr-text {
  color: rgb(123, 216, 176);
  font-size: 14px;
  font-weight: 400;
}

.borderText {
  border: 1px solid #aaa5eb;
  padding: 3px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
  min-height: 50px;
}

.borderText2 {
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mint-span {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #d87b7b;
  text-align: center;
}

.connect-wallet-wrapper {
  background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  border-radius: 8px;
  .connect-wallet-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  .wallet-btn {
    border: 1px solid #7bd8b0;
    border-radius: 8px;
    padding: 10px;
    .wallet-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #7bd8b0;
    }
  }
}

.errormsg {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #d87b7b;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 50%;
  justify-content: end;
  @media screen and (max-width: 786px) {
    width: 100%;
    justify-content: start;
  }
}
.pricetext {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 5px;
}

.price-separator {
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
}
.pricetag {
  right: 20px;
  top: 20px;
  z-index: 1;

  @media screen and (max-width: 991px) {
    right: 30px;
    bottom: 105px;
    top: auto;
  }
}

.totalprice {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.mintprice {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.mint-wrapper {
  background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  border-radius: 8px;
  position: relative;
  .land-stake-title {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    position: absolute;
    white-space: pre;
    left: 0;
    top: -40px;
    @media screen and (max-width: 786px) {
      top: -70px;
      white-space: break-spaces;
    }
  }
  .create-land-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .more-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }

  .land-placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  .land-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .mint-divider {
    width: 100%;
    border-top: 1px solid #4f4770 !important;
    opacity: 1 !important;
  }
}

.mint-wrappernew {
  background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  border-radius: 8px;
  position: relative;



  .land-stake-title {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    position: absolute;
    white-space: pre;
    left: 0;
    top: -40px;
    @media screen and (max-width: 991px) {
      top: -85px;
      white-space: break-spaces;
    }
  }

  .land-stake-titlenew {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    position: relative;
    // white-space: pre;
    @media screen and (max-width: 786px) {
      font-size: 18px;
    }
  }

  .land-lock-timenew {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    position: relative;
  }

  .cawsimg {
    height: 140px;
    object-fit: contain;
    width: fit-content;
  }

  .marktext {
    color: #09fbd3;
    font-weight: bold;
    background-color: transparent;
  }

  .whitelist-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
  }
  .minting-price {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: #09fbd3;
  }

  .minthero {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    object-fit: cover;
  }
  .create-land-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .more-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }

  .land-placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  .land-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .land-name2-active {
    border-radius: 8px;
    background: linear-gradient(109deg, #181636 5.26%, #fe53bb 312.84%);
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    outline: none;
    padding: 10px;
  }

  .land-name2-passive {
    border-radius: 8px;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
  }

  .mint-divider {
    width: 100%;
    border-top: 1px solid #4f4770 !important;
    opacity: 1 !important;
  }
}

.market-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/timepiecehero1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.market-mint-timepiece {
  background-image: url("https://cdn.worldofdypians.com/wod/timepieceMintHero.webp");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px !important;

  @media screen and (max-width: 786px) {
    height: auto !important;
  }
}

.market-mint-skale {
  background-image: url("https://cdn.worldofdypians.com/wod/skaleMintHero.webp");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  // @media screen and (max-width: 786px) {
  //   background-image: none;
  //   background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  // }
}

.market-mint-bnb {
  background-image: url("https://cdn.worldofdypians.com/wod/bnbMintBanner5.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  // @media screen and (max-width: 786px) {
  //   background-image: none;
  //   background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  // }
}

.market-mint-viction {
  background-image: url("https://cdn.worldofdypians.com/wod/victionMarketMint.webp");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  // @media screen and (max-width: 786px) {
  //   background-image: none;
  //   background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  // }
}
.market-mint-core {
  background-image: url("https://cdn.worldofdypians.com/wod/coreMarketMint.webp");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  // @media screen and (max-width: 786px) {
  //   background-image: none;
  //   background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  // }
}

.market-mint-sei {
  background-image: url("https://cdn.worldofdypians.com/wod/seiMintHero.webp");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px !important;

  @media screen and (max-width: 786px) {
    height: auto !important;
  }
}

.market-mint-opbnb {
  background-image: url("https://cdn.worldofdypians.com/wod/opbnbMarketMint.webp");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  // @media screen and (max-width: 786px) {
  //   background-image: none;
  //   background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  // }
}

.market-mint-immutable {
  background-image: url("https://cdn.worldofdypians.com/wod/immutableMarketBanner.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  // @media screen and (max-width: 786px) {
  //   background-image: none;
  //   background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  // }
}

.market-mint-taiko {
  background-image: url("https://cdn.worldofdypians.com/wod/taikoMarketMint.webp");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  // @media screen and (max-width: 786px) {
  //   background-image: none;
  //   background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  // }
}

.market-stake-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/cawsStakeBanner.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px !important;

  @media screen and (max-width: 786px) {
    height: auto !important;
  }
}

.market-land-stake-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/landStakeBanner.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px !important;

  @media screen and (max-width: 786px) {
    height: auto !important;
  }
}
.conflux-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/confluxBackground.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.conflux-mint-bg2 {
  background-image: url("https://cdn.worldofdypians.com/wod/confluxBackgroundLarge.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}
.avax-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/avaxBackground.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}
.kucoin-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/kucoinBackground.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}
.gate-mint-bg2 {
  background-image: url("https://cdn.worldofdypians.com/wod/gateMintBg.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.base-mint-bg2 {
  background-image: url("https://cdn.worldofdypians.com/wod/baseMintBg.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}
.doge-mint-bg2 {
  background-image: url("https://cdn.worldofdypians.com/wod/dogeBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.cmc-mint-bg2 {
  background-image: url("https://cdn.worldofdypians.com/wod/cmcBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.coin98-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/coin98Background1.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

 
.doge-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/dogeBackground.webp");
  background-size: 90%;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.coingecko-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/coingeckoBackground.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.base-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/baseBackground.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}



 

.gate-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/gateBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.skale2-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/skaleBackground2.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.core-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/coreBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.sei-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/seiBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.matchain-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/matchainBg.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.bnb-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/bnbBackground2.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.opbnb-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/opBnbBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.manta-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/mantaBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.taiko-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod//taikoBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.cookie3-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/cookieBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.viction-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/victionBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.immutable-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/immutableBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

.multivers-mint-bg {
  background-image: url("https://cdn.worldofdypians.com/wod/multiversBackground.webp");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media screen and (max-width: 786px) {
    background-image: none;
    // background: linear-gradient(137deg, #181636 8.24%, #7133E5 100%);
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
  }
}

// .mint-wrappernew::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   margin: auto;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: top;

//   @media screen and (max-width: 991px) {
//     background-image: none;
//   }
// }

// .mint-wrappernew::after{
//   content: "";
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   position: absolute;
//   left: 0;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   margin: auto;
//   @media screen and (max-width: 991px) {
//     background-image: none;
//   }
// }

.land-input {
  background: #272450;
  border: 1px solid #aaa5eb;
  border-radius: 8px;
  padding: 15px;
  color: #ffffff;
}

.land-input::placeholder {
  color: #ffffff;
}

.limit-span {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  position: absolute;
  left: 0;
  bottom: -48px;
}

.eth-price {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.land-apr {
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  white-space: pre;
}

.land-lock-time {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  // width: 75%;
  padding-bottom: 5px;
  @media screen and (max-width: 1500px) {
    width: 100%;
  }
}

.earned-span {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #8c56ff;
}

.eth-rewards {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.genesis-desc {
  width: 100%;
  background: linear-gradient(180deg, #5b08d0 -17.02%, #7f2df7 100%);
  border-radius: 6px;
  --mask: linear-gradient(45deg, #0000 30px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding: 30px;
  // position: absolute;
  // top: 80%;
}

.genesis-desc-game {
  width: 180px;
  background: linear-gradient(180deg, #5b08d0 -17.02%, #7f2df7 100%);
  border-radius: 6px;
  --mask: linear-gradient(45deg, #0000 20px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding: 15px;
  // position: absolute;
  // top: 80%;
}

.nomask {
  --mask: none !important;
  mask: none !important;
  -webkit-mask: none !important;
}

.land-desc {
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #f7f7fc;

  @media screen and (max-width: 1300px) {
    display: flex;
    white-space: nowrap;
  }
  @media screen and (max-width: 1080px) {
    display: flex;
    white-space: normal;
  }

  @media screen and (max-width: 434px) {
    display: flex;
    white-space: nowrap;
    font-size: 12px;
  }
}

.land-desc-game {
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #f7f7fc;

  // @media screen and (max-width: 1300px) {
  //   display: flex;
  //   white-space: nowrap;
  // }
  // @media screen and (max-width: 1080px) {
  //   display: flex;
  //   white-space: normal;
  // }
}

.genesis-badge {
  // position: absolute;
  // left: 0;
  // right: 0;
  // margin: auto;
  // top: 30px;
  width: 100%;
}

.tiers-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 40px;
  // margin-bottom: 25rem;
  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
    // place-items: center;
  }
  @media screen and (max-width: 786px) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 1rem;
  }
}

.genesis-wrapper {
  --mask: linear-gradient(-150deg, #0000 36.57px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  background: linear-gradient(
    141.14deg,
    #5e17cc 11.06%,
    #381486 34.42%,
    #1f164c 56.62%
  );
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.32);
  border-radius: 6px;
  height: 330px;
}

.genesis-wrapper:nth-child(even) {
  position: relative;
  // top: 80px;
}

.genesis-wrapper-game {
  --mask: linear-gradient(-150deg, #0000 25px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  background: linear-gradient(
    141.14deg,
    #5e17cc 11.06%,
    #381486 34.42%,
    #1f164c 56.62%
  );
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.32);
  border-radius: 6px;
  width: 180px;
  height: 200px;
}

.genesis-wrapper-game:nth-child(even) {
  position: relative;
  // top: 80px;
}

.land-tiers {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  white-space: pre;

  @media screen and (max-width: 786px) {
    white-space: normal;
  }
}

.tiers-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  width: 50%;
  @media screen and (max-width: 786px) {
    width: 100%;
  }
}

.benefit-card {
  background: linear-gradient(300.5deg, #ba7bd8 2.36%, #85fbc9 82.19%);
  border-radius: 6px;
  --mask: linear-gradient(45deg, #0000 30px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding: 30px;
  width: 350px;
  .benefit-title {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-align: right;
    color: #080b2a;
  }
}

.glass-card {
  background: rgba(255, 255, 255, 0.26);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 25%;
  height: 200px;
  position: absolute;
  bottom: 250px;
  .benefits-building {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -150px;
  }
}

.mint-time {
  font-family: "Organetto" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 54px;
  color: #ffffff;

  @media screen and (max-width: 786px) {
    font-size: 30px;
  }
}

.mint-time3 {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;

  @media screen and (max-width: 786px) {
    font-size: 30px;
  }
}

.days {
  font-family: "Organetto";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  @media screen and (max-width: 786px) {
    font-size: 10px;
  }
}
.days3 {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  @media screen and (max-width: 786px) {
    font-size: 10px;
  }
}

.community-title {
  font-weight: 800;
  font-size: 32px;
  line-height: 43px;
  color: #ffffff;
  white-space: pre;
  @media screen and (max-width: 786px) {
    white-space: normal;
  }
}

.community-card {
  background: #181636;
  border-radius: 8px;
  height: 500px;

  .community-title {
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
  }

  .community-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff !important;
    max-height: 90px;
    text-decoration: none !important;
    p {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #ffffff !important;
      margin-bottom: 0 !important;
    }
    span {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #ffffff !important;
      margin-bottom: 0 !important;
    }

    h6 {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #ffffff !important;
      margin-bottom: 0 !important;
    }
  }

  .community-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #f7f7f7;
    text-decoration: none !important;
  }
}

.tier-specs {
  background: rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  .spec-value {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }
  .spec-type {
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.7);
  }
}
.land-benefits-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.tier-benefit {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.genesis-land {
  background-image: url("https://cdn.worldofdypians.com/wod/cawsActive.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.coingecko-active {
  background-image: url("https://cdn.worldofdypians.com/wod/coingeckoActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.conflux-active {
  background-image: url("https://cdn.worldofdypians.com/wod/confluxActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.gate-active {
  background-image: url("https://cdn.worldofdypians.com/wod/gateActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.doge-active {
  background-image: url("https://cdn.worldofdypians.com/wod/dogeActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.cmc-active {
  background-image: url("https://cdn.worldofdypians.com/wod/cmcActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.white-explore {
  width: 20px;
  height: 20px;
  @media only screen and (max-width: 550px) {
    width: auto;
    height: auto;
  }
}

.base-active {
  background-image: url("https://cdn.worldofdypians.com/wod/baseActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.skale-active {
  background-image: url("https://cdn.worldofdypians.com/wod/skaleActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.core-active {
  background-image: url("https://cdn.worldofdypians.com/wod/coreActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}
.manta-active {
  background-image: url("https://cdn.worldofdypians.com/wod/mantaNft.png");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}
.taiko-active {
  background-image: url("https://cdn.worldofdypians.com/wod/taikoNft.png");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.cookie3-active {
  background-image: url("https://cdn.worldofdypians.com/wod/cookie3Nft.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.sei-active {
  background-image: url("https://cdn.worldofdypians.com/wod/seiActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.mat-active {
  background-image: url("https://cdn.worldofdypians.com/wod/matchainActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.multivers-active {
  background-image: url("https://cdn.worldofdypians.com/wod/multiversActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}
.bnb-active {
  background-image: url("https://cdn.worldofdypians.com/wod/bnbActive2.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.opbnb-active {
  background-image: url("https://cdn.worldofdypians.com/wod/opBnbActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.viction-active {
  background-image: url("https://cdn.worldofdypians.com/wod/victionActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.immutable-active {
  background-image: url("https://cdn.worldofdypians.com/wod/immutableActive.webp");
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.genesis-land-empty {
  background-image: url("https://cdn.worldofdypians.com/wod/cawsplaceholder.svg"),
    linear-gradient(141.14deg, #5e17cc 11.06%, #381486 34.42%, #1f164c 56.62%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.conflux-empty {
  background-image: url("https://cdn.worldofdypians.com/wod/confluxPlaceholder.svg"),
    linear-gradient(141.14deg, #5e17cc 11.06%, #381486 34.42%, #1f164c 56.62%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.coingeckoempty-wrapper {
  --mask: linear-gradient(-150deg, #0000 25.57px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  background-size: contain;

  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.32);
  border-radius: 6px;
}

.community-image {
  --mask: linear-gradient(-135deg, #0000 30px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}

.twitter-tag {
  position: absolute;
  left: 0;
  top: 0;
}

.mint-release-date {
  font-weight: 800;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
}

.mint-release-title {
  width: 75%;

  @media screen and (max-width: 786px) {
    width: 100%;
  }

  span {
    font-weight: 800;
    font-size: 24px;
    line-height: 40px;
    color: #181637;
    background-color: #09fbd3;
    @media screen and (max-width: 786px) {
      font-size: 20px;
    }
  }
}

.mint-types {
  background: rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  width: 75%;
  flex-wrap: wrap;
  gap: 12px;
  @media screen and (max-width: 1500px) {
    width: 100%;
  }
  .type-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }
  .type-desc {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #09fbd3;
  }

  .dimensions-span {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.75);
  }
}

.mint-benefits-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;

  @media screen and (max-width: 991px) {
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 400px) {
    gap: 15px;
    grid-template-columns: repeat(1, 1fr);
  }

  .mint-benefits-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
  }
}

.members-title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  color: #ffffff;
}

.glass-bubble {
  background: rgba(255, 255, 255, 0.03);
  box-shadow: inset 0px 5.48547px 5.48547px rgba(255, 255, 255, 0.16),
    inset 0px 0px 43.8838px rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(18.5135px);
  border-radius: 50%;
  width: fit-content;
}

.first-glass {
  width: 235px;
  height: 235px;
  @media screen and (max-width: 1171px) {
    min-width: 200px !important;
    min-height: 200px !important;
    max-width: 200px !important;
    max-height: 200px !important;
  }
}

.second-glass {
  width: 173px;
  height: 173px;
}
.third-glass {
  width: 235px;
  height: 235px;
  @media screen and (max-width: 1171px) {
    min-width: 200px !important;
    min-height: 200px !important;
    max-width: 200px !important;
    max-height: 200px !important;
  }
}
.fourth-glass {
  width: 173px;
  height: 173px;
}

.first-bubble {
  position: absolute;
  left: 15%;
  top: 27%;
  z-index: 2;

  @media screen and (max-width: 1171px) {
    top: 38%;
    left: 6%;
  }

  .members-playing {
    position: absolute;
    left: -1%;
    top: -3%;
    z-index: 3;

    @media screen and (max-width: 1171px) {
      left: 0%;
      top: -15%;
      z-index: 3;
      scale: 0.8;
    }
  }
}
.second-bubble {
  position: absolute;
  left: 22%;
  top: 62%;
  z-index: 3;
  @media screen and (max-width: 1171px) {
    left: 10%;
    top: 76%;
  }
}
.third-bubble {
  position: absolute;
  left: 70%;
  top: 27%;
  @media screen and (max-width: 1171px) {
    left: 71%;
    top: 39%;
  }

  .discord-members {
    position: absolute;
    left: 7%;
    top: -20%;
    z-index: 3;
    @media screen and (max-width: 1171px) {
      left: 7%;
      top: -20%;
      z-index: 3;
      scale: 0.8;
    }
  }
}
.fourth-bubble {
  position: absolute;
  left: 67%;
  top: 62%;

  @media screen and (max-width: 1171px) {
    left: 68%;
    top: 76%;
  }

  .twitter-members {
    position: absolute;
    left: -1%;
    top: -20%;
    z-index: 3;

    @media screen and (max-width: 1171px) {
      left: -1%;
      top: -20%;
    }
  }
}

.pink-title {
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 37.3867px rgba(241, 28, 220, 0.52),
    0px 0px 15.7418px rgba(241, 28, 220, 0.48);
  filter: blur(0.491931px);
}

.pink-content {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 37.3867px rgba(241, 28, 220, 0.52),
    0px 0px 15.7418px rgba(241, 28, 220, 0.48);
  filter: blur(0.491931px);
}
.blue-title {
  font-weight: 800;
  font-size: 34px;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 37.3867px rgba(88, 101, 242, 0.52),
    0px 0px 15.7418px rgba(88, 101, 242, 0.48);
  filter: blur(0.491931px);
}

.blue-content {
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 37.3867px rgba(88, 101, 242, 0.52),
    0px 0px 15.7418px rgba(88, 101, 242, 0.48);
  filter: blur(0.491931px);
}

.play-button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  pointer-events: none;
  //   padding: 15px;
  //   box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.25), inset 0px 5.48547px 5.48547px rgba(255, 255, 255, 0.16), inset 0px 0px 43.8838px rgba(255, 255, 255, 0.08);
  // backdrop-filter: blur(18.5135px);
  // background: rgba(255, 255, 255, 0.03);
}

.slider-buttons-wrapper {
  position: absolute;
  bottom: -10%;
  right: 2.5%;
  width: fit-content;
  @media screen and (max-width: 786px) {
    right: 3.5%;
    bottom: -10%;
  }
}

.prev-button {
  transform: rotate(180deg);
  z-index: 2;
  margin: auto;
  width: fit-content;
  cursor: pointer;
}
.next-button {
  z-index: 2;
  margin: auto;
  width: fit-content;
  cursor: pointer;
}

.new-benefits-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  place-items: center;

  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 786px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.new-benefits-grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  place-items: center;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 786px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.new-benefits-header {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #8c56ff;
}

.premium-custom-text {
  height: 1px;
  position: relative;
  top: -10px;
  @media screen and (max-width: 576px) {
    display: none;
  }
}

.new-benefit-card {
  background-image: url("https://cdn.worldofdypians.com/wod/benefitBg.svg");
  background-size: 350px 150px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px;
  min-width: 350px;
  max-width: 350px;

  @media screen and (max-width: 1600px) {
    background-size: 300px 150px;
    min-width: 300px;
    max-width: 300px;
  }
  @media screen and (max-width: 1500px) {
    background-size: 350px 150px;
    min-width: 350px;
    max-width: 350px;
  }
  .new-benefit-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }
  .new-benefit-desc {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
}

.land-benefits-wrapper {
  background-image: url("https://cdn.worldofdypians.com/wod/landBenefitsBg.svg");
  background-repeat: no-repeat;
  background-size: 75% 75%;
  background-position: center;
}

.landtitle-wrapper {
  white-space: initial;
  display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 1441px) {
  .benefit-card {
    width: 285px !important;
  }

  .glass-card {
    bottom: 300px !important;
  }
}

@media screen and (max-width: 786px) {
  .genesis-wrapper:nth-child(4),
  .genesis-wrapper-game:nth-child(4) {
    padding-bottom: 100px !important;
  }

  .laptop {
    width: 50% !important;
  }

  .land-main-wrapper .first-bubble {
    top: 26%;
    left: -14%;
    scale: 0.6;
  }

  .land-main-wrapper .second-bubble {
    left: -6%;
    top: 45%;
    scale: 0.7;
  }

  .land-main-wrapper .third-bubble {
    left: 56%;
    top: 26%;
    scale: 0.6;
  }

  .land-main-wrapper .fourth-bubble {
    left: 58%;
    top: 45%;
    scale: 0.7;
  }
}

@media screen and (max-width: 598px) {
  .land-main-wrapper .first-bubble {
    top: 44%;
    left: -22%;
    scale: 0.5;
  }

  .land-main-wrapper .second-bubble {
    left: -19%;
    top: 64%;
    scale: 0.6;
  }

  .land-main-wrapper .third-bubble {
    left: 46%;
    top: 42%;
    scale: 0.5;
  }

  .land-main-wrapper .fourth-bubble {
    left: 51%;
    top: 63%;
    scale: 0.6;
  }
  .land-main-wrapper .members-title {
    font-size: 17px;
  }
}

.timepiece-graphic {
  max-height: 300px;
  object-fit: contain;
  margin: auto;
  scale: 1.5;
}

@media screen and (max-width: 991px) {
  .timepiecewhite-gif {
    max-height: 250px !important;
  }

  .timepiece-graphic {
    margin: auto;
    scale: 1.3;
    margin-top: 3rem;
  }
}

@media screen and (max-width: 488px) {
  .land-bg {
    width: 100%;
  }

  .laptop {
    width: 75% !important;
  }
}

@media screen and (max-width: 1827px) {
  .discount-break {
    display: none;
  }
}

.discountprice {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: #fe6a6a;
}

.totalprice2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #09fbd3;
}

.notetxt {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #b8b8b8;
}

.market-mint-title {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.08px;
  text-transform: capitalize;
}

.market-mint-desc {
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.48px;
}

.uppertext {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bottomtext {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.daily-rewards-popup {
  background: rgba(8, 11, 42, 0.8) !important;
}

.mint-popup {
  border-radius: 8px;
  background: linear-gradient(127deg, #181636 35.2%, #7133e5 191.04%);

  width: 30%;
  position: fixed;
  top: -10000px;
  height: fit-content;
  left: 0%;
  bottom: 0%;
  right: 0%;
  margin: auto;
  z-index: 1000;
  pointer-events: auto !important;
  transition: all 0.25s ease-in-out;

  @media screen and (max-width: 1455px) {
    width: 40% !important;
  }
  @media screen and (max-width: 1300px) {
    width: 50% !important;
  }
  @media screen and (max-width: 786px) {
    width: 90% !important;
  }
}

.mint-popup-title {
  color: #fff;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-wrap: nowrap;

  @media screen and (max-width: 786px) {
    font-size: 15px;
  }
  @media screen and (max-width: 400px) {
    font-size: 13px;
  }
}

.mint-popup-desc {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.288px;
  @media screen and (max-width: 786px) {
    font-size: 12px;
  }
}

.available-mint-bg {
  border-radius: 6px;
  background: linear-gradient(
    270deg,
    rgba(86, 80, 216, 0) 0.07%,
    #5650d8 51.44%,
    rgba(86, 80, 216, 0) 100.72%
  );
  position: relative;
  bottom: 20px;
  .popup-available-mint {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 0 !important;
    @media screen and (max-width: 786px) {
      font-size: 16px;
    }
    @media screen and (max-width: 400px) {
      font-size: 13px;
    }
  }
}

.doge-button {
  border-radius: 20px;
  background: linear-gradient(90deg, #e87483 -13.56%, #6b1d90 129.3%);
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cmc-btn {
  border-radius: 20px;
  background: linear-gradient(90deg, #755fef -13.56%, #c43fb5 129.3%);
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.bottom-auto-bg {
  bottom: auto !important;
}

.mint-popup-img {
  position: relative;
  bottom: 25px;
}

.popup-active {
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.5);
  top: 0% !important;
}

@keyframes pulseDot {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(183, 16, 97, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(183, 16, 97, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(183, 16, 97, 0);
  }
}

.pulsatingDot {
  width: 11px;
  height: 11px;
  margin-right: 8px;
  background-color: #b71061;
  border-radius: 50%;
  animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateY(10px) ranslateX(-10px);
  }
  50% {
    transform: translateY(-10px) translateX(-10px);
  }
  75% {
    transform: translateY(10px) translateX(10px);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
}

.shake-img {
  position: absolute;
  width: 200px;
  height: 200px;
  left: -50px;
  animation: shake 5s ease-in-out infinite;
}

.rewards-title {
  color: #18ffff;
  text-align: right;
  font-size: 30px;
  font-style: normal;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
}

body {
    --accent-orange-red-nft: #fa4833;
    --accent-red-nft: #e30613;
    --accent-red-ed-nft: #ed636c;
    --accent-red-fc-nft: #fc4f36;
    --accent-red-ff-nft: #ff0000;
    --accent-red-f1-nft: #F13227;
    --accent-red-2-nft: #e30713;
    --orange-nft: #ff523c;
    --orange-f9-nft: #f94732;
    --title-dark-nft: #282828;
    --title-dark-00-nft: #001922;
    --title-dark-02-nft: #020202;
    --title-dark-47-nft: #474747;
    --text-dark-nft: #4d4d4d;
    --text-dark-3a-nft: #3a3a3a;
    --text-dark-3f-nft: #3f3f3f;
    --text-gray-a2-nft: #a2a2a2;
    --text-gray-7d-nft: #7d7d7d;
    --text-gray-8b-nft: #8b8b8b;
    --light-gray-eb-nft: #ebebeb;
    --light-gray-a4-nft: #a4a4a4;
    --light-gray-c4-nft: #c4c4c4;
    --light-gray-d8-nft: #d8d8d8;
    --light-gray-f4-nft: #f4f4f4;
    --light-gray-f5-nft: #f5f5f5;
    --light-gray-99-nft: #999999;
    --accent-blue-nft: #219ee1;
    --accent-blue-20-nft: #2081e2;
    --accent-green-nft: #52a8a4;
    --accent-green-63-nft: #63edab;
    --black-nft: #000000;
    --white-nft: #ffffff;
    --white-theme-nft: #ffffff;
    --light-gray-99: #999999;
    --light-purple: #d2d1ff;
    --accent-yellow: #f3ba2f;
    --accent-chain-red: #e84142;
    --light-gray-ed: #ededed;
    --gray-25: #25262e;
    --light-gray-dc: #dcdcdc;
    --accent-red-fc: #fc4f36;
    --text-gray-e0: #e0e0e0;
    --orange-f9: #f94732;
    --black-theme: #000000;
    --black-26: #26272f;
    --black-theme-opacity: 0, 0, 0;
    --black-09: #090a13;
    --light-gray-f8: #f8f4f6;
    --text-gray-e0: #e0e0e0;
    --accent-purple: #4966d6;
    --text-dark-33: #333333;
    --red-ff: #ffbdaa;
    --accent-red-e5: #e50d15;
    --light-gray-f8-bg: #f8f4f6;
    --grey-73: #737373;
    --light-gray-d9: #d9d9d9;
    --light-gray-f6: #f6f6f6;
    --dark-gray-3a: #3a3b42;
    --accent-red-f8: #f84531;
    --black-theme-nft: #000000;
    --black-26-nft: #26272F;
    --grey-b2-nft: #b2b2b2;
}

body.dark {
    --accent-orange-red-nft: #fa4833;
    --accent-red-nft: #e30613;
    --accent-red-ed-nft: #ed636c;
    --accent-red-fc-nft: #fc4f36;
    --accent-red-ff-nft: #ff0000;
    --accent-red-f1-nft: #F13227;
    --accent-red-2-nft: #e30713;
    --orange-nft: #ff523c;
    --orange-f9-nft: #f94732;
    --title-dark-nft: #fff;
    --title-dark-00-nft: #fff;
    --text-dark-3a-nft: #fff;
    --title-dark-02-nft: #fff;
    --title-dark-02-nft: #020202;
    --title-dark-47-nft: #474747;
    --text-dark-nft: #C2C2C2;
    --text-dark-3f-nft: #3f3f3f;
    --text-gray-a2-nft: #a2a2a2;
    --text-gray-7d-nft: #7d7d7d;
    --text-gray-8b-nft: #8b8b8b;
    --light-gray-eb-nft: #ebebeb;
    --light-gray-a4-nft: #a4a4a4;
    --light-gray-c4-nft: #c4c4c4;
    --light-gray-d8-nft: #d8d8d8;
    --light-gray-f4-nft: #f4f4f4;
    --light-gray-f5-nft: #f5f5f5;
    --light-gray-99-nft: #999999;
    --accent-blue-nft: #219ee1;
    --accent-blue-20-nft: #2081e2;
    --accent-green-nft: #52a8a4;
    --accent-green-63-nft: #63edab;
    --black-nft: #000000;
    --white-nft: #ffffff;
    --white-theme-nft: #151519;
    --light-gray-99: #999999;
    --light-purple: #d2d1ff;
    --accent-yellow: #f3ba2f;
    --accent-chain-red: #e84142;
    --light-gray-ed: #504f56;
    --gray-25: #25262e;
    --light-gray-dc: #3e3d44;
    --accent-red-fc: #fc4f36;
    --text-gray-e0: #e0e0e0;
    --orange-f9: #f94732;
    --black-theme: #ffffff;
    --black-26: #26272f;
    --black-theme-opacity: 255, 255, 255;
    --gray-39: #393a41;
    --light-gray-f8: #26272f;
    --black-09: #090a13;
    --text-gray-b2: #b2b2b2;
    --accent-purple: #4966d6;
    --text-dark-33: #fff;
    --red-ff: #ffffff;
    --accent-red-e5: #ff7360;
    --light-gray-f8-bg: #090a13;
    --grey-73: #737373;
    --light-gray-d9: #d9d9d9;
    --light-gray-f6: #f6f6f6;
    --dark-gray-3a: #3a3b42;
    --accent-red-f8: #f84531;
    --black-theme-nft: #FFFFFF;
    --black-26-nft: #26272F;
    --grey-b2-nft: #b2b2b2;
}

$accent-orange-red: var(--accent-orange-red-nft);
$accent-red: var(--accent-red-nft);
$accent-red-ed: var(--accent-red-ed-nft);
$accent-red-fc: var(--accent-red-fc-nft);
$accent-red-ff: var(--accent-red-ff-nft);
$accent-red-f1: var(--accent-red-f1-nft);
$accent-red-2: var(--accent-red-2-nft);
$orange: var(--orange-nft);
$orange-f9: var(--orange-f9-nft);
$title-dark: var(--title-dark-nft);
$text-dark-3a: var(--text-dark-3a-nft);
$title-dark-00: var(--title-dark-00-nft);
$title-dark-02: var(--title-dark-02-nft);
$title-dark-47: var(--title-dark-47-nft);
$text-dark: var(--text-dark-nft);
$text-dark-3f: var(--text-dark-3f-nft);
$text-gray-a2: var(--text-gray-a2-nft);
$text-gray-7d: var(--text-gray-7d-nft);
$text-gray-8b: var(--text-gray-8b-nft);
$light-gray-eb: var(--light-gray-eb-nft);
$light-gray-a4: var(--light-gray-a4-nft);
$light-gray-c4: var(--light-gray-c4-nft);
$light-gray-d8: var(--light-gray-d8-nft);
$light-gray-f4: var(--light-gray-f4-nft);
$light-gray-f5: var(--light-gray-f5-nft);
$light-gray-99: var(--light-gray-99-nft);
$accent-blue: var(--accent-blue-nft);
$accent-blue-20: var(--accent-blue-20-nft);
$accent-green: var(--accent-green-nft);
$accent-green-63: var(--accent-green-63-nft);
$black: var(--black-nft);
$white: var(--white-nft);
$white-theme: var(--white-theme-nft);
$text-dark-3a:#3a3a3a;
$light-gray-99: var(--light-gray-99);
$light-purple: var(--light-purple);
$accent-yellow: var(--accent-yellow);
$accent-chain-red: var(--accent-chain-red);
$light-gray-ed: var(--light-gray-ed);
$gray-25: var(--gray-25);
$light-gray-dc: var(--light-gray-dc);
$accent-red-fc: var(--accent-red-fc);
$text-gray-e0: var(--text-gray-e0);
$orange-f9: var(--orange-f9);
$black-theme: var(--black-theme);
$black-26: var(--black-26);
$black-theme-opacity: var(--black-theme-opacity);
$gray-39: var(--gray-39);
$light-gray-f8: var(--light-gray-f8);
$black-09: var(--black-09);
$text-gray-b2: var(--text-gray-b2);
$accent-purple: var(--accent-purple);
$text-dark-33: var(--text-dark-33);
$red-ff: var(--red-ff);
$accent-red-e5: var(--accent-red-e5);
$light-gray-f8-bg: var(--light-gray-f8-bg);
$grey-73: var(--grey-73);
$light-gray-d9: var(--light-gray-d9);
$light-gray-f6: var(--light-gray-f6);
$dark-gray-3a: var(--dark-gray-3a);
$accent-red-f8: var(--accent-red-f8);
$black-theme: var(--black-theme-nft);
$black-26: var(--black-26-nft);
$grey-b2: var(--grey-b2-nft);

body.dark{
    $accent-orange-red: #fa4833;
    $accent-red: #e30613;
    $accent-red-2: #e30713;
    $orange: #ff523c;
    $title-dark: #282828;
    $title-dark-02: #020202;
    $title-dark-47: #474747;
    $text-dark: #4d4d4d;
    $text-dark-3f: #3f3f3f;
    $text-gray-a2: #a2a2a2;
    $text-gray-8b: #8b8b8b;
    $light-gray-eb: #ebebeb;
    $light-gray-a4: #a4a4a4;
    $light-gray-c4: #c4c4c4;
    $light-gray-d8: #d8d8d8;
    $light-gray-f5: #f5f5f5;
    $accent-blue: #219ee1;
    $accent-green: #52a8a4;
    $black: #000000;
    $white: #ffffff;
    $text-dark-3a: red;
    $black-theme-opacity: var(--black-theme-opacity);
    $black-09: var(--black-09);
}
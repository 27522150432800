@import "../src/screens/Caws/caws";
@import "../src/screens/Caws/colors";
@import "../src/screens/Caws/nftWhiteList";
@import "../src/screens/Caws/responsive";
@import "../src/screens/Account/src/Components/BundleCard/bundle";
@import "../src/screens/Account/src/Components/ProfileCard/profilecard";

// @import "../src/screens/Account/src/Containers/Dashboard/Components/TopSection/topsection";
@import '../src/screens/Caws/NftMinting/General/NftCawCard/nftCawCard';
@import '../src/screens/Caws/NftMinting/General/NftStakingCawCard/nftStakeCawCard';
@import  '../src/screens/Home/CawsSociety/cawssociety';

@import '../src/components/LandPopup/landpopup';


@font-face {
  font-family: "Organetto";
  src: url("./fonts/Organetto.ttf");
}

@font-face {
  font-family: "Iceland";
  src: url("./fonts/Iceland.ttf");
}

@font-face {
  font-family: "Oxanium";
  src: url("./fonts/Oxanium.ttf");
}
.custom-container {
  max-width: 1600px;
  width: 100%;
  @media screen and (max-width: 1600px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    }
  // @media screen and (max-width: 1600px) {
  //   width: fit-content !important;
  // }
}

.font-oxanium {
  font-family: "Oxanium" !important;
}
.font-montserrat {
  font-family: "Montserrat" !important;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.main-wrapper2 {
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

 
.lifetime-plan-text {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
}

.lifetime-plan-text2 {
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
}

.discount-price {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.discount-price-profile {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  // line-height: 32px;
}

.old-price-text {
  color: #F3BF09;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-decoration-line: line-through;
}

.old-price-text-profile {
  color: #F3BF09;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  // line-height: 32px;
  text-decoration-line: line-through;
}

.premiumRedTag {
  top: -11px;
  left: calc(50% - 55px);
}

.premiumRedTag-profile {
  top: -11px;
  left: calc(50% - 45px);
}

.discount-price2 {
  color: #f7f7ff;
  text-align: center;
  // font-family: Oxanium;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
}

.discount-price2-profile {
  color: #f7f7ff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
}

.discountwrap {
  top: 4px;
  left: calc(50% - 18px);
}

.premiumtag-img {
  height: 30px;
}

.discountwrap-profile {
  top: 5px;
  left: calc(50% - 18px);
}

.discount-price-bottom {
  color: #fff;
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

#root {
  height: 100vh;
  width: 100vw;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #26264f;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8e97cd;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
a {
  text-decoration: none;
}

body {
  background: #080b2a;
  font-family: "Montserrat", sans-serif !important;

  .font-poppins {
    font-family: "Poppins", sans-serif !important;
  }

  .font-organetto {
    font-family: "Organetto" !important;
  }

  .font-iceland {
    font-family: "Iceland" !important;
  }

  .font-raleway {
    font-family: "Raleway", sans-serif !important;
  }

  .linear-border {
    position: relative;
    padding: 3px;
    background: linear-gradient(89.7deg, #7bd8b0 0.23%, #dcfb85 99.72%);
    border-radius: 8px;
    clip-path: polygon(
      0 20px,
      20px 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
    height: fit-content;
  }

  .linear-border::before {
    content: "";
  }

  .linear-border-roadmap {
    position: relative;
    padding: 3px;
    background: linear-gradient(90deg, #b936f5 0.23%, #c64c85 99.72%);
    border-radius: 8px;
    clip-path: polygon(
      0 20px,
      20px 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
    height: fit-content;
  }

  .linear-border-roadmap::before {
    content: "";
  }

  .linear-border-download {
    position: relative;
    padding: 3px;
    background: linear-gradient(90deg, #8eee86 -13.56%, #0078d4 129.3%);
    border-radius: 8px;
    clip-path: polygon(
      0 20px,
      20px 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
    height: fit-content;
  }

  .linear-border-download::before {
    content: "";
  }

  .timepiece-border {
    position: relative;
    padding: 3px;
    background: linear-gradient(89.7deg, #bd185d 0.23%, #ba18bd 99.72%);
    border-radius: 8px;
    clip-path: polygon(
      0 20px,
      20px 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
    height: fit-content;
  }

  .linear-border-purp {
    position: relative;
    padding: 3px;
    pointer-events: none;
    user-select: none;
    background: linear-gradient(89.7deg, #7e39fd 0.23%, #2f1369 99.72%);
    border-radius: 8px;
    clip-path: polygon(
      0 20px,
      20px 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
  }

  .linear-border-purp::before {
    content: "";
  }

  .walletbutton {
    background: var(--box-bg);
    outline: currentcolor none medium;
    border: 1px solid rgb(237, 238, 242);
    border-radius: 12px;
    width: 100% !important;
    display: flex;
    flex-direction: row;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: justify;
    padding: 10px;
    opacity: 1;
  }

  .walletbutton:hover {
    border: 1px solid rgb(140, 86, 255);
  }

  .walletmodal-wrapper {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background: linear-gradient(111.64deg, #080b2a 0%, #8c56ff 100.5%);
  }

  .walletclose {
    display: flex;
    justify-content: end;
    padding: 18px;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
  .linear-border-disabled {
    position: relative;
    padding: 2px;
    background: #c4c4c4;
    border-radius: 8px;
    clip-path: polygon(
      0 20px,
      20px 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
  }

  .linear-border-disabled::before {
    content: "";
  }

  .linear-border-transparent::before {
    content: "";
  }

  .linear-border-transparent {
    background: transparent;
    border: transparent;
  }

  .outline-btn-transparent {
    clip-path: polygon(
      0 19px,
      19px 0,
      100% 0,
      100% calc(100% - 19px),
      calc(100% - 19px) 100%,
      0 100%
    );
    background: transparent;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    white-space: pre;
    text-decoration: underline;
  }

  .outline-btn {
   
    background: #080b2a;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12pt;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    white-space: pre;
  }

  .outline-btn:hover {
  
    background: linear-gradient(89.7deg, #7b84d8 0.23%, #a4fb85 99.72%);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000;
    white-space: pre;
  }

  .outline-btn-roadmap {
    clip-path: polygon(
      0 19px,
      19px 0,
      100% 0,
      100% calc(100% - 19px),
      calc(100% - 19px) 100%,
      0 100%
    );
    background: #080b2a;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12pt;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    white-space: pre;
  }

  .outline-btn-roadmap:hover {
    clip-path: polygon(
      0 19px,
      19px 0,
      100% 0,
      100% calc(100% - 19px),
      calc(100% - 19px) 100%,
      0 100%
    );
    background: linear-gradient(90deg, #b936f5 0.23%, #c64c85 99.72%);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000;
    white-space: pre;
  }

  .multiplayer-btn {
    border-radius: 12px;
    background: linear-gradient(270deg, #7b2eb0, #2647c9, #2647c9, #7b2eb0);
    background-size: 300%;
    background-position: 0 0;
    filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.25));
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12pt;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    white-space: pre;
    transition: all 0.25s;
  }

  .multiplayer-btn:hover {
    // background: linear-gradient(to left, #7b2eb0 0.23%, #142d8e 99.72%);
    background-position: 100% 0 !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    white-space: pre;
  }
  .stake-wod-btn2 {
    border-radius: 12px;
    background: linear-gradient(270deg, #0EBE9E, #0588B8, #0588B8, #0EBE9E);
    background-size: 300%;
    background-position: 0 0;
    filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.25));
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12pt;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    white-space: pre;
    transition: all 0.25s;
  }

  .stake-wod-btn2:hover {
    // background: linear-gradient(to left, #7b2eb0 0.23%, #142d8e 99.72%);
    background-position: 100% 0 !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    white-space: pre;
  }

  .outline-btn-disabled {
    border-radius: 12px;
    background: #c4c4c4;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #080b2a;
    white-space: pre;
    border: none;
    outline: none;
    pointer-events: none;
    opacity: 0.4;
  }

  .simple-btn {
    background: transparent;
    color: #ffffff;
    outline: none;
    border: none;
    text-decoration: underline;
  }

  .filled-btn-download {
    clip-path: polygon(
      0 19px,
      19px 0,
      100% 0,
      100% calc(100% - 19px),
      calc(100% - 19px) 100%,
      0 100%
    );
    background: linear-gradient(90deg, #8eee86 -13.56%, #0078d4 129.3%);
    color: #333333;
    font-size: 12pt;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none !important;
    white-space: pre;
    transition: all 0.25s;
  }

  .filled-btn-download:hover {
    color: #fff;
    // text-decoration: underline;
  }

  .filled-btn {
    border-radius: 12px;
    background: linear-gradient(270deg, #7bd8b0, #dcfb85, #dcfb85, #7bd8b0);
    background-size: 300%;
    background-position: 0 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #080b2a !important;
    border: none !important;
    white-space: pre;
    transition: all 0.25s;
  }

  .filled-btn:hover {
    background-position: 100% 0 !important;
  }

  .filled-btn-roadmap {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    border: none !important;
    white-space: pre;
    transition: all 0.25s;
  }

  .timepiece-btn {
    clip-path: polygon(
      0 19px,
      19px 0,
      100% 0,
      100% calc(100% - 19px),
      calc(100% - 19px) 100%,
      0 100%
    );
    background: linear-gradient(89.7deg, #bd185d 0.23%, #ba18bd 99.72%);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    border: none !important;
    white-space: pre;
    transition: all 0.25s;
  }

  .download-linear-border {
    position: relative;
    padding: 2px;
    background: transparent;
    border-radius: 8px;
    clip-path: polygon(
      0 20px,
      20px 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
  }

  .download-filled-btn {
    clip-path: polygon(
      0 19px,
      19px 0,
      100% 0,
      100% calc(100% - 19px),
      calc(100% - 19px) 100%,
      0 100%
    );
    // background: linear-gradient(
    //   148deg,
    //   rgba(0, 0, 0, 0.1) 0%,
    //   rgba(0, 0, 0, 0.5) 100%
    // );
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #080b2a;
    border: none !important;
    white-space: pre;
  }

  .filled-error-btn {
    clip-path: polygon(
      0 19px,
      19px 0,
      100% 0,
      100% calc(100% - 19px),
      calc(100% - 19px) 100%,
      0 100%
    );
    background: linear-gradient(89.7deg, #d87b7b 0.23%, #fb85d3 99.72%);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #080b2a;
    border: none !important;
    white-space: pre;
    transition: all 0.25s;
  }



  .pink-filled-btn {
    clip-path: polygon(
      0 19px,
      19px 0,
      100% 0,
      100% calc(100% - 19px),
      calc(100% - 19px) 100%,
      0 100%
    );
    background: linear-gradient(89.7deg, #5690ff 0.23%, #8c56ff 99.72%);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #080b2a;
    border: none !important;
    white-space: pre;
  }

  .pink-linear-border {
    position: relative;
    padding: 2px;
    background: linear-gradient(89.7deg, #5690ff 0.23%, #8c56ff 99.72%);
    border-radius: 8px;
    clip-path: polygon(
      0 20px,
      20px 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
  }

  .pink-linear-border::before {
    content: "";
  }

  .multiplayer-linear-border {
    position: relative;
    padding: 2px;
    background: linear-gradient(90deg, #7b2eb0 0.23%, #2647c9 99.72%);
    border-radius: 8px;
    clip-path: polygon(
      0 20px,
      20px 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
  }

  .multiplayer-linear-border::before {
    content: "";
  }
}

.join-beta-ribbon {
  border-radius: 6px;
  border: 1px solid #7bd8b0;
  background: rgba(8, 11, 42, 0.6);
  backdrop-filter: blur(12.5px);
  position: relative;
  z-index: 5;

  @media only screen and (max-width: 991px) {
    width: fit-content !important;
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.join-beta-ribbon:hover {
  background: rgba(123, 216, 176, 0.3);
}

.joinbeta-white-txt {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.purple-btn {
  background: linear-gradient(266.71deg, #a420e2 8.87%, #1829bd 91.56%);
  clip-path: polygon(
    0 20px,
    20px 0,
    100% 0,
    100% calc(100% - 20px),
    calc(100% - 20px) 100%,
    0 100%
  );
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  border: none !important;
  white-space: pre;
}

.purple-btn2 {
  background: linear-gradient(90deg, #ca00a0 1.45%, #4525c4 96.22%);
  clip-path: polygon(
    0 20px,
    20px 0,
    100% 0,
    100% calc(100% - 20px),
    calc(100% - 20px) 100%,
    0 100%
  );
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  border: none !important;
  white-space: pre;
}

.linear-border-purple::before {
  content: "";
}

.outline-btn-green,
.outline-btn-green:hover,
.outline-btn-green:focus {
  background: #14142a;
  border-radius: 8px;
  border: 1px solid #4ed5d2;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4ed5d2;
  width: 145px !important;
}

.linear-border-purple {
  background: linear-gradient(266.71deg, #a420e2 8.87%, #1829bd 91.56%);
  border: transparent;
  position: relative;
  padding: 3px;
  border-radius: 8px;
  clip-path: polygon(
    0 20px,
    20px 0,
    100% 0,
    100% calc(100% - 20px),
    calc(100% - 20px) 100%,
    0 100%
  );

  @media only screen and (max-width: 500px) {
    align-self: center;
  }
}

.linear-border-purple2 {
  background: linear-gradient(90deg, #ca00a0 1.45%, #4525c4 96.22%);
  border: transparent;
  position: relative;
  padding: 3px;
  border-radius: 8px;
  clip-path: polygon(
    0 20px,
    20px 0,
    100% 0,
    100% calc(100% - 20px),
    calc(100% - 20px) 100%,
    0 100%
  );

  @media only screen and (max-width: 500px) {
    align-self: center;
  }
}

.hidescroll {
  overflow: hidden !important;
  pointer-events: none !important;
}
.elevated-container {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 12px 16px 44px rgba(0, 0, 0, 0.12);
  padding: 24px 16px;
  @include medium {
    padding: 32px;
  }
}

.modal .modal-dialog .modal-content {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  background:#1a1c39;
  border: 0;
}

.MuiBox-root {
  border: 0.5px solid #525469;
  background: #0E0F35;
}

.close-x {
  width: 50px;
  position: relative;
  bottom: 65px;
  cursor: pointer;
  height: fit-content;
}

.sub-navbar-wrapper {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.scroll-to-top {
  position: fixed;
  bottom: 5%;
  right: 3%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 999;

  @media screen and (max-width: 786px) {
    bottom: 9%;
  }
}

.MuiTooltip-tooltip {
  // background: #3B217B !important;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.16) !important;
  border-radius: 6px !important;
  margin-bottom: auto !important;
  font-size: 12px !important;
  min-width: 350px !important;
  max-width: 450px !important;
}

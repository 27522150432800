.economy-banner{
    background-image: url('https://cdn.worldofdypians.com/wod/igEconomyBg.webp');
    background-size: 100%;
     background-repeat: no-repeat;
     border-bottom: 3px solid black;
    @media screen and (max-width: 786px) {
        background-image: url('https://cdn.worldofdypians.com/wod/igEconomyBgMobile.webp');
    }
}

.fancybox__content{
    width: 100vw !important;
    height: 100vh !important;
}
.leaderboard-wrapper {
  font-family: "Poppins", sans-serif !important;
}

.leaderboardTitle {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  @media screen and (max-width: 786px) {
    font-size: 15px;
  }
}


.leaderboardTitleGlobal {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  @media screen and (max-width: 786px) {
    font-size: 15px;
  line-height: normal;

  }
}


.leaderboard-active-btn {
  border-radius: 8px;
  border: 1px solid #386fc8;
  background: linear-gradient(to bottom right, #386fc8 0%, #032c6c 50%) bottom
      right / 50% 50% no-repeat,
    linear-gradient(to bottom left, #386fc8 0%, #032c6c 50%) bottom left / 50%
      50% no-repeat,
    linear-gradient(to top left, #386fc8 0%, #032c6c 50%) top left / 50% 50%
      no-repeat,
    linear-gradient(to top right, #386fc8 0%, #032c6c 50%) top right / 50% 50%
      no-repeat;
  color: #f7f7ff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // transition: all 0.25s;
  @media screen and (max-width: 786px) {
    font-size: 12px;
  }
}

.table-btns-wrapper {
  grid-template-columns: repeat(2, 1fr);
}

.leaderboard-inactive-btn {
  border-radius: 8px;
  border: 1px solid #757086;
  color: #757086;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  @media screen and (max-width: 786px) {
    font-size: 12px;
  }
  
}

.global-leaderboard-wrapper {
  @media screen and (max-width: 786px) {
    height: 400px;
    overflow: auto;
    padding-right: 10px;
  }
}


.leaderboard-inactive-btn2 {
  border-radius: 8px;
  border: 1px solid #757086;
  color: #757086;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  padding: 10px;
  display: flex;
  justify-content: center;
}


.leaderboard-title {
  font-size: 20px;
  font-weight: 800;
}

.playerName-inner {
  display: flex;
  align-items: center;
  white-space: nowrap;

  span {
    text-overflow: ellipsis;
    width: 90px;
    overflow: hidden;
  }
}

.grandPrices-wrapper {
  background-color: #242240;
  border-radius: 8px;
  padding: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 180px;
}

.goldenscore {
  background: linear-gradient(180deg, #f3980f 0.44%, #fe5a51 100%) !important;
  color: #fff !important;
  text-align: center;
  position: relative;
}

.verifiable-txt {
  font-size: 10px;
}

.goldenscore-inactive {
  // background: rgb(88 86 82 / 80%) !important;
  color: #48465e !important;
  text-align: center;
  position: relative;
}

.grand-label {
  font-weight: 600;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: -0.018em;

  color: #f7f7ff;
}

.grandprizeTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
}

.prizeitem {
  display: flex;
  gap: 10px;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #f7f7fc;
}

.winnersamount {
  font-weight: 700;
  font-size: 40px;
  line-height: 42px;
  letter-spacing: -0.018em;
  color: #f7f7fc;
  text-shadow: 0px 6px 4px #5e06a2;
}

.grand-prize {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(-7deg);
  height: fit-content;
}

.playerTable {
  border-radius: 8px;
  color: white;
}

.playerData {
  padding: 0 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.018em;
  color: #ffffff;
}

.playerHeader {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
  position: sticky;
  top: 0;
  // background: #080b2a;
  z-index: 2;
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    font-size: 9px;
  }
}

.optionText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #747687;
  display: flex;
  justify-content: center;
  cursor: pointer;
  // width: 80px;

  @media screen and (max-width: 786px) {
    font-size: 10px !important;
    white-space: nowrap;
  }
}

.optionText:hover, .leaderboard-inactive-btn2:hover,
.activeoption {
  color: #fff;
}

.new-leaderboard-desc-title {
  color: #F4E07B;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.new-leaderboard-desc {
  color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
}

.optionTextGolden {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
  display: flex;
  justify-content: center;
}

.activeoptiongolden {
  color: #f7f7ff;
  font-size: 14px;
}

.viewWinners {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
}

.previous-desc {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
}

.optionsWrapper {
  background: #242240;
  border-radius: 8px;
  padding: 2px;
}

.playerRow {
  position: static;
  z-index: 1;
}

.playerInnerRow {
  background: #242240;

  border-bottom: 1px solid #757086;
}

.playerInnerRow-inactive {
  background: rgba(0, 254, 207, 0.1) !important;

  .goldenscore-inactive2 {
    color: #fff;
  }

  .inactivegold {
    color: #fff !important;
    text-align: center;
    position: relative;
  }
}

.golden-score-disabled {
  color: #8f8e99 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.252px;
}

.optionsWrapper2 {
  background: linear-gradient(
    180deg,
    rgba(33, 36, 64, 0.15) 99.99%,
    rgba(255, 255, 255, 0.15) 100%
  );
  backdrop-filter: blur(13px);
  border-radius: 10px;
  border: 1px solid #4c4e64;
}

.playerAvatar {
  width: 28px;
  height: 30px;
  @media screen and (max-width: 500px) {
    width: 25px;
    height: 25px;
  }
}
.premium-star {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
}

.playerReward {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #fff;
  height: 32px;
  @media screen and (max-width: 786px) {
    font-size: 12px !important;
  }
}
.playerScore {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: rgba(243, 192, 9, 1);
  height: 32px;
  @media screen and (max-width: 786px) {
    font-size: 12px;
  }
}
.goldenscore-inactive2 {
  color: #fff;
}
.golden-score-disabled {
  color: #8f8e99 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.252px;
}

.playerName {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
  @media screen and (max-width: 786px) {
    font-size: 12px;
    overflow-x: hidden;
    width: 100%;
    max-width: 100px;
  }
}

.tablewrapper {
  height: 366px;
  // overflow-y: scroll;

  // @media screen and (max-width: 1600px) {
  //   height: 250px !important;
  // }
}

.optiongenesis {
  // background: linear-gradient(90.31deg, #F1B14B 0.27%, #DC5738 99.74%);
  border-radius: 0px;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #09f3d2 !important;
  color: #09f3d2;
}

.otheroptionsActive {
  // background: linear-gradient(90.31deg, #7344B3 0.27%, #20BBCA 99.74%);
  // font-weight: bold;
  padding: 10px;
  // border-radius: 8px;
  // border-bottom: 1px solid #09f3d2 !important;
  color: #fff;
}

.nft-hover {
  position: absolute;
  left: -50%;
  top: 3.5%;
  width: 45%;
  @media screen and (max-width: 991px) {
    width: 100%;
    position: static;
  }
}

.statusText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.018em;
  color: #fe7a00;
}

.nft-badges-wrapper {
  position: relative;
  top: -24px;
}

.opensea-badge {
  transition: all 0.25s ease-in-out;
  width: 90px;

  @media screen and (max-width: 786px) {
    width: 100px;
  }
}

.opensea-badge:hover {
  transform: scale(1.1);
}

.tooltip-wrapper {
  background: #3b217b;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  position: absolute;
  top: 100%;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s;
}

.tooltip-wrapper2 {
  background: #43455d;
  box-shadow: 0px 4.3px 53px rgba(0, 7, 72, 0.12);
  border-radius: 10px;
  position: absolute;
  top: 100%;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s;
}

.tooltip-content2 {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #b8b8e0;
}

.tooltip-active {
  opacity: 1;
  visibility: visible;
}

.tooltip-content {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #ffffff;
  font-family: "Poppins";
}

.nft-hover-wrapper {
  background: linear-gradient(180deg, #582378 0%, #272343 100%);
  border-radius: 8px;
  padding: 0px 12px 0px 12px;
  width: 100%;
}

.nft-hover-title {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
}

.nft-hover-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.comingsoon {
  filter: blur(3px);
}
.watermark {
  color: #fff;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  opacity: 0.5;
  transform: rotate(-28deg);
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 353px;

  // @media only screen and (max-width: 767px) {
  //   left: 28%;
  // }

  @media only screen and (max-width: 500px) {
    width: 253px;
    font-size: 20px;
  }
}

.leaderboard-title-wrapper {
  border-radius: 8px 8px 0px 0px;
  border: 1.5px solid #f4e27b;
  background: linear-gradient(to bottom right, #582570 0%, #351755 50%) bottom
      right / 50% 50% no-repeat,
    linear-gradient(to bottom left, #582570 0%, #351755 50%) bottom left / 50%
      50% no-repeat,
    linear-gradient(to top left, #582570 0%, #351755 50%) top left / 50% 50%
      no-repeat,
    linear-gradient(to top right, #582570 0%, #351755 50%) top right / 50% 50%
      no-repeat;
}

.premium-goldenscore {
  color: #f4e27b !important;
}

.global-leaderboard-banner {
  border-radius: 16px 16px 0 0;
  // border: 1px solid #F4E27B;
  background-image: url('https://cdn.worldofdypians.com/wod/globalHeaderBg.png');
  background-size: 100% 100%;
  // background: linear-gradient(90deg, rgba(19, 13, 49, 0.50) 0%, rgba(52, 21, 83, 0.50) 100%);
  // backdrop-filter: blur(10.5px);
}

.greatcollection-leaderboard-banner {
  border-radius: 16px 16px 0 0;
  // border: 1px solid #F4E27B;
  background-image: url('https://cdn.worldofdypians.com/wod/greatCollectionBg.webp');
  background-size: 100% 100%;
  height: 128px;
  // background: linear-gradient(90deg, rgba(19, 13, 49, 0.50) 0%, rgba(52, 21, 83, 0.50) 100%);
  // backdrop-filter: blur(10.5px);
}

.global-leaderboard-title {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
}

.global-total-wrapper {
  border-radius: 12px;
  border: 1px solid rgba(88, 37, 112, 1);
}

.global-total-players {
  color: #f4e27b;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Oxanium" !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.global-total-span {
  color: #fff;
  text-align: center;
  font-family: "Oxanium" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.new-leaderboard-green-btn {
  border-radius: 8px;
background: linear-gradient(90deg, #7BD8B0 0%, #DCFB85 100%);
color: #333;
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
border: none;
}

.view-chains-wrapper {
  border-radius: 10px;
  border-bottom: 1px solid rgba(76, 78, 100, 0.5);
  background: linear-gradient(
    180deg,
    rgba(53, 34, 89, 0.4) 99.99%,
    rgba(52, 35, 87, 0.4) 100%
  );
  backdrop-filter: blur(13px);
  span {
    color: #f7f7ff;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.216px;
  }
}

.monthly-players-percent {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -40%;
  margin: auto;
  color: #7bd8b0;
  text-align: center;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: fit-content;
}

.wod-builders-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  width: 100%;

  @media screen and (max-width: 786px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);

    .builders-title {
      font-size: 18px !important;
    }
  }
}
.new-wod-builders-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  width: 100%;

  @media screen and (max-width: 786px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);

    .builders-title {
      font-size: 18px !important;
    }
  }
}

.builder-item {
  border-radius: 8px;
  background: linear-gradient(148deg, rgba(170, 20, 240, 0.10) 0%, rgba(170, 20, 240, 0.05) 100%);
  backdrop-filter: blur(10px);
  transition: all .3s ease-in-out;
}

.builder-item:hover{
  .partner-banner{
    transform: scale(1.1);
  }
}

.builder-title {
  color: #f7f7f7;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}

.new-builder-title{
  color: #EEEDFF;
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 171.429% */
white-space: nowrap;
@media screen and (max-width: 786px) {
  font-size: 14px;
}
}

@media screen and (max-width: 450px) {
  .builders-title {
    font-size: 24px !important;
    line-height: 24px !important;
  }
}

.builders-first-half {
  width: fit-content;
  @media screen and (max-width: 786px) {
    width: 100%;
  }
}
.builders-second-half {
  // width: 80%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 10px 16px;
  overflow-x: auto;
  @media screen and (max-width: 786px) {
    height: 170px;
    // grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}
.new-builders-second-half {
  // width: 80%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px 16px;
  overflow-x: auto;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(14, 1fr);
    gap: 10px;
    width: 100%;
  }
}

.build-business-wrapper {
  background-image: url("https://cdn.worldofdypians.com/wod/wodpartnersBg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  scroll-margin-top: 100px;
  @media screen and (max-width: 786px) {
  background-image: url("https://cdn.worldofdypians.com/wod/wodpartnersBgMobile.png");
    
  }
}

.build-business-title-wrapper {
  // width: 50%;
  h5 {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
  }

  h6 {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
  }
  @media screen and (max-width: 786px) {
    width: 100%;
    h6 {
      // font-size: 20px;
      text-align: center;
    }
  }
}

 

.builders-bottom-wrapper {
  position: absolute;
  bottom: -74px;
  backdrop-filter: blur(5.5px);
  background: linear-gradient(262.84deg, #614497, #1b0941);
  justify-content: center;

  @media screen and (max-width: 786px) {
    bottom: -106px;
    }
}

.partner-banner-holder{
  border-radius: 18px 0 18px 0;
}
.partner-banner{
  border-radius: 18px 0 18px 0;
  transition: all .3s;
}

.builders-bg{
  background-image: url('https://cdn.worldofdypians.com/wod/buildersBg.webp');
  padding-top: 20px;
  padding-bottom: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  // margin-top: -40px;
border-bottom: 3px solid black;
}

.builders-bg-large{
  background-image: url('https://cdn.worldofdypians.com/wod/buildersBgLarge.webp') !important;
  @media screen and (max-width: 786px) {
    background-size: auto !important;
  }
}


.partner-logo-container{
  width: 90px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-partners-title{
  color: #EEEDFF;
text-align: center;
font-family: "Montserrat";
text-transform: uppercase;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 171.429% */
}

.view-all-partners{
  color: #DCFB85;
font-family: "Montserrat";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
white-space: nowrap;
}

.new-partners-grid{
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 10px;
  overflow-x: auto;
  // height: 280px;
  // @media screen and (max-width: 1439px) {
  //   grid-template-columns: repeat(10, 1fr);
  //   place-items: center;
  // }

  // @media screen and (max-width: 1039px) {
  //   grid-template-columns: repeat(8, 1fr);
  //   place-items: center;
  // }

  // @media screen and (max-width: 991px) {
  //   grid-template-columns: repeat(4, 1fr);
  //   place-items: center;
  // }
  // @media screen and (max-width: 786px) {
  //   grid-template-columns: repeat(2, 1fr);
  //   place-items: center;
  // }
}

.view-more-partners{
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 20px !important;
font-size: 14px !important;
font-weight: 600 !important;
width: fit-content;
cursor: pointer;
transition: all .25s;
}


.view-more-partners:hover{
  filter: drop-shadow(0 1px 2px rgba(220, 251, 133, 0.8));
}

.yellow-arrow{
  transform: rotate(90deg);
}
.caws-benefits-wrapper {
    background: #080B2A;
}

.caws-benefits-wrapper2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // border: 2px solid #8c56ff;
    backdrop-filter: blur(5.5px);
    border-radius: 12px;
    gap: 40px;

    @media screen and (max-width: 786px) {
      grid-template-columns: auto;
    }

    .benefits-title {
      font-weight: 300;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
    }
    .benefits-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #c0cbf7;
    }
  }
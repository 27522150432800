@import '../../../responsive';


.common-title-text {
  h1 {
    color: black;
    font-weight: 900;
    font-size: 32px;
    margin-bottom: 16px;

    @include medium {
        font-size: 54px;
    }
    small {
      font-weight: 200;
    }
    mark {
      background-color: transparent;
      color: red;
      word-break: break-all;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: black;
    margin: 0;
    @include medium {
        font-size: 20px;
        line-height: 32px;
    }
    mark {
      font-weight: bold;
      background-color: transparent;
    }
  }
}

.common-title-main {
  h1 {
    font-size: 38px;
    @include medium {
        font-size: 64px;
    }
  }
}

.center-text {
  h1 {
    text-align: center;
  }
  p {
    text-align: center;
  }
}

@media screen and (max-width: 786px) {
  .common-title-text {
    margin-top: 20px;
  }
}

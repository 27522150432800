.news-card {
  background: #181636;
  border-radius: 8px;
  min-height: 350px;
}

.terms-container{
  max-height: 60vh;
  overflow-y: scroll;
}

.update-title {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 43px;
  margin-top: 4rem;
  margin-bottom: 2rem;
  color: #ffffff;
  width: 65%;
}


.terms-main-wrapper {
    gap: 80px;
    max-width: 2400px;
    overflow-x: hidden;
    background-image: url(https://cdn.worldofdypians.com/wod/newsBackground.webp);
    background-size: cover;
}
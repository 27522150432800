@import '../../../responsive';


.cats-society-ranking {
    color: white;
    padding-left: 0px;
    padding-right: 0px;
    @include medium {
        padding-left: 50px;
        padding-right: 50px;
    }
    h1 {
        font-size: 42px;
        line-height: 53px;
        color: white;
    }
    small {
        font-weight: 200;
    }
    .bolder {
        font-weight: 900;
    }
    p {
        line-height: 160%;
        font-size: 16px;
        color: white;
        &.note {
            font-size: 12px;
            line-height: 18px;
        }
        mark {
            color: white;
        }
    }
    &.background {
        background: url("https://cdn.worldofdypians.com/wod/paws-bg.png") no-repeat center center;
        background-size: cover;
    }
    .first-image {
        width: 100%;
    }
    .second-image {
        width: 100%;
    }
    .third-image {
        width: 100%;
    }
    .fourth-image {
        width: 100%;
    }
    .fifth-image {
        width: 100%;
    }

    @include medium {
        .first-image {
            width: 105%;
            padding: 30px;
        }
        .second-image {
            width: 95%;
            padding: 30px;
        }
        .third-image {
            width: 75%;
            padding: 30px;
        }
        .fourth-image {
            width: 75%;
            padding: 30px;
        }
        .fifth-image {
            width: 65%;
            padding: 30px;
        }
        .fourth-image-container {
            margin-top: -50px;
        }
        .fifth-image-container {
            margin-top: -50px;
        }
    }
    @include large {
        .fourth-image-container {
            margin-top: -100px;
        }
        .fifth-image-container {
            margin-top: -100px;
        }
    }
    @include extra-large {
        .fourth-image-container {
            margin-top: -120px;
        }
        .fifth-image-container {
            margin-top: -120px;
        }
    }
}

.governance-mainhero-wrapper {
  min-height: 65vh !important;
  background-image: url("https://cdn.worldofdypians.com/wod/governanceBg.png") !important;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  border-bottom: none !important;
  border-bottom: 3px solid black;
  @media only screen and (max-width: 991px) {
    min-height: auto !important;
  }

  @media only screen and (max-width: 450px) {
    background-image: linear-gradient(
        180deg,
        #080b2a 0%,
        rgba(8, 11, 42, 0.6) 100%
      ),
      url("https://cdn.worldofdypians.com/wod/bridgeHeroBgMobile.webp") !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.governance-hero-wrapper {
  margin-top: 150px;
  margin-bottom: 50px;
  @media screen and (max-width: 786px) {
    margin-top: 40px;
  }
}

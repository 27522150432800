@mixin extra-small {
    @media (max-width: 576px) {
        @content;
    }
}
@mixin small {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin medium {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin large {
    @media (min-width: 992px) {
        @content;
    }
}
@mixin extra-large {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin wide-screen {
    @media (min-width: 1500px) {
        @content;
    }
}

@mixin extra-large-and-up {
    @media (width: 1440px) {
        @content;
    }
}


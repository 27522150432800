.token-utilities-grid{
display: grid;
grid-template-columns: repeat(3, 1fr);
gap: 40px;



@media screen and (max-width: 786px) {
    grid-template-columns: repeat(1,1fr);
}
}

.token-utility-title{
    color: #EEEDFF;
font-family: "Montserrat";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;

}
.token-utility-desc{
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.token-utility-title-header{
    color: #EEEDFF;
    font-family: "Montserrat";
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
}

.token-utility-desc-header{
    color: #EEEDFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
}

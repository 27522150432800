.new-game-stats-wrapper {
    position: relative;
    width: 100%; /* Ensure the wrapper spans the full width */
    height: clamp(300px, 25vw, 480px); /* Responsive height */
    background-size: cover; /* Maintain aspect ratio of the image, cutting excess parts */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repetition */
    border-bottom: 3px solid black;
    overflow: hidden; /* Ensure no content spills out */
  
    @media screen and (max-width: 1300px) {
      background-position: right; /* Optional: shift the image focus */
    }
  
    @media screen and (max-width: 900px) {
      background-image: none !important;
      background-color: rgba(81, 84, 111, 0.2);
      height: 300px;
    }
  }
  
  .first-banner {
    background-image: url("https://cdn.worldofdypians.com/wod/transactionsBanner.webp");
  }
  

.second-banner{
    background-image: url("https://cdn.worldofdypians.com/wod/darkLordSpaceBanner.webp");

}

.third-banner{
    background-image: url("https://cdn.worldofdypians.com/wod/lizardBanner.webp");
    

}
.fourth-banner{
    background-image: url("https://cdn.worldofdypians.com/wod/cityBanner.webp");

}
.fifth-banner{
    background-image: url("https://cdn.worldofdypians.com/wod/groupBanner.webp");
}
.sixth-banner{
    background-image: url("https://cdn.worldofdypians.com/wod/secondCityBanner.webp");
}
.seventh-banner{
    background-image: url("https://cdn.worldofdypians.com/wod/cawsGameBanner.webp");
}

.eightth-banner{
    background-image: url("https://cdn.worldofdypians.com/wod/exploreGameBanner.webp");
}




.small-transaction-banner{
    width: 100%;
    display: none;
    @media screen and (max-width: 900px) {
      display: flex;
     }
}

.game-stats-grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 30px;
    background: url('https://cdn.worldofdypians.com/wod/gameStatsLines.svg');
    background-size: 100% 100%;
    @media screen and (max-width: 786px) {
        gap: 10px;
    }
}
.game-stats-value{
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: clamp(1rem, 2vw + 1vh, 3rem);
    font-style: normal;
    font-weight: 900;
    line-height: 24px; /* 66.667% */
    // @media screen and (max-width: 1300px) {
    //     font-size: 24px;
    // }
    // @media screen and (max-width: 1200px) {
    //     font-size: 20px;
    // }
 
}
.game-stats-type{
    color: rgba(238, 237, 255, 0.60);
    text-align: center;
    font-family: "Montserrat";
   font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    white-space: nowrap;
    @media screen and (max-width: 786px) {
        font-size: 12px;
    }
}
.leaderboard-wrapper {
  font-family: "Poppins", sans-serif !important;
}

.leaderboardTitle {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  @media screen and (max-width: 786px) {
    font-size: 15px;
    line-height: 12px;
  }
}

// .main-wrapper {
//   @media screen and (max-width: 786px) {
//    min-height: 560px;
//    overflow: auto;
//   }
// }

.grandPrices-wrapper {
  background-color: #242240;
  border-radius: 8px;
  padding: 10px;
 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 180px;
  display: flex;
  justify-content: end;
  flex-direction: column;
}

.grandprizeTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
}

.prizeitem {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #ffffff;
}

.winnersamount {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.018em;
  color: #ff9fc9;
  text-shadow: 0px 4px 4px rgba(36, 34, 64, 0.26);
  background: linear-gradient(242.68deg, #2d106b 11.53%, #1b0c44 103.68%);
  padding: 3px 10px;
}

.winnerwrapper {
  background: linear-gradient(210.54deg, #e36eff -3.77%, #6a21f7 85.1%);
  border-radius: 6px;
  padding: 5px;
}

.playerTable {
  border-radius: 8px;
  color: white;
}

.playerData {
  padding: 0 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.018em;
  color: #ffffff;
}

.playerHeader {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
  position: sticky;
  top: 0;
  // background: #080b2a;
  z-index: 2;
  white-space: nowrap;
  @media screen and (max-width: 500px) {
    font-size: 9px;
  }
}

.optionText {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #747687;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 80px;
}

.optionText:hover,
.activeoption {
  color: #fff;
}

.optionTextGolden {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
  display: flex;
  justify-content: center;
}

.goldenscore {
  background: linear-gradient(0deg, #7344b3 0.27%, #20bbca 99.74%) !important;
  color: #fff !important;
  text-align: center;
  position: relative;
}

// .goldenscorefirst::after {
//   content: '+';
//   position: absolute;
//   left: -5px;
//   color: red !important;

// }

.goldenscore-inactive {
  // background: rgb(88 86 82 / 80%) !important;
  color: #48465e !important;
  text-align: center;
  position: relative;
}

.activeoptiongolden {
  color: #f7f7ff;
  font-size: 14px;
}

.viewWinners {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
}

.previous-desc {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.018em;
  color: #f7f7ff;
}

.optionsWrapper {
  border-radius: 12px;
  background: #1e2237;
  padding: 2px;
}

.playerRow {
  position: static;
  z-index: 1;
}

.playerInnerRow {
  background: none !important;
  border-bottom: 1px solid #757086;
}

.playerInnerRow-inactive {
  background: rgba(0, 254, 207, 0.1) !important;
  .goldenscore-inactive {
    color: #09f3d2 !important;
  }
}

.playerAvatar {
  width: 30px;
  height: 30px;

  @media screen and (max-width: 500px) {
    width: 25px;
    height: 25px;
  }
}
.premium-star {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
}

.playerReward {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #fff;
  height: 32px;
}
.playerScore {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: rgba(243, 192, 9, 1);
  height: 32px;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
}
.goldenscore-inactive2 {
  color: #fff;
  text-align: center;
}

.golden-score-disabled {
  color: #8f8e99 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.252px;
}

.playerName {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.018em;
  color: #f7f7ff;

  @media screen and (max-width: 786px) {
    font-size: 12px;
    overflow-x: hidden;
    width: auto;
    max-width: none;
  }
}

.tablewrapper {
  overflow: auto;
  // overflow-y: auto;
}

.medal-wrapper { 
  background-position: center -62px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  height: 475px;
  border-radius: 20px;
  border: 1px solid #4c4e64;
}

.medalinnerwrapper {
  top: -40px;
  height: 120px;
}

.secondaryplayer-wrapper {
  background: #181b38;
  border-radius: 12px;
  padding: 10px;
  margin-right: -8px;
}

.thirdplayer-wrapper {
  background: #181b38;
  border-radius: 12px;
  padding: 10px;
  margin-left: -8px;
}

.mainplayer-wrapper {
  background: #20223e;
  border-radius: 30px 30px 0px 0px;
  padding: 10px;
  position: relative;
  top: -45px;
  height: 165px;
}

.winnerimg {
  height: 113px;
  bottom: 127px;
}

.playersname {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.winnersreward {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #f3900d;
}

.playerscore {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #b8b8e0;
  height: 32px;
}

.playersimg {
  height: 68px;
  bottom: 85px;
}
.playersreward {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #02f9d0;
}

.placeimg {
  top: 5px;
  left: 0;
  right: 0;
  margin: auto;
}

.optiongenesis {
  // background: linear-gradient(90.31deg, #F1B14B 0.27%, #DC5738 99.74%);
  border-radius: 0px;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #09f3d2 !important;
  color: #09f3d2;
}

.otheroptionsActive {
  // background: linear-gradient(90.31deg, #7344B3 0.27%, #20BBCA 99.74%);
  // font-weight: bold;
  padding: 10px;
  border-radius: 12px;
  white-space: nowrap;
  // border-bottom: 1px solid #09f3d2 !important;
  color: #fff;
}

.comingsoon-new {
  filter: blur(3px);
}
.watermark {
  color: #fff;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  opacity: 0.5;
  transform: rotate(-28deg);
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 353px;

  // @media only screen and (max-width: 767px) {
  //   left: 28%;
  // }

  @media only screen and (max-width: 500px) {
    width: 253px;
    font-size: 20px;
  }
}

.tooltip-wrapper {
  background: #3b217b;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  position: absolute;
  top: 100%;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s;
}

.tooltip-active {
  opacity: 1;
  visibility: visible;
}

.tooltip-content {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #ffffff;
  font-family: "Poppins";
}

.statusText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.018em;
  color: #fe7a00;
}

.leaderboard-item {
  width: 33%;
  background: rgba(151, 89, 192, 0.3);
  border-radius: 12px;

  @media screen and (max-width: 786px) {
    width: 100%;
  }
}

.leaderboard-item2 {
  width: 100%;
  background: rgba(69, 74, 149, 0.2);
  backdrop-filter: blur(12.5px);
  border-radius: 12px;

  @media screen and (max-width: 786px) {
    width: 100%;
  }
}

.leaderboard-inner-title2 {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.new-leaderboard-btns-wrapper {
  border-radius: 12px;
  background: rgba(96, 87, 137, 0.1);
  backdrop-filter: blur(2px);
  padding: 10px;
  overflow: auto;
}

.player-inner-username {
  color: #f7f7ff;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.288px;
}

.user-totalstar-text {
  color: #F3BF09; 
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: normal;
}

.user-blue-rank {
  color: #F7F7FF; 
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.216px;
}

.user-rank-text {
  color: #F3BF09;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.144px;
}

.optionswrapper-bg {
  position: absolute;
  width: 14%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid #f4e07b;
  background: #341553;
  transition: all 0.25s ease-in-out;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.optionswrapper-bg-new {
  border-radius: 12px;
  border: 1px solid #f4e07b;
  background: #341553;
  transition: all 0.25s ease-in-out;
}

.optionswrapper-bg-home {
  position: absolute;
  width: 33%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid #f4e07b;
  background: #341553;
  transition: all 0.25s ease-in-out;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.optionswrapper-bg-2 {
  position: absolute;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid #f4e07b;
  background: #341553;
  transition: all 0.25s ease-in-out;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.move-1 {
  transform: translateX(105%);
}
.move-2 {
  transform: translateX(308%);
}
.move-3 {
  transform: translateX(410%);
}
.move-4 {
  transform: translateX(512%);
}

.move-5 {
  transform: translateX(614%);
}

.move-6 {
  transform: translateX(207%);
}

.move-1-home {
  transform: translateX(100%);
}

.move-2-home {
  transform: translateX(200%);
}

.leaderboard-icon {
  transition: all 0.25s;
}

.leaderboard-icon-active {
  filter: grayscale(0);
}

.blur-leaderboard {
  filter: blur(5px);
  pointer-events: none;
}
.reset-time-lb {
  color: #b1a8b9;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 8px;
  }
}

.reset-time-lb-value {
  color: #f4e27b;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 8px;
  }
}

.reset-time-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  justify-content: center;
}

.global-tooltip-position {
  position: absolute;
  left: 2%;
}

.leaderboard-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  font-family: "Montserrat" !important;

  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
}
.left-arrow-leaderboard {
  position: absolute;
  left: -4%;
  top: 50%;
  z-index: 1;
  width: 30px;
  cursor: pointer;
}
.right-arrow-leaderboard {
  position: absolute;
  right: -4%;
  top: 50%;
  z-index: 1;
  width: 30px;
  cursor: pointer;
}

.select-chain-arrow-left {
  position: absolute;
  left: 3%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.select-chain-arrow-right {
  position: absolute;
  right: 3%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.coming-soon-wrapper {
  height: 442px;
  background: rgba(151, 89, 192, 0.3);
  border-radius: 12px;
  h6 {
    margin-bottom: 0;
    font-family: "Oxanium" !important;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
  }
}

.coming-soon-position {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 5;
  h6 {
    margin-bottom: 0;
    font-family: "Oxanium" !important;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
  }
}

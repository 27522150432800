.mobile-navbar {
  position: fixed;
  top: 0;
  background: #080B2A;;
  width: 100%;
  z-index: 9;
  height: 65px;
  border-bottom: 2px solid #1E5ED7;

  .hb-wrapper {
    border-radius: 8px;
background: linear-gradient(270deg, #1348AD 0%, #1E5ED7 100%);
// width: 40px;
// height: 40px;
clip-path: none;
  }

  #hamburgermenu span {
    display: block;
    position: absolute;
    height: 2px;
    width: 16px;
    background: #E7EFFF;
    border-radius: 20px;
    opacity: 1;
    left: 18px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #hamburgermenu span:nth-child(1) {
    top: 8px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #hamburgermenu span:nth-child(2) {
    top: 14px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #hamburgermenu span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #hamburgermenu.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 13px;
    left: 13px;
  }

  #hamburgermenu.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #hamburgermenu.open {
    border: 1px solid #f7f7fc;
    background: inherit;

    span {
      background: #f7f7fc;
    }
  }

  #hamburgermenu.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 30px;
    left: 13px;
  }
}


.subscribe-container {
  border-radius: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: 991px) {
    height: 500px;
  }
}



.mobile-menu {
  background: #171B3E;;
  width: 100%;
  position: fixed;
  top: -1000px;
  z-index: 1001;
  transition: all .25s;
  .mobile-nav-item {
    border-radius: 6px;
    background: rgba(113, 127, 255, 0.10);

    .mobile-nav-link {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      // line-height: 32px;
      color: #ffffff;
    }
  }

  .mobile-nav-item-active {
    border-radius: 6px;
    border: 0.5px solid #5690FF;
    background: rgba(30, 94, 215, 0.50);
   
  }
}


.mobile-menu-open{
    top: 59px;
  box-shadow:0 0 0 1000px rgba(0,0,0,.50);
left: 0;
}

@import '../../../responsive';



.cats-and-watches-society {
  &::before {
    content: "";
    width: 100%;
    height: 100px;
    // top: 100px;
    display: block;
    position: absolute;
    left: 0;
    background-size: contain;
    background: url("https://cdn.worldofdypians.com/wod/paw-meta-dark.png")
      repeat-x center;
      background-position: initial;
    @include large {
        margin-top: -35px;
        height: 180px;
        background: url("https://cdn.worldofdypians.com/wod/paw-meta-dark.png") repeat-x center;
        background-position: initial;
    }
  }
}
.common-title-text {
  mark {
    color: #7770e0;
  }
  p {
    font-size: 16px;
  }
}
.dark .cats-and-watches-society {
  &::before {
    background: url("https://cdn.worldofdypians.com/wod/paw-meta-dark.png")
      repeat-x center;
      background-position: initial;
    @include large {
        background: url("https://cdn.worldofdypians.com/wod/paw-meta-dark.png") repeat-x center;
        background-position: initial;
    }
  }
}

.home-main-wrapper {
  max-width: 2400px;
  width: 100%;
  // background-image: url("../../assets/siteBackground.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-x: hidden;
  @media screen and (max-width: 1600px) {
    width:  fit-content !important;
  }
}

.opacitywrapper {
  border-radius: 15px;
  padding: 0px; 
  cursor: pointer;
  position: absolute;
  z-index: 5; 
  bottom: 1%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}

.opacitywrapper2 {
  // border: 1px solid #00fecf;
  border-radius: 15px;
  border: 1px solid #b8b8e0;
  background: linear-gradient(
    148deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
  backdrop-filter: blur(12.5px);
  padding: 10px 20px;
  max-width: 1050px;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  left: 10px;
  bottom: 1%;
  width: 60%;
  margin-top: -20px;
  @media only screen and (max-width: 1650px) {
    width: 57%;
  }

  @media only screen and (max-width: 991px) {
    bottom: auto;
    margin-top: 20px;
    width: 90%;
    left: 0;
  }
}

.opacitywrapper3 {
  // border: 1px solid #00fecf;
  border-radius: 8px;
  border: 1px solid #b8b8e0;
  background: linear-gradient(
    148deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
  backdrop-filter: blur(12.5px);
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px auto 0;
  width: fit-content;
}

.opacitywrapper4 {
  // border: 1px solid #00fecf;
  border-radius: 8px;
  border: 1px solid #b8b8e0;
  background: linear-gradient(
    148deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
  backdrop-filter: blur(12.5px);
  padding: 5px;
  cursor: pointer;
  margin: 10px auto 0;
  width: fit-content;
}

.opacitywrapper5 {
  // border: 1px solid #00fecf;
  border-radius: 8px;
  border: 1px solid #b8b8e0;
  background: linear-gradient(90deg, #8eee86 -13.56%, #0078d4 129.3%);
  backdrop-filter: blur(12.5px);
  padding: 4px;
  cursor: pointer;
  margin: 10px auto 0;
  width: fit-content;
}

.opacitywrapper3:hover,
.opacitywrapper4:hover,
.opacitywrapper5:hover {
  background: linear-gradient(107deg, #3dbda7 24.84%, #067d71 83.51%);
  border: 1px solid #067d71;
}

.release-date-title{
  color: #fff;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}
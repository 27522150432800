.new-events-top-wrapper {
  border-radius: 10px 10px 0px 0px;
  background: #121235;
}

.challenges-text {
  color: #ffc808;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
}

.yellow-text-amount {
  color: #ffc808;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
  text-transform: capitalize;
}

.small-text-amount {
  color: #eeedff;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.coming-soon-event-text {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
}

.event-thumb-img {
  border: 1px solid transparent;
  border-radius: 12px;
  transition: all 0.25s;
}

.event-thumb-hover {
  border: 1px solid #ffc808;
  border-radius: 12px;
}

.event-thumb-img:hover {
  border: 1px solid #ffc808;
  border-radius: 12px;
}

.active-challenge-tab {
  border-radius: 6px;
  border: 0.5px solid #ffc808;
  background: rgba(255, 200, 8, 0.2);
  cursor: pointer;
  span {
    color: #ffc808;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.216px;
  }
}

.beast-big-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: 1399px) {
  grid-template-columns: repeat(1, 1fr);
    
  }
}

.challenge-tab {
  transition: all 0.3s;
  border-radius: 6px;
  border: 0.5px solid transparent;

  cursor: pointer;
  span {
    color: #eeedff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.216px;
  }
}

.challenge-tab:hover {
  border-radius: 6px;
  border: 0.5px solid #ffc808;
  background: rgba(255, 200, 8, 0.2);
  span {
    color: #ffc808;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.216px;
  }
}

.new-events-bottom-wrapper {
  border-radius: 0px 0px 10px 10px;
  background: #18193c;
  backdrop-filter: blur(10px);
  height: 710px;
  @media screen and (max-width: 1400px) {
    height: auto;
  }
}

.challenges-list-wrapper {
  border-radius: 10px;
  background: #121235;
  height: 670px;
  @media screen and (max-width: 1400px) {
    height: auto;
  }
  @media screen and (max-width: 600px) {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    height: auto;
  }
}

.dynamicPosition {
  position: absolute;
  @media screen and (max-width: 1024px) {
    position: relative;
  }
}

.active-challenge-item {
  border-radius: 6px;
  background: rgba(255, 200, 8, 0.1);
  width: 100%;
  cursor: pointer;
  h6 {
    color: #ffc808;
    font-family: "Montserrat";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    // line-height: 24px;
    text-transform: uppercase;
    @media screen and (max-width: 1550px) {
      font-size: 10px;
      white-space: nowrap;
    }
    @media screen and (max-width: 600px) {
      font-size: 11px;
    }
  }
}

.challenge-item {
  border-radius: 6px;
  transition: all 0.25s;
  width: 100%;
  cursor: pointer;
  h6 {
    color: #eeedff;
    font-family: "Montserrat";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    // line-height: 24px;
    text-transform: uppercase;
    @media screen and (max-width: 1550px) {
      font-size: 10px;
      white-space: nowrap;
    }
    @media screen and (max-width: 600px) {
      font-size: 11px;
    }
  }
}

.challenge-item:hover {
  background: rgba(255, 200, 8, 0.1);
  h6 {
    color: #ffc808;
  }
}

.beast-siege-wrapper {
  height: 380px;
  border-radius: 12px;
  z-index: 1;
  @media screen and (max-width: 786px) {
    height: auto;
  }
}

.brands-yellow-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid gold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s;
}



.dragon-siege {
  background: linear-gradient(109deg, #2e3842 0%, #121b1e 49%);
  position: relative;
  z-index: 2;
}

.dragon-siege::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/dragonRuinsHead.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  pointer-events: none;
  z-index: -1;
}

.bear-siege {
  background: linear-gradient(109deg, #0097b9 0%, rgba(18, 16, 131, 0.77) 49%);
  position: relative;
  z-index: 2;
}

.bear-siege::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/coldBiteHead.png");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: -1;
  @media screen and (max-width: 786px) {
    background-size: 43%;
  }
}
.beast-siege {
  background: linear-gradient(109deg, #29302c 0%, #29302c 49%);
  position: relative;
  z-index: 2;
}

.beast-siege::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/furyBeastHead.png");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: -1;
  @media screen and (max-width: 786px) {
    background-size: 43%;
  }
}
.eagle-siege {
  background: linear-gradient(109deg, #004e7f 0%, #073755 49%);
  position: relative;
  z-index: 2;
}

.eagle-siege::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/wingStormHead.png");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: -1;
  @media screen and (max-width: 786px) {
    background-size: 43%;
  }
}
.scorpion-siege {
  background: linear-gradient(109deg, #732802 0%, #ab621e 49%);
  position: relative;
  z-index: 2;
}

.scorpion-siege::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/scorpionKingHead.png");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: -1;
  @media screen and (max-width: 786px) {
    background-size: 43%;
  }
}
.cyclops-siege {
  background: linear-gradient(109deg, #0b3398 0%, #062663 49%);
  position: relative;
  z-index: 2;
}

.cyclops-siege::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/stoneEyeHead.png");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: -1;
  @media screen and (max-width: 786px) {
    background-size: 43%;
  }
}
.maze-garden-card {
  background: linear-gradient(109deg, #081c00 0%, #081c00 49%);
  position: relative;
}

.maze-garden-card::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/bnbMazeDayCardNewEvents.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: -1;
}
.puzzle-madness-card {
  background: linear-gradient(109deg, #055f67 0%, #00293e 49%);
  position: relative;
}

.puzzle-madness-card::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/puzzleMadnessCardNewEvents.png");
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: -1;
}
.critical-hit-card {
  background: linear-gradient(109deg, #712b1f 0%, #3e0a04 49%);
  position: relative;
}

.critical-hit-card::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/criticalHitCardNewEvents.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: -1;
}
.explorer-hunt-card {
  background: linear-gradient(109deg, #491961 0%, #2e0a40 49%);
  position: relative;
}

.explorer-hunt-card::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/explorerHuntCardNewEvents.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: -1;
}
.great-collection-card {
  background: linear-gradient(109deg, #71511f 0%, #503203 49%);
  position: relative;
}

.great-collection-card::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.worldofdypians.com/wod/greatCollectionCardNewEvents.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
  z-index: -1;
}

.beast-siege-title {
  color: #eeedff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 18px;
  }
}

.beast-siege-sub {
  color: #bbc9da;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 14px;
  }
}

.beast-siege-wod-price {
  color: #eeedff;
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 18px;
  }
}

.beast-siege-usd-price {
  color: #ffc808;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 16px;
  }
}

.beast-siege-event-price {
  color: #d3d5d7;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media screen and (max-width: 786px) {
    font-size: 12px;
  }
}

.get-land-link{
  color: #d3d5d7 !important;
}

.beast-siege-btn {
  border-radius: 12px;
  background: #ffeacd;
  color: #2b353e;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  width: fit-content;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  @media screen and (max-width: 786px) {
    font-size: 16px;
  }
}
.beast-siege-info-wrapper {
  border-radius: 12px;
  height: 380px;
  @media screen and (max-width: 786px) {
    height: auto;
  }
}

.beast-siege-btn-inactive {
  border-radius: 12px;
  background: black;
  color: white;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  width: fit-content;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.beast-siege-btn:hover {
  background: #53a5be !important;
  color: #ebeeff !important;
}

.dragon-siege-info {
  background-image: url("https://cdn.worldofdypians.com/wod/dragonRuinsBannerNewEvents.webp");
  background-size: cover;
}

.bear-siege-info {
  background-image: url("https://cdn.worldofdypians.com/wod/coldBiteBannerNewEvents.webp");
  background-size: cover;
}

.beast-siege-info {
  background-image: url("https://cdn.worldofdypians.com/wod/furyBeastBannerNewEvents.webp");
  background-size: cover;
}

.eagle-siege-info {
  background-image: url("https://cdn.worldofdypians.com/wod/wingStormBannerNewEvents.webp");
  background-size: cover;
}

.scorpion-siege-info {
  background-image: url("https://cdn.worldofdypians.com/wod/scorpionKingBannerNewEvents.webp");
  background-size: cover;
}

.cyclops-siege-info {
  background-image: url("https://cdn.worldofdypians.com/wod/stoneEyeBannerNewEvents.webp");
  background-size: cover;
}

.maze-garden-info {
  background-image: url("https://cdn.worldofdypians.com/wod/mazeGardenBannerNewEvents.webp");
  background-size: cover;
}
.puzzle-madness-info {
  background-image: url("https://cdn.worldofdypians.com/wod/puzzleMadnessBannerNewEvents.webp");
  background-size: cover;
}
.explorer-hunt-info {
  background-image: url("https://cdn.worldofdypians.com/wod/explorerHuntBannerNewEvents.webp");
  background-size: cover;
}
.great-collection-info {
  background-image: url("https://cdn.worldofdypians.com/wod/greatCollectionBannerNewEvents.webp");
  background-size: cover;
}
.critical-hit-info {
  background-image: url("https://cdn.worldofdypians.com/wod/criticalHitBannerNewEvents.webp");
  background-size: cover;
}

.luminosity {
  mix-blend-mode: luminosity;
}

.dragon-button {
  color: #2b353e !important;
  background: #ffeacd !important;
}

.bear-button {
  color: #018cac !important;
  background: #68e9fe !important;
}
.beast-button {
  color: #27302a !important;
  background: #bee3a8 !important;
}
.eagle-button {
  color: #053e62 !important;
  background: #d6f4ff !important;
}
.scorpion-button {
  color: #7a2f06 !important;
  background: #ffd695 !important;
}
.cyclops-button {
  color: #0a308d !important;
  background: #f1edff !important;
}
.critical-hit-button {
  color: #420d07 !important;
  background: #c77f72 !important;
}

.maze-day-button {
  color: #081c01 !important;
  background: #ddf8d7 !important;
}

.event-top-banner {
  height: 200px;
  width: 100%;
  margin-bottom: 31px;
  object-fit: cover;
  @media screen and (max-width: 786px) {
    height: 100px;
  }
}

.new-events-circle-2 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid gold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s;
}

.wod-balance-position{
  position: absolute;
  top: -40px;
}

.maze-day-balance-holder{
  position: absolute;
  right: 10px;
  top: 10px;
  background: #081c00;
  border-radius: 12px;
  span{
    color: #fff !important;
  }
}